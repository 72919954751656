import React, {Component, createRef, LegacyRef, RefObject} from "react";
import "./UiTrainingWindow.css";
import "./UiPdfOverlay.css";
import PdfOverlayState from "./UiPdfOverlay.state";
import Configs from "../../../Config/Configs";
import PopupWindowState from "./UiPopupWindow.state";
import BabylonManager from "../../../Managers/BabylonManager";
import TrainingManager from "../../../Managers/TrainingManager";
import UIManager from "../../../Managers/UIManager";
import { getItemStyles } from "@fluentui/react/lib/components/ContextualMenu/ContextualMenu.classNames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Button } from "@material-ui/core";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default class UiPdfOverlay extends Component<unknown, PdfOverlayState> {

    public pdfOverlayRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);
        this.state = new PdfOverlayState();
        this.onPdfProgress = this.onPdfProgress.bind(this);
        this.onPdfLoadSuccess = this.onPdfLoadSuccess.bind(this);
	    this.closePdf = this.closePdf.bind(this);
        this.changePage = this.changePage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
    }



  public closePdf(){
        console.log("close pdf")
        this.setState({isVisible:false, pdfUrl:null})
        console.log(this.state)
        BabylonManager.MainContext.setCompleteStep(this.state.stepId, this.state.percentComplete)
		setTimeout(()=>{
		//	UIManager.UiOverlayRef.current.setState({ShowPopupWindow:false, HideOverlay:true,ShowVideoOverlay:false})
		 UIManager.UiOverlayRef.current.setState({ HideOverlay:true,ShowPdfOverlay:false})

		},0)
        
    }

private onPdfLoadSuccess(pdf: any):void{

	// type should be PDFDocumentProxy but not finding the def
	console.log(pdf)
	let totalPgs = pdf._pdfInfo.numPages;
	if(this.setState){
this.setState({numPages:totalPgs})
	console.log("pdf loaded", totalPgs)
	}
}

private changePage(offset:number) {
let newPageNum = this.state.pageNum + offset;
let maxPage = this.state.maxPage > newPageNum ? this.state.maxPage : newPageNum
let percCompleted = maxPage/this.state.numPages *100;

	this.setState({pageNum: this.state.pageNum + offset, maxPage: maxPage, percentComplete:percCompleted})
  }

private prevPage(){
this.changePage(-1)
}
private nextPage(){
this.changePage(1)
}

private onPdfProgress():void{
    if(this.state){
   
    this.setState({percentComplete:100})
    setTimeout(()=>{
        this.closePdf();
    },1500)
//this.setState({isVisible:false})
    }
}
    
    public render(): React.ReactElement {
        let pdfUrl = this.state.pdfUrl;
        let closeAction = this.state.closeAction; 
        let pdfH = "70vh"
		
        let pdfW = "100%"
        console.log("pdfUrl",pdfUrl)
       
                return (
                    <>
                    {this.state.isVisible === true && pdfUrl ? 
					<div className="pdf-content-wrapper">
	
 
                    <div className="PdfOverlayContainer">
                         <div className="ClosePopup" onClick={()=>{this.closePdf()}}><FontAwesomeIcon icon={faTimesCircle as IconProp} /></div>
              
                        <div className="PdfOverlay" ref={this.pdfOverlayRef}>
                            
                            <>
                          
<div>{this.state.title}</div>
<Document file={this.state.pdfUrl} onLoadSuccess={this.onPdfLoadSuccess}>
							<Page pageNumber={this.state.pageNum} ></Page>
						</Document>

						<div class="pdf-footer">
       <Button className="text-link pdf-prev" disabled={this.state.pageNum  <= 1} onClick={()=>{this.prevPage()}}><FontAwesomeIcon icon={faAnglesLeft as IconProp} /></Button>
        <div>
          Page {this.state.pageNum || (this.state.numPages ? 1 : '--')} of {this.state.numPages || '--'}
        </div>
		

		<Button className="text-link pdf-next" disabled={this.state.pageNum  >= this.state.numPages} onClick={()=>{this.nextPage()}}><FontAwesomeIcon icon={faAnglesRight as IconProp} /></Button>
              
      
      </div>
                           </>
                            
                          <div>
                                  </div>
                            
                        </div>
                    </div>
					
					</div>
                    :
                    "unable to display document"
                    }
                    </>
                );
            }
}