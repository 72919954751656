import TrainingManager from "../../../Managers/TrainingManager";
import { TrainingStatus } from "../../../TrainingSession.props";
import { TrainingWindowSections } from "./UiTrainingWindow";

export default class TrainingWindowState {
    public TrainingStatus: TrainingStatus = TrainingManager.Session.status;
    public CurrentSection: TrainingWindowSections = TrainingWindowSections.PreChecks;
    public CompletedSection: boolean = false;
    public ShowPreChecks: boolean = true;
    public ShowQuestions: boolean = false;
    public ShowCompleted: boolean = false;
    public ShowResponse: boolean = false;
    public ResponseType: string = "";
    public ResponseText: string = "";
    public NextButtonText: string = 'Continue';
}
