import React, {Component, createRef, LegacyRef, RefObject} from "react";
import "./UiTrainingWindow.css";
import "./UiImageTextOverlay.css";
import ImageTextOverlayState from "./UiImageTextOverlay.state";
import Configs from "../../../Config/Configs";
import PopupWindowState from "./UiPopupWindow.state";
import BabylonManager from "../../../Managers/BabylonManager";
import TrainingManager from "../../../Managers/TrainingManager";
import UIManager from "../../../Managers/UIManager";
import ReactPlayer from 'react-player'
import { getItemStyles } from "@fluentui/react/lib/components/ContextualMenu/ContextualMenu.classNames";
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Parser from 'html-react-parser';
import IPanelProps from "../../UiComponents/TrainingComponents/UiImageTextOverlay.props";
import { Paper,Button, List, Checkbox, ListItemText,ListItem,ListItemIcon, } from "@material-ui/core";
import {ImgTextData, LinkData} from '../../../Data/dataSetTypes'
import Carousel from 'react-material-ui-carousel'

export default class UiImageTextOverlay extends Component<unknown, ImageTextOverlayState> {

    public UiImageTextOverlayRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
private currentPanel: ImgTextData;
private initialized = false;

    constructor(props: any) {
        super(props);
        this.state = new ImageTextOverlayState();
        this.onPanelProgress = this.onPanelProgress.bind(this);
        this.onPanelEnded = this.onPanelEnded.bind(this);
        this.closePanel = this.closePanel.bind(this);
    }

	componentDidMount(): void {
		setTimeout(()=>{
			UIManager.UiOverlayRef.current.setState({HideOverlay:false})
				console.log("pdata:",this.state)
				if(this.state.panels){
				//	this.getPanelContent(this.state.panelIdx)
				   }
		},200)
	
	}
	componentDidUpdate(): void {
		
		if(this.state.panels && this.state.panels !== null){
			console.log("updatated", this.state.panels, this.initialized)
			if(this.initialized === false){
		
			this.setState({currentPanel:	this.getPanelContent(this.state.panelIdx)})
this.initialized = true;
UIManager.UiOverlayRef.current.setState({HideOverlay:false})
		
			}
		   }	
	}

    public closePanel(){
        console.log("close panel")

	
        this.setState({isVisible:false})
		this.setState({panels:null,currentPanel:null,panelIdx:0});
		this.initialized = false;
        console.log("closedimgtext",this.state)
        UIManager.UiOverlayRef.current.setState({ShowPopupWindow:false, HideOverlay:true,ShowVideoOverlay:false})
		this.state = new ImageTextOverlayState();
      	BabylonManager.MainContext.setCompleteStep(this.state.stepId, this.state.percentComplete)
		BabylonManager.MainContext.getNextAction();
    }

	private getLink(link:LinkData){
let targ = "_blank";
if(link.linkTarget){
	targ = link.linkTarget;
}

return <div className="link-item text-link" ><a href={link.linkUrl} target={targ} className="text-link">{link.linkText} <FontAwesomeIcon className="ext-link" icon={faExternalLink as IconProp} /></a></div>	
		
	}


	private getLinkList(){

		let content = this.state.currentPanel;
		console.log("content-pdata", content)
		let links = [];
		if(content.arrLinks.length > 0 ){
let arrLinks = content.arrLinks.map((item)=>{
return this.getLink(item);
})
return arrLinks
		}else{
			return ""
		}
			
		}
	

private onPanelProgress(e:any):void{


}
private onPanelEnded():void{
    if(this.state){
    console.log("state", this.state)
    this.setState({percentComplete:100})
    setTimeout(()=>{
        this.closePanel();

    },1500)
//this.setState({isVisible:false})
    }
}

private getPanelContent(idx: number): ImgTextData{

console.log("panelcontent",this.state.panels, idx)

let pContent = this.state.panels[idx];
//this.changePage(0)
return pContent
}

private getBtnText(): string{

	let btnTxt = "DONE"
	if(this.state.standAlone === false){
		btnTxt = "NEXT"
	}

	return btnTxt
}

private changePage(offset:number) {
	let newPageNum = this.state.panelIdx + offset;
	let maxPage = this.state.panels.length > newPageNum ? this.state.panels.length : newPageNum
	let percCompleted = maxPage/this.state.panels.length *100;
	
		this.setState({panelIdx: this.state.panelIdx + offset,  percentComplete:percCompleted})
		//this.getPanelContent(newPageNum)
		//this.currentPanel =  this.getPanelContent(newPageNum)
		this.setState({currentPanel : this.getPanelContent(newPageNum)})
	  }
	
	private prevPage(){
	this.changePage(-1)
	}
	private nextPage(){
	this.changePage(1)
	}
	
	private getImgHeight() {

	
let container = document.querySelector("VideoOverlay");
if(container){
 let cHt = container.clientHeight;
 let h = cHt *.85 + "px";
 let cStyle = {height: h};
console.log("container height", h)

//container.style.height = ""
		return cStyle
}else{
	return {height: "100%",}
}
	}
		
    
    public render(): React.ReactElement {
      
       let content = this.state.currentPanel;
	   console.log("pgcontent", content)
                return (
                    <>
                    {this.state.isVisible && content && content !== null ? 
					<div className={'video-content-wrapper has-text'}>
                    <div className="VideoOverlayContainer">
                         <div className="ClosePopup" onClick={()=>{this.closePanel()}}><FontAwesomeIcon icon={faTimesCircle as IconProp} />
						 <div className="close-text"> CLOSE</div>
						 </div>
              
                        <div className="VideoOverlay" ref={this.UiImageTextOverlayRef}>
                            
                           

	{content.imageSet ? 

	<div className="carousel-wrapper" >
	 <Carousel autoPlay={false}  className="carousel" indicators={false} navButtonsAlwaysVisible={true}>
	 {
		 content.imageSet.map( (item, i) => {
			if(item.label){
			return <div><img src={ item.imageFileName} /><div className="img-label">{item.label}</div></div>
			}else{
				return <div><img src={ item.imageFileName} /></div>
			
			}
		} )
	 }
 </Carousel>
	</div>

	: 	                         
	<div className="img-wrapper">
		<img src={ content.imageFileName} />
	</div>
	}

                            
                            
                          <div>
                                  </div>
                            
                        </div>
                    </div>
					{content.strHtml ?
					<div className="video-text">
						<div className="video-heading">
							{content.heading}
						</div>
						<div>
						{Parser(content.strHtml)}
					
					</div>
					<div className="link-list">
						{this.getLinkList()}
					
			</div>
					<div class={this.state.panels.length > 1 ? "it-footer": "it-footer hidden"}>
       <Button className="text-link it-prev" disabled={this.state.panelIdx + 1  <= 1} onClick={()=>{this.prevPage()}}><FontAwesomeIcon icon={faAnglesLeft as IconProp} /></Button>
        <div>
           {this.state.panelIdx + 1 || (this.state.panels.length ? 1 : '--')} of {this.state.panels.length || '--'}
        </div>
		

		<Button className="text-link it-next" disabled={this.state.panelIdx +1  >= this.state.panels.length} onClick={()=>{this.nextPage()}}><FontAwesomeIcon icon={faAnglesRight as IconProp} /></Button>
              
      
      </div>
	  <div className="btn-complete">
{this.state.panelIdx +1  >= this.state.panels.length ?
<Button className="primary-button" onClick={()=>{this.closePanel()}}>{this.getBtnText()}</Button>
: ""
	}
	  </div>
					</div>
						:""
	}
					</div>
                    :
                    ""
                    }
                    </>
                );
            }
}