import React, {Component} from "react";
import IUiTransitionProps from "./UiTransition.props";

export default class UiTransition extends Component<IUiTransitionProps, undefined>{

    constructor(props: any) {
        super(props);
    }

    public render(): React.ReactElement {
            return (
                <div className={`OverlayTransition ${this.props.ExtraClass}`}/>
            );
        }
}
