import { Tools} from "@babylonjs/core";
import { assets } from "../../assetMap";
import { ImgTextData, TaskListItem } from "../dataSetTypes";

const netBlowerPanel: ImgTextData[] = [
    {
        heading: "Prepping Blowing Machine",
        imgAutoPlay: true,
        imageSet: [
            {
                imageFileName: assets.fns.getAssetPath(
                    "prepBlower1",
                    "courseAssets",
                    "netBlow"
                ),
            },
            {
                imageFileName: assets.fns.getAssetPath(
                    "prepBlower2",
                    "courseAssets",
                    "netBlow"
                ),
            },
        ],
        strHtml:
            "Blown in insulation performs best when the fibers expand and are fully separated by air while flowing through the hose. Its package is compressed for shipping and storage, which means it has to run through a machine and hose in order to open the fibers.<br/<br/>Check all machine settings for the proper feed gate and airflow settings. Make sure to attach the correct length and diameter of hose per the settings and recommendations found on the insulation bag label or product data sheet.<br/<br/>Click the link below to view detailed instructions on setting up your machine.",
        arrLinks: [
            {
                linkText: "Net & Blow Insulation Installation Guide",
                linkUrl: assets.fns.getAssetPath(
                    "netBlowInstallGuide",
                    "courseAssets",
                    "netBlow"
                ),
            },
        ],
    },
];

const densityTable: ImgTextData[] = [
    {
        heading: "Cavity Wall Coverage",
        imageFileName: assets.fns.getAssetPath(
            "densityTable",
            "courseAssets",
            "netBlow"
        ),
        strHtml:
            "Before you begin, it’s important to confirm that you have the correct number of packages to complete the job. Refer to the table found in the product data sheet (cavity wall coverage table). For this particular installation, you will need to fill approximately 200sq ft. Based on the table, you would need no less than 4 packages to complete the job.",
        arrLinks: [],
    },
];

const specialCasesPanel: ImgTextData[] = [
    {
        heading: "Installing Around Obstructions",
        imageFileName: assets.fns.getAssetPath(
            "wetWall",
            "courseAssets",
            "netBlow"
        ),
        strHtml:
            "Occasionally you will need to cut mesh to fit protrusions, such as plumbing stub-outs, to ensure a flat installation. A special note: once insulated, the mesh around outlet boxes and light switches should be cut out so that the drywallers know where they are.",
        arrLinks: [],
    },
];

const bestPracticesPanel: ImgTextData[] = [
    {
        heading: "Check Your Density",
        imageFileName: "Images/batt_install_tasks/correct_incorrect2.jpg",
        imgAutoPlay: false,
        imageSet: [
            {
                imageFileName: assets.fns.getAssetPath(
                    "densityCheck",
                    "courseAssets",
                    "netBlow"
                ),
            },
            {
                imageFileName: assets.fns.getAssetPath(
                    "densityTable2",
                    "courseAssets",
                    "netBlow"
                ),
            },
        ],
        strHtml:
            "The density should be checked within the first five cavities, and every 1,000 square feet of wall space after that. A density check ensures proper R-value is installed, and the proper coverage will be reached. Check the density by weighing a cubic foot of material from an insulated wall.",
        linkText: "Residential Insulation: Preparing for Your Installation",
        linkUrl: "https://www.knaufnorthamerica.com/en-ca/install",
        arrLinks: [
            {
                linkText: "Net & Blow Insulation Training Guide",
                linkUrl: assets.fns.getAssetPath(
                    "netBlowInstallGuide",
                    "courseAssets",
                    "netBlow"
                ),
            },
        ],
    },
];

export let hotspotsNetAndBlow = [
    {
        id: "netblow-bestpractices",
        parentId: "netblow-bestpractices",
        task: "onBestPractices",
        priority: 0,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: {
            panelData: bestPracticesPanel,
            navOptions: { displayPopupAsNavPanel: true },
        },
        active: false,
        complete: false,
    },

    {
        id: "netblow-special",
        parentId: "netblow-specialcases",
        task: "onSpecialCases",
        priority: 0,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: {
            panelData: specialCasesPanel,
            navOptions: { displayPopupAsNavPanel: true },
        },
        active: false,
        complete: false,
    },
];

export let interactiveNetAndBlow = [
    {
        id: "intro-netBlow",
        parentId: "netblow-interactive",
        task: "onNetBlow",
        priority: 0,
        inst: "",
        type: "popup",
        fnId: "",
        params: {
            autoAdvance: true,
            retainPopup: true,
            text: "Welcome to the Net & Blow Insulation Training. <br> Here you will learn all of the steps required for installing the loose fill fiberglass insulation and ensure proper R-Value fill line.",
            title: "Net & Blow Interactive Installation",
            xPos: 150,
            yPos: 105,
            btnText: "Start Training",
        },
        active: false,
        complete: false,
    },

    {
        id: "netblow-img-text1",
        parentId: "netblow-interactive",
        task: "onNetBlow",
        priority: 1,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: { autoAdvance: true, panelData: densityTable },
        active: false,
        complete: false,
    },

    {
        id: "chklist-netblow",
        parentId: "netblow-interactive",
        task: "onNetBlow",
        priority: 2,
        inst: "",
        type: "checklist",
        fnId: "",
        params: {
            xPos: 150,
            yPos: 105,
            autoAdvance: true,
            width: 550,
            text: "Before installation, make sure you have completed the jobsite preparation.",
            checklist: [
                "Walk site to look for any hazards or issues; plan safest/easiest access for hose and equipment",
                "Make sure all work before insulation is finished and has passed inspection (plumbing, electrical, HVAC, etc.)",
                "The house is “dried in”",
                "All pre-insulation work is completed",
                "Joints/openings in the building enclosure are air sealed or properly backed",
                "Verify that penetrations to unconditioned space are air sealed",
                "Clean debris out of wall cavities that are to be insulated",
                "Sweep floors where you will be working",
                "Verify you have all the necessary tools and equipment",
                "Correct amount of insulation packages for the job",
            ],
            navOptions: { displayPopupAsNavPanel: true },
            title: "Prepare the Job Site",
            btnText: "Start",
        },
        active: false,
        complete: false,
    },

    {
        id: "netblow-prep-blower",
        parentId: "netblow-interactive",
        task: "onNetBlow",
        priority: 3,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: {
            panelData: netBlowerPanel,
            navOptions: { displayPopupAsNavPanel: true },
        },
        active: false,
        complete: false,
    },

    {
        id: "hangNetting-netBlow",
        parentId: "netblow-interactive",
        task: "onNetBlow",
        priority: 4,
        inst: "",
        type: "click-animation",
        fnId: "",
        params: {
            comment:
                'Select the insulation netting to hang and staple to the studs.<br/> <br/>Begin by rolling the fabric across the floor in front of the area you intend to insulate; cut the fabric straight once you\'ve pulled enough to cover the area to be insulated; To hang the fabric, start by hanging one of the "top" corners of the wall - just a few staples to hold it for now; Go the the opposite corner, pull the fabric tight, and staple it in place; Move to the bottom middle of the fabric, and pull it tight (away from the top corners), and add a few staples. This stapling pattern helps to keep the fabric straight and tight, allowing for easier stapling across the framing members. Once the fabric is tight and straight, staple the fabric to the framing in 1" intervals. Having a staple every 1" will keep insulation from "bridging" across the face of the framing while installing.',
            pullQuote:
                "This stapling pattern helps to keep the fabric straight and tight, which will allow you to staple all the stud faces faster.",
            commentTitle: "Hang & Staple Netting",
            xPos: 150,
            yPos: 175,
            startAnim: 0,
            endAnim: 6.85,
            focalPointOptions: {
                name: "focalPt",
                meshName: "Netting",
                focalType: "pointer",
                rotation: 0,
                scale: 1,
                offsetX: 0,
                offsetY: -5,
            },
            btnText: "Start",
            speedRatio: 1,
            animation: "ANIM-HangNetting",
            target: "Netting",
            postAnimationId: "",
            autoAdvance: false,
            navOptions: { displayPopupAsNavPanel: true },
        },
        active: false,
        complete: false,
    },
    //startStaples
    {
        id: "cutHoles-netBlow",
        parentId: "netblow-interactive",
        task: "onNetBlow",
        priority: 5,
        inst: "",
        type: "click-animation",
        fnId: "",
        params: {
            comment:
                "Select the utility knife to cut a hole in the insulation netting.",
            pullQuote:
                "In order to blow insulation into the cavity, you need a hole to stick the hose through.",
            commentTitle: "Cut Hole Into Netting",
            xPos: 150,
            yPos: 175,
            startAnim: 0.08,
            endAnim: 15,
            focalPointOptions: {
                name: "focalPt",
                meshName: "boxCuttercopy",
                focalType: "pointer",
                rotation: -90,
                scale: 1,
                offsetX: -5,
                offsetY: 0,
            },
            btnText: "Start",
            speedRatio: 1,
            fromFrame: 0.3,
            toFrame: 14.6,
            animation: "ANIM-CutHolesAndPrefill",
            target: "boxCuttercopy",
            postAnimationId: "",
            autoAdvance: false,
            navOptions: { displayPopupAsNavPanel: true },
        },
        active: false,
        complete: false,
    },

    {
        id: "fill-netBlow",
        parentId: "netblow-interactive",
        task: "onNetBlow",
        priority: 6,
        inst: "",
        type: "click-animation",
        fnId: "",
        params: {
            comment:
                "Click the blower hose to connect the hose to the hole to begin blowing insulation into the cavity.",

            commentTitle: "Blowing Insulation",
            xPos: 150,
            yPos: 175,
            startAnim: 0,
            endAnim: 2,
            focalPointOptions: {
                name: "focalPt",
                meshName: "blowerHoseMcopy",
                focalType: "pointer",
                rotation: -90,
                scale: 1,
                offsetX: -5,
                offsetY: 0,
            },
            btnText: "Start",
            speedRatio: 0.1,
            animation: "ANIM-NetAndBlow_CavityFill",
            target: "blowerHoseMcopy",
            postAnimationId: "",
            autoAdvance: true,
            navOptions: { displayPopupAsNavPanel: true },
        },
        active: false,
        complete: false,
    },

    {
        id: "intro-netBlowComplete",
        parentId: "netblow-interactive",
        task: "onNetBlow",
        priority: 7,
        inst: "",
        type: "popup",
        fnId: "",
        params: {
            autoAdvance: false,
            text: "Congrats! You have successfully completed the Net & Blow Insulation Training. <br><br> You can review the training steps in the official installation guide for additional information.",
            title: "Net & Blow Interactive Installation",
            xPos: 150,
            yPos: 175,
            btnText: "Exit Hotspot",
        },
        active: false,
        complete: false,
    },
];

export let tasksNetAndBlow : TaskListItem[] = [
    {
        id: "netblow-interactive",
        courseId: 2,
        title: "Net & Blow Installation",
        desc: "",
        active: false,
        complete: false,
        xPos: -8.5,
        yPos: 1.4,
        zPos: -9.5,
        hoverMesh: null,
        destroyOnInit: true,
        taskFn: "onNetBlow",
        navOptions: { displayPopupAsNavPanel: true },
        cameraOptions: {
            xPos: -6.87,
            yPos: 1.36,
            zPos: -8.4,
            FOV: 1.219,
            targetMesh: null,
            rotationX: Tools.ToRadians(5.5),
            rotationY: Tools.ToRadians(255),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },

    {
        id: "netblow-bestpractices",
        courseId: 2,
        title: "Best Practices",
        desc: "",
        active: false,
        complete: false,
        xPos: -8.5,
        yPos: 1.4,
        zPos: -8,
        hoverMesh: null,
        taskFn: "onBestPractices",
        cameraOptions: {
            xPos: -6.87,
            yPos: 1.36,
            zPos: -8.4,
            FOV: 1.219,
            targetMesh: null,
            rotationX: Tools.ToRadians(4.2),
            rotationY: Tools.ToRadians(297),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },

    {
        id: "netblow-specialcases",
        courseId: 2,
        title: "Special Cases",
        desc: "",
        active: false,
        complete: false,
        xPos: -7.249,
        yPos: 1.4,
        zPos: -9.72,
        hoverMesh: null,
        taskFn: "onSpecialCases",
        cameraOptions: {
            xPos: -6.87,
            yPos: 1.36,
            zPos: -8.4,
            FOV: 1.219,
            targetMesh: null,
            rotationX: Tools.ToRadians(6.4),
            rotationY: Tools.ToRadians(234),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
];
