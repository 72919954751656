import React from "react";

export default class UiOverlayState {
    public ShowingTransition: boolean = true;
    public TransitionExtraClass: string = "";
    public ShowHeader: boolean = true;
    public ShowFooter: boolean = true;
    public ShowIntro: boolean = true;
    public ShowTrainingHub:boolean= false;
    public ShowSafetyTools:boolean= false;
    public ShowProductMenu: boolean = false;
    public ShowTrainingWindow: boolean = false;
    public ShowPopupWindow: boolean = false;
    public ShowToolStrip: boolean = false;
    public ShowNotepad: boolean = false;
    public ShowTaskList: boolean = false;
    public ShowVideoOverlay: boolean = false;
    public ShowPdfOverlay: boolean = false;
    public ShowImageTextOverlay: boolean = false;
    public PopupData: Object = {posX:200};
    public ShowReturnButton:boolean=false;
    public CourseId:string;
	public CourseTitle:string;
    public CourseName:string;
	public CourseCameraDefaults: any;
    public ShowQuiz:boolean=false;
   // public TaskData:any;
	public enableAudio: boolean;
    public ShowStartSceneBtn:boolean = false;
    public CourseComplete:boolean = false;

    public ShowMap: boolean = false;
    public ShowNavigationButton: boolean = false;
    public NavigationButtonText: string = "";
    public OnClickNavigate: () => void;
    public OnClickStartButton: () => void;

    public IntroFadingOut: boolean = false;
    public IntroFadingIn: boolean = false;

    public DimOverlay: boolean = false;
    public HideOverlay: boolean = true;

    public ShowTutorial: boolean = false;
    public TutorialOpacity: number = 0;
	public showCourseLandingPanel:boolean ;
	public ShowWelcomeMessage:boolean;
	public WelcomeMessageText: string;
	public HidingWelcomeMessage: boolean;
}
