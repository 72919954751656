import React, { Ref, } from "react";
import "./IntroScreen.css";
// import "./IntroScreen.d.ts";
import IntroScreenState from "./IntroScreen.state";

import Configs from "../Config/Configs";
import IntroScreenProps from "./IntroScreen.props";
import IframeFunctions from "./UiComponents/iframe";

import { faExpand, faShare } from '@fortawesome/free-solid-svg-icons'

import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Paper from "@material-ui/core/Paper";
import { Grow } from "@material-ui/core";
import i18next from 'i18next';
import i18n from "../i18n";
import { withTranslation,WithTranslation  } from 'react-i18next';
import UIManager from "../Managers/UIManager";
import { RefObject } from "@fluentui/react";
import { Component, createRef } from "react";
import SVGIcon from '../icons/SVGIcon'
import { assets } from "../assetMap";
declare global {namespace JSX {
    interface IntrinsicElements {
        babylon: any,
        //React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
        model: any,
        camera: any,
        engine: any,
        scene: any,
        scaling: any,
        position: any,
        clearcolor: any,
        events: any,
       
    }
}
}
 class IntroScreenBase extends Component<IntroScreenProps,IntroScreenState, unknown> {

    constructor(props: any) {
        super(props);
        this.state = new IntroScreenState();
     this.hidePopupMssg = this.hidePopupMssg.bind(this) 
     console.log(props) 
    }

    
//     componentDidMount():void{
// console.log("mounted")

//         setTimeout(()=>{
//            this.setState({showIntroMssg: true});
            
//         },4000)
//         setTimeout(()=>{
//            this.setState({showIntroMssg: false});
            
//         },25000)
//     }




private hidePopupMssg():void{
    this.setState({showIntroMssg: false});
}

private showTrainingHub():void{
UIManager.UiOverlayRef.current.setState({ShowIntro:false, ShowTrainingHub:true})
//UIManager.UiOverlayRef.current.setState({ShowIntro:false})
//IframeFunctions.test();
}

private showTrainingHubNoAudio():void{
UIManager.UiOverlayRef.current.setState({ShowIntro:false, ShowTrainingHub:true,enableAudio:false})

}

    private showPrivacyPolicy():void{

    }

    public render(): React.ReactElement {

            return (
            
                <>
               
               
                 
				  
                    <div className="IntroScreenFrostedContainer">
						
                   
                    <div className={this.props.fadingOut ?
                        "IntroScreenFrostedContainerContent Animated FadeOutDown" :
                        this.props.fadingIn ?
                            "IntroScreenFrostedContainerContent Animated FadeInUp" :
                            "IntroScreenFrostedContainerContent Animated"}>

                        <div className="IntroHeadingText">
                        {i18n.t('intro.Welcome')}
                        </div>

                        <div className="IntroSubText">
                        {i18n.t('intro.Subtitle')}
                        </div>
                        {/* <div className="IntroScreenButton primary-button" onClick={() => this.showTrainingHubNoAudio()}>
                        {i18n.t('intro.StartButton').toUpperCase()}
                        </div> */}
                        <div className="IntroScreenButton primary-button" onClick={() => this.showTrainingHub()}>
                        {i18n.t('intro.StartButton').toUpperCase()}
                        </div>
                        <div className="IntroScreenTextLink default-textlink" onClick={() => this.showTrainingHubNoAudio()}>
                        {i18n.t('intro.StartWithoutAudio').toUpperCase()}    <FontAwesomeIcon className="fa-icon"  icon={faArrowRightLong as IconProp} />
                        </div>
						
                        <div className="IntroScreenButton hidden" onClick={() => IframeFunctions.clearPathData()}>
                            Clear Progress
                        </div>
                   
              
                </div>
                 
               
                
                
                 <div className="intro-footer">
                 {i18n.t('intro.Footer-a')}
                 <a href='https://www.knaufnorthamerica.com/en-us/privacy-policy' target='_blank'>{i18n.t('intro.FooterLink')}</a>
                 {/* <a onClick={this.showPrivacyPolicy}> {i18n.t('intro.FooterLink')}</a>  */}
                 {i18n.t('intro.Footer-b')}
                    </div>
<Grow in={false}>
                    <div className="pop-mssg">
                    <FontAwesomeIcon className="fa-icon panel-arrow"  icon={faShare as IconProp} />
                    <Paper elevation={3} onClick={this.hidePopupMssg} >
<div className="mssg-title"> {i18n.t('intro.PopupTitle').toUpperCase()}</div>
<div className="block-wrapper">
    <div className="mssg-block">
        <div className="mssg-icon"><FontAwesomeIcon className="fa-icon"  icon={faLanguage as IconProp} /></div>
        <div> {i18n.t('intro.PopupLang')}</div>
    </div>
	<div>
		Image:<img src={assets.fns.getAssetPath("testLogo.png", "courseAssets","residentialBase", )} />
   
   </div>

    <div className="mssg-block">
        <div className="mssg-icon"><FontAwesomeIcon className="fa-icon"  icon={faVolumeHigh as IconProp} /></div>
        <div> {i18n.t('intro.PopupAudio')}</div>
    </div>

    <div className="mssg-block">
        <div className="mssg-icon"><FontAwesomeIcon className="fa-icon"  icon={faExpand as IconProp} /></div>
        <div> {i18n.t('intro.PopupFullscreen')}</div>
    </div>
</div>
</Paper>
                    </div>
                    </Grow>
                </div> 
                  <div className="IntroScreenDiv"></div>
				 
                </>
                    
            );
        }
}


const IntroScreen = IntroScreenBase

export default  IntroScreen              