import React, {Component, createRef, LegacyRef, MouseEventHandler, RefObject} from "react";
import UINotepadState from "./UiNotepad.state";
import NotepadProps from "./UiNotepad.props";
import Configs from "../../Config/Configs";
import "./UiNotepad.css"
import { Paper, Grow, Button, Popover  } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose } from '@fortawesome/free-solid-svg-icons'
import i18next, { t } from 'i18next'

import i18n from "../../i18n";
//import { withTranslation,WithTranslation  } from 'react-i18next';

export default class UINotepad extends Component<NotepadProps,UINotepadState> {
   
    public notepadRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
    private xPos:number;
    private yPos:number;
    private summary:string;
    private title:string;
    private popupImg:string;
      
        constructor(props: any) {
            super(props);
            this.state= new UINotepadState();
       
        }
 componentDidMount(): void {
    // this.getHSRef()

    }

    public clearState(): void{
        let newState = new UINotepadState()
        this.setState(newState);
    }
 private getHLabel(): string {

    if(this.state.line1Label){
        return this.state.line1Label.toFixed(1) + " in"
    }
 }
 private getVLabel(): string {

    if(this.state.line2Label){
        return this.state.line2Label.toFixed(1) + " in"
    }
 }


    private closeNotepad():void{
        if(this.state.completed === true){
//this.setState({selected:false})
//this.props.onClick()
        }
    }
 
   // this.setState({selectedHotspot:this.state.hsData[hsIdx],hsSummaryOpen: !this.state.hsSummaryOpen})

   
        public render(): React.ReactElement {

                return (
                    <>
                 {this.state.isVisible === true ?  
                <div className="notepad" ref={this.notepadRef}>

<img src={Configs.ContentUrlWithTrailingSlash + "Images/tools/notepad.png"}></img>
<div className="arrow arrow-rt"></div>
<div className="arrow arrow-lt"></div>
<div className="arrow arrow-top"></div>
<div className="arrow arrow-bottom"></div>
<div className="outline"></div>
<div className="hLabel">{this.getHLabel()}</div>
<div className="vLabel">{this.getVLabel()}</div>
                </div>
                :
                ""
                 }
             
                    </>
                )
        }    
}
