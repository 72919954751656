import React, { Component, RefObject } from "react";
// import Rooms from "./Babylon/Main/Rooms/Rooms";
// import LocationManager from "./Managers/LocationManager";
import LocationRouterState from "./LocationRouter.state";
import Login from "./ReactComponents/Login";
import PreLoader from "./ReactComponents/PreLoader";
import { TrainingLocations } from "./TrainingSession.props";
import Buildings from "./Babylon/Main/Rooms/Buildings";
import LoadingScreen from "./ReactComponents/LoadingScreen";
import UIManager from "./Managers/UIManager";
import { GetRoomIdx, GetUrlParameter } from "./ReactComponents/utilities";
import UIData from "./Data/UIData";

import RefTest from "./ReactComponents/reftest";
import ResidentialHouseStates from "./Babylon/Main/Rooms/ResidentialHouse/ResidentialHouseStates";
import IframeFunctions from "./ReactComponents/UiComponents/iframe";
import i18next from "i18next";
import UITextToSpeech from "./ReactComponents/UiComponents/UiTextToSpeech";
import { assets } from "./assetMap";
/**
 * A react component to handle switching between showing different page components
 * Also handles dynamically loading babylon.js once ShowBabylonContainer is set to true
 */
export default class LocationRouter extends Component<unknown,LocationRouterState> {
    private babylonContainer: typeof Component;

    constructor(props: any) {
        super(props);
        this.state = new LocationRouterState();
    }

    private buildingIdx: number;
    private roomIdx: number;
    public componentDidMount(): void {
        this.parseReqParams();
        assets.fns.setCurrentUrl();
    }

    private parseReqParams(): void {
        IframeFunctions.init();
        
        let courseId: number;
        let strCourse:string
        let courseLocation: string = GetUrlParameter("courseId");
        if(courseLocation){
            courseLocation.split('');
            let courseCount = 0
            if(courseLocation[0] === 'c'){
                courseCount = 3
            }
            courseId = courseCount + parseFloat(courseLocation[1]);
            console.log('course frme URL: ', courseId)
            strCourse = courseId.toString();
        }

        let building: string = GetUrlParameter("building");
        let buildingArr: string[] = Object.keys(Buildings).filter(
            (key: string, idx: number) => {
                return typeof Buildings[key as any] === "number";
            }
        );

        if (buildingArr.length > 0) {
            this.buildingIdx = buildingArr.indexOf(building);
        } else {
            this.buildingIdx = 0;
        }
        
        let oldCourseUrl = GetUrlParameter("course");
        if(oldCourseUrl){
            strCourse = oldCourseUrl
            courseId = parseInt(strCourse);
        }
        


        let room: string = GetUrlParameter("room");
        this.roomIdx = GetRoomIdx(room);
        sessionStorage.room = this.roomIdx;

        if (strCourse) {
            if (courseId > -1 && courseId < 3) {
                sessionStorage.building = 2;
            }
            if (courseId >= 3 && courseId < 6) {
                sessionStorage.building = 3;
            }
        } else {
            if (this.buildingIdx) {
                sessionStorage.building = this.buildingIdx;
            } else {
                sessionStorage.building = 2;
            }
        }
        let lang: string = GetUrlParameter("lang");
        if (sessionStorage.lang && sessionStorage.lang !== "") {
            lang = sessionStorage.lang;
        } else {
            if (lang === null || lang === "") {
                lang = "en";
            } else {
                sessionStorage.lang = lang;
            }
        }

        i18next.changeLanguage(lang);
        if (strCourse) {
            setTimeout(() => {
                sessionStorage.courseIdx = strCourse;
                UIManager.UiOverlayRef.current.setState({
                    showCourseLandingPanel: false,
                    ShowIntro: false,
                    ShowTrainingHub: false,
                    ShowSafetyTools: true,
                    CourseId: strCourse,
                    ShowReturnButton: false,
                });
            }, 250);
        }

        // sessionStorage.lang = e.target.value
        // i18n.init({lng:e.target.value})
        // console.log(this.state);
        //UIManager.UiDataRef.current.resetData()
    }

    public showLoadingScreen(): void {
        this.setState({ Loading: true, LoadingScreenOpacity: 1 });
        // this.setState({Loading: true, LoadingScreenOpacity: 0});
    }
    public showIntroScreen(): void {
        // this.setState({Loading: true, LoadingScreenOpacity: 1});
        this.setState({ Loading: false, LoadingScreenOpacity: 0 });
    }

    public hideLoadingScreen(): void {
        this.setState({ LoadingScreenOpacity: 0 });
        this.forceUpdate(() =>
            requestAnimationFrame(() =>
                setTimeout(() => this.setState({ Loading: false }), 550)
            )
        );
    }

    public render(): React.ReactElement {
        // {this.state.Loading &&
        //     <LoadingScreen opacity={this.state.LoadingScreenOpacity} ref={UIManager.LoadingScreenRef}/>}
        // {this.state.ShowBabylonContainer && this.state.BabylonLoaded && <BabylonContainer startingTrainingLocation={this.buildingIdx} startingRoom={this.roomIdx}/>}
        // {/*{this.state.ShowLogin && <Login/>}*/}
        //TODO: Show a loading screen before babylon starts loading

        //  {this.state.ShowBabylonContainer && this.state.BabylonLoaded && <BabylonContainer startingTrainingLocation={this.buildingIdx} startingRoom={this.roomIdx}/>}

        if (this.state.LoadBabylonContainer && !this.state.BabylonLoaded) {
            import("./ReactComponents/BabylonContainer").then((module) => {
                this.babylonContainer = module.default;
                this.setState({ BabylonLoaded: true });
            });
        }

        let BabylonContainer = this.babylonContainer;

        return (
            <>
                {this.state.LoadBabylonContainer &&
                    this.state.BabylonLoaded && (
                        <BabylonContainer
                            showBabylonContainer={
                                this.state.ShowBabylonContainer
                            }
                            startingTrainingLocation={this.buildingIdx}
                            startingRoom={this.roomIdx}
                        />
                    )}

                <UIData ref={UIManager.UiDataRef} />
                <UITextToSpeech ref={UIManager.UiTTLRef} />
            </>
        );
    }
}
