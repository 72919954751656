import ResidentialHouseStates from "../Babylon/Main/Rooms/ResidentialHouse/ResidentialHouseStates";

const GetUrlParameter = (name:string)=> {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const GetRoomIdx=(strRoom:string): number =>{
    let roomIdx: number = 0;
    // this is a problem, only works for residential 
    let roomArr:string[] =  Object.keys(ResidentialHouseStates).filter((key: string,idx:number)=>{
    return typeof ResidentialHouseStates[key as any] === "number"
});

if(roomArr.length >0){
    roomIdx = roomArr.indexOf(strRoom)
   
   }

return roomIdx;
}

const GetFillerText=():string=>{
    let txt = "Let me walk you through our nut spacing and cracked system rim-riding grip configuration. Using a field of half-seized sprats and brass-fitted nickel slits, our bracketed caps and splay-flexed brace columns vent dampers to dampening hatch depths of 1/2 meter from the damper crown to the spurv plinth. How? Well, we bolster 12 Husk Nuts to each girdle jerry, while flex tandems press a task apparatus of ten vertically composited patch hamplers, then pin flam-fastened pan traps at both maiden apexes of the jimjoints. "
    return txt
}

export {GetRoomIdx}
export {GetUrlParameter}
export {GetFillerText}