import React, {Component, createRef, LegacyRef, RefObject} from "react";
import "./UiTrainingWindow.css";
import "./UiVideoOverlay.css";
import VideoOverlayState from "./UiVideoOverlay.state";
import Configs from "../../../Config/Configs";
import PopupWindowState from "./UiPopupWindow.state";
import BabylonManager from "../../../Managers/BabylonManager";
import TrainingManager from "../../../Managers/TrainingManager";
import UIManager from "../../../Managers/UIManager";
import ReactPlayer from 'react-player'
import { getItemStyles } from "@fluentui/react/lib/components/ContextualMenu/ContextualMenu.classNames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Parser from 'html-react-parser';
import {LinkData} from '../../../Data/dataSetTypes'

export default class UiVideoOverlay extends Component<unknown, VideoOverlayState> {

    public videoOverlayRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
public playerRef:RefObject<ReactPlayer> = createRef<ReactPlayer>();
    constructor(props: any) {
        super(props);
        this.state = new VideoOverlayState();
        this.onVideoProgress = this.onVideoProgress.bind(this);
        this.onVideoEnded = this.onVideoEnded.bind(this);
        this.closeVideo = this.closeVideo.bind(this);
    }

    public closeVideo(){
        console.log("close video")
        this.setState({isVisible:false})
        console.log(this.state)
        BabylonManager.MainContext.setCompleteStep(this.state.stepId, this.state.percentComplete)
        BabylonManager.MainContext.resetTaskCamData();
        UIManager.UiOverlayRef.current.setState({ HideOverlay:true,ShowVideoOverlay:false})
//ShowPopupWindow:false,
    }

	private getLink(link:LinkData){
		let targ = "_blank";
		if(link.linkTarget){
			targ = link.linkTarget;
		}
		
		return <div className="link-item text-link" ><a href={link.linkUrl} target={targ} className="text-link">{link.linkText} <FontAwesomeIcon className="ext-link" icon={faExternalLink as IconProp} /></a></div>	
				
			}
		
		
			private getLinkList(){
		
				
				let links = [];
				if(this.state.links && this.state.links.length > 0 ){
		let arrLinks = this.state.links.map((item)=>{
		return this.getLink(item);
		})
		return arrLinks
				}else{
					return ""
				}
					
				}

				
private onVideoProgress(e:any):void{
let dur = this.playerRef.current.getDuration();

     this.setState({percentComplete:e.played * 100})

}
private onVideoEnded():void{
    if(this.state){
    console.log("state", this.state)
    this.setState({percentComplete:100})
    setTimeout(()=>{
        this.closeVideo();
    },1500)
//this.setState({isVisible:false})
    }
}
    
    public render(): React.ReactElement {
        let videoUrl = this.state.videoUrl;
        let closeAction = this.state.closeAction; 
        let vidH = "100%"
		if(this.state.videoText){
			vidH = "100%";
		}
        let vidW = "100%"
        console.log("videoUrl",videoUrl)
       
                return (
                    <>
                    {this.state.isVisible && videoUrl ? 
					<div className={this.state.videoText? 'video-content-wrapper has-text':'video-content-wrapper'}>
                    <div className="VideoOverlayContainer">
                         <div className="ClosePopup" onClick={()=>{this.closeVideo()}}><FontAwesomeIcon icon={faTimesCircle as IconProp} />
						 <div className="close-text"> CLOSE</div>
						 </div>
              
                        <div className="VideoOverlay" ref={this.videoOverlayRef}>
                            
                            <>
                           <ReactPlayer url={videoUrl} controls={true} 
                           playing={true} 
						   config={{youtube:{
							playerVars:{
								showinfo:0,
							modestbranding:1}
						   }}} 
                           onProgress={this.onVideoProgress} height={vidH} width={vidW} onEnded={this.onVideoEnded}
                         ref={this.playerRef}
                           />
<div>{this.state.title}</div>
                            </>
                            
                          <div>
                                  </div>
                            
                        </div>
                    </div>
					
					{this.state.videoText ?
					<div className="video-text">
						
						<div className="video-heading">
							{this.state.videoHeading}
						</div>
						<div>
					{this.state.videoText}
					</div>
					<div className="link-list">
					{this.getLinkList()}
					</div>
					</div>
						:""
	}
					</div>
                    :
                    ""
                    }
                    </>
                );
            }
}