import React, {Component, createRef, LegacyRef, MouseEventHandler, RefObject} from "react";
import UITextToSpeechState from "./UiTextToSpeech.state";
//import NotepadProps from "./UiNotepad.props";
// import Configs from "../../Config/Configs";
import "./UiNotepad.css"
// import { Paper, Grow, Button, Popover  } from "@material-ui/core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { IconProp } from "@fortawesome/fontawesome-svg-core";
// import { faClose } from '@fortawesome/free-solid-svg-icons'
// import i18next, { t } from 'i18next'

// import i18n from "../../i18n";
import UIManager from "../../Managers/UIManager";
//import { withTranslation,WithTranslation  } from 'react-i18next';

export default class UITextToSpeech extends Component<unknown,UITextToSpeechState> {
   
    //public ttlRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
    
      
        constructor(props: any) {
            super(props);
            this.state= new UITextToSpeechState();
       
        }
 
   
        public async handleTTLRequest(txt: string){
            let knaufTxt = this.pronounceFix(txt)
			let cleanTxt = this.removeTags(knaufTxt)
            
            console.log(cleanTxt)

if(this.state.isPlaying === false && UIManager.UiHeaderRef.current.state.audioEnabled === true){
	this.stopAudio();
             type AudioContent={
             data:number[],
             type:string
            }
            type AudioData={audioContent:AudioData}[]
		
		 let audioDataResp = await fetch("https://us-central1-knaufacademy.cloudfunctions.net/getTTSVoice?ttstext=" + encodeURIComponent(cleanTxt)) ;
        
            let audioDataJSON =  await audioDataResp.json();
			let audioData = audioDataJSON.response;
             console.log(audioData)
         if(audioData.length > 0){
          let resp = audioData[0] ;
           console.log(resp)
           //console.log(resp.audioContent.data)
           this.playOutput(resp.audioContent.data as ArrayBuffer)
         }
        }else{
            // console.log("can't play while audio is already playing")
        } 
         
           
          }
      public stopAudio(){
		let audioCtx: AudioContext ;

		let sources = this.state.sources;
		sources.map((src)=>{
			src.stop()
		})


		audioCtx = new window.AudioContext as AudioContext
		audioCtx.close();
		this.setState({sources:[]})
		console.log("stop audio")
	  }
          private playOutput(buffer: ArrayBuffer){
            let audioData = new Uint8Array(buffer) as Uint8Array;
            // console.log(audioData)
          
            let audioCtx: AudioContext ;
            // console.log("audiocontext", window.AudioContext.baseLatency)
           
             audioCtx = new window.AudioContext as AudioContext
          
            //   || window.webkitAudioContext)();
      let source = audioCtx.createBufferSource();
      audioCtx.decodeAudioData(audioData.buffer.slice(0), (buffer: AudioBuffer)=> {
          source.buffer = buffer;
          source.connect(audioCtx.destination);
          source.start(0);
		  let sources = this.state.sources;
		  sources.push(source)
          this.setState({isPlaying:true, sources:sources})
		  
        })
      
      source.onended=()=>{
      
        // console.log("sound ended")
        this.setState({isPlaying:false})
      }
     
          }

public pronounceFix(str:string){
    console.log('string')
    str.replace("*", "");
    console.log(str)
    return str.replace("Knauf", "kaˈnauf");
}
public  removeTags(str:string) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
}


          public render(): React.ReactElement{

            return(
                <>
                <div  ></div>
                </>
            )
        }
}
