import { hot } from "react-hot-loader/root";
import React, {Component, RefObject} from "react";
import ProjectRoot from "./ProjectRoot";
import "./App.css";


/**
 * The app's root component
 */
const App = (): React.ReactElement => (
    <div className="App">
        <ProjectRoot/>
    </div>
);

export default hot(App);
