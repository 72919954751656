import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import transResourcesES from "./translations/transResources.es"
import transResourcesEN from "./translations/transResources.en"

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const defaultNS = "en"
let resources = {...transResourcesEN,...transResourcesES} ;

const getLang = (): string=>{
  console.log("setlang", sessionStorage.lang)
return sessionStorage.lang
}
// export const resources = {
//   en: {
//     translation: {
//       "Welcome to React": "Welcome to Knauf Academy",
//       "NavButtonText.Default":"Return To Product Overview",
//       "NavButtonText.Attic":"Go To Living Room",
//       "NavButtonText.LivingRoom":"Go To Attic",
//     }
//   },
//   es: {
//     translation: {
//       "Welcome to React": "ES-Welcome to Knauf Academy",
//       "NavButtonText.Default":"ES-Return To Product Overview",
//       "NavButtonText.LivingRoom":"Ir a la sala de estar",
//       "NavButtonText.Attic":"Ir al ático",
//     }
//   },
//   fr: {
//     translation: {
//       "Welcome to React": "Bienvenue à Knauf Academy"
//     }
//   }
// };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLang(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;