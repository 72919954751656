import React, {Component, createRef, RefObject} from "react";
import "./UiTrainingWindow.css";
import "./UiPopupWindow.css";
import Configs from "../../../Config/Configs";
import PopupWindowState from "./UiPopupWindow.state";
import BabylonManager from "../../../Managers/BabylonManager";
import TrainingManager from "../../../Managers/TrainingManager";
import UIManager from "../../../Managers/UIManager";
import { TrainingAnswer, TrainingPreCheck, TrainingPreCheckTypes, TrainingProduct, TrainingQuestion, TrainingQuestionTypes } from "../../../TrainingSession.props";
import UiPreCheckSituation from "./UiPreCheckSituation";
import UiPreCheckList from "./UiPreCheckList";
import UiQuestionTrueOrFalse from "./UiQuestionTrueOrFalse";
import UiQuestionMultipleChoice from "./UiQuestionMultipleChoice";
import ResidentialHouseStates from "../../../Babylon/Main/Rooms/ResidentialHouse/ResidentialHouseStates";
//import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { GetFillerText } from "../../utilities";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button, Checkbox, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { StandardRenderingPipeline } from "babylonjs";
import UiProgressDots from "../UiProgressDots";
import { ListItemButton } from "@mui/material";
import CustomScroll from 'react-custom-scroll';
import Parser from 'html-react-parser';

export default class UiPopupWindow extends Component<unknown, PopupWindowState> {

    public popupWindowRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
private currentText: string;
private checkedItems:string[]=[];
    constructor(props: any) {
        super(props);
        this.state = new PopupWindowState();

        this.chkListComplete = this.chkListComplete.bind(this);
    }

    componentDidMount(): void {       
       
    }
    
    componentDidUpdate(): void {    
    }
  
    private resetPopupState(): void {
        // this.setState({
        //   // actionItems:[]
        // });
    }

public readText(): void{
    
	console.log(this.state.popupText)
     if(this.state.popupText !== undefined && this.state.popupText !== "" ){
         console.log("speak popup txt", this.state.popupText)
		 let txtToSpeak = this.state.popupText;
if(this.state.pullQuote){
	txtToSpeak = txtToSpeak + " " + this.state.pullQuote;
}
    UIManager.UiTTLRef.current.handleTTLRequest(txtToSpeak)
this.setState({textHasBeenRead:true})
}
}

    protected onActionButtonClick(): void {

    }
    
private onComplete(stepId:string):void{
	this.setState({arrCheckList:null,showCheckList:false,nextBtnDisabled:true})
    if(stepId){
        BabylonManager.MainContext.setCompleteStep(stepId);
	    BabylonManager.MainContext.attachCamControls();
	    if(this.state.fnComplete){
            //this.state.fnComplete(stepId)
	    }
    }
    this.closePopup()
}
private onBack(stepId:string):void{
	this.checkedItems = [];
	this.setState({arrCheckList:null,showCheckList:false,nextBtnDisabled:true})
    if(stepId){
    BabylonManager.MainContext.prevStep(stepId);
	
//this.state.fnComplete(stepId)
    }
    this.closePopup()
}
    
private onContinue():void{
    BabylonManager.MainContext.handleContinue()

    this.closePopup()
}

private onReplay(stepId:string):void{
    BabylonManager.MainContext.replayStep(stepId)

}
private handleAltFn(fnId:string):void{
    BabylonManager.MainContext.handleFn(fnId)
}
  
private chkListComplete(event: React.ChangeEvent<HTMLInputElement>){
if(event.target.checked === true){
this.checkedItems.push(event.target.id)
}else{
	let idx = this.checkedItems.indexOf(event.target.id);
	if(idx >-1){
		this.checkedItems.splice(idx,1)
	}
}

if(this.checkedItems.length === this.state.arrCheckList.length){
	this.setState({nextBtnDisabled:false})
}else{
	this.setState({nextBtnDisabled:true})
}
}

private getCheckList(arrItems: string[]): ReactJSXElement[]{
return arrItems.map((chkItem,idx)=>{

	return  <ListItemButton role={undefined}  dense>
	  <ListItemIcon>
		<Checkbox
		id={chkItem}
		  edge="start"
onChange={this.chkListComplete}
		  tabIndex={-1}
		  disableRipple
		  
		/>
	  </ListItemIcon>
	  <ListItemText id={chkItem} primary={chkItem} />
	</ListItemButton>
  
})
	
}

private getProceedButton(): ReactJSXElement{
    let jsxButtons : ReactJSXElement
	if(this.state.displayAsNavPanel === false){
  if(this.state.showConfirmBtn === true){
     jsxButtons =   <Button className="primary-button btn-popup closePopup" disabled={false} onClick={()=>{this.closePopup()}} >{this.state.btnText}</Button> 
  }else{
   if(this.state.showContinueBtn === true){
    jsxButtons =   <Button className="primary-button btn-popup onContinue" disabled={this.state.btnDisabled} onClick={()=>{this.onContinue()}} >{this.state.btnText}</Button> 
                      
   }else{
    if(this.state.hideContinueBtn === false){
    jsxButtons = <Button className="primary-button btn-popup stepId" disabled={this.state.btnDisabled} onClick={()=>{this.onComplete(this.state.stepId)}} >{this.state.btnText}</Button> 
    }
   }

  }   
}else{
	// display nav panel for interactive steps
	jsxButtons = <><Button className="primary-button btn-popup hidden" disabled={this.state.currentStep <= 0} onClick={()=>{this.onBack(this.state.stepId)}} >BACK</Button> &nbsp; &nbsp; &nbsp; &nbsp; <Button className="primary-button btn-popup" disabled={this.state.nextBtnDisabled} onClick={()=>{this.onComplete(this.state.stepId)}} >NEXT</Button> 
	
	</>
 
}         
                     
                  
return jsxButtons
}

public setupPopupHoverListener(){
    let popup = document.getElementsByClassName("TxtPopupContainer")[0];
    let tool  = document.querySelector(".drag-tool-icon") as HTMLElement;
    if(popup && tool){
        popup.addEventListener("pointerover", (ev)=>{
        tool.style.opacity = "0";
    })
    popup.addEventListener("pointerout", (ev)=>{
        tool.style.opacity = "1";
    })
    }
}
    protected clearAnimations(): void {
        BabylonManager.MainContext.clearAllAnimations();
    }

public closePopup():void{
	if( this.state.retainPopup === true){
		this.setState({showConfirmBtn:false,showContinueBtn:false,hideContinueBtn:true, showQuizBtn:false})
	}else{
		this.setState({isVisible:false, textHasBeenRead:false, showQuizBtn:false})
		
	}	
	if(this.state.fnComplete){
		//this.state.fnComplete()
	}

	this.state = new PopupWindowState();
	this.checkedItems=[];
setTimeout(()=>{
    UIManager.UiOverlayRef.current.setState({HideOverlay:true})
	
},0)
   

//this.popupWindowRef.current.innerHTML="";

}

public manualClosePopup():void{
	
		this.setState({isVisible:false, textHasBeenRead:false, showQuizBtn:false})
        
		this.state = new PopupWindowState();

setTimeout(()=>{
    UIManager.UiOverlayRef.current.setState({HideOverlay:true})
	
	this.state = new PopupWindowState();
	this.checkedItems=[];
    BabylonManager.MainContext.resetCamera()
	BabylonManager.MainContext.showHotspots()
	BabylonManager.MainContext.completedTaskCleanup()

},0)
   


}

    public render(): React.ReactElement {
if(this.state.textHasBeenRead === false && this.currentText !== this.state.popupText ){
        this.currentText = this.state.popupText
    this.readText()
   }
if(this.popupWindowRef.current){
  
let posX = this.state.posX + "px";
let posY = this.state.posY + "px";
this.popupWindowRef.current.setAttribute('style',`left:${posX};top:${posY}`);
if(this.state.isVisible === true){
   // setTimeout(()=>{
       this.setupPopupHoverListener(); 
   // },200)

}
}
        return (
            
            <> 
            {this.state.isVisible ?  
           
                <div className="PopupWindow" ref={this.popupWindowRef}>
              
                <div className="TxtPopupContainer">
                {/* <div className="ClosePopup" onClick={()=>this.manualClosePopup()}>
                    <FontAwesomeIcon icon={faTimesCircle as IconProp}  /> 
					 <div className="close-text"> CLOSE</div>
					 </div> */}
            
                <div className="popup-title">{this.state.title}</div>
                <div className="popup-text" >{Parser(this.state.popupText)}</div>
				{this.state.pullQuote && this.state.pullQuote !== "" && this.state.pullQuote !== null ?
                <div className="popup-pullquote"  >
					{Parser(this.state.pullQuote)}
				</div>
				:<div className="popup-pullquote hidden" ></div>
				}
             
			  {this.state.arrCheckList ? 
			  <>
			 
			  <CustomScroll>
				<div className="check-list">
				{this.getCheckList(this.state.arrCheckList)}
				</div>
				</CustomScroll>
				</>
			  :""
			  }
			  
			   {this.getProceedButton()}
               &nbsp; &nbsp;
			   {this.state.displayAsNavPanel === false ?
			   <>
                {this.state.showReplayBtn === true ? 
                <Button className="primary-button btn-popup" disabled={this.state.btnDisabled} onClick={()=>{this.onReplay(this.state.stepId)}} >Replay</Button>
                 :""
                    }
                {this.state.showQuizBtn === true ? 
                    <Button className="primary-button btn-popup" onClick={()=>{BabylonManager.MainContext.showQuiz()}} >GO TO QUIZ</Button>
                 :""
                }
                {this.state.showAltBtn === true ? 
                <Button className="primary-button btn-popup" onClick={()=>{this.handleAltFn(this.state.altFnId)}} >{this.state.altBtnText}</Button>
                 :""
                    }
					</>
: ""}

                </div>
                   
                </div>
          
             :
             <div>
                     </div>
               }
            </>
         
        );
    }
}
