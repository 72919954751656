import React, { Component, createRef, LegacyRef, RefObject } from "react";

import UIHotspot from "./UiComponents/UIHotspot";
import Configs from "../Config/Configs";
import "./IntroScreen.css";
import "./TrainingHub.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Paper from "@material-ui/core/Paper";
import {
    AppBar,
    Grow,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Popover,
} from "@material-ui/core";
import i18next, { t } from "i18next";
import TrainingHubState from "./TrainingHub.state";
import {
    faAnglesDown,
    faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Buildings from "../Babylon/Main/Rooms/Buildings";
import { AllBuildingStates, TrainingLocations } from "../TrainingSession.props";
import TrainingManager from "../Managers/TrainingManager";
import i18n from "../i18n";
import { IHsData, TaskListItem } from "../Data/dataSetTypes";
import { withTranslation, WithTranslation } from "react-i18next";
import UIManager from "../Managers/UIManager";
import BabylonManager from "../Managers/BabylonManager";
import Rooms from "../Babylon/Main/Rooms/Rooms";
import SVGIcon from "../icons/SVGIcon";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { assets } from "../assetMap";
import CustomScroll from "react-custom-scroll";
declare global {
    namespace JSX {
        interface IntrinsicElements {
            babylon: any;
            //React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
            model: any;
            camera: any;
            engine: any;
            scene: any;
            scaling: any;
            position: any;
            clearcolor: any;
            events: any;
        }
    }
}

//interface IHsData{id:number,xPos:number,yPos:number, summary:string, tasksComplete:number,totalSections:number,building:string,buildingIdx: number,title:string, popupImg:string,selected:boolean,}
const svgCircle =
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="10 10 472 285" width="100%" height="100%" xml:space="preserve" preserveAspectRatio="none" ><style type="text/css">.st0{fill:none;stroke:#ff0000;stroke-width:12;stroke-miterlimit:10;stroke-linecap: round;}</style><g><g><path class="circ st0" d="	M213.049,46.108c143.575-22.145,239.316,28.042,239.316,91.279C452.365,200.623,344,272.5,243.5,275.5	c-121.309,3.621-228-34.264-228-97.5C15.5,119.632,149-2.5,361,24.5"/></g></g></svg>';

const hsd: IHsData[] = [
    {
        id: 0,
        building: "residential",
        buildingIdx: 0,
        summary:
            "The building professionals' choice: batt insulation for single-family houses, multi-family structures and light commercial projects. As a professional installer or remodeler, your goal is always to get the job done right—on time, on budget and in the best interest of your customer - while growing your business. That's why pros choose fiberglass batt insulation. It installs quickly and easily, provides a snug fit, and delivers excellent thermal and sound control.",
        totalSections: 5,
        xPos: 46,
        yPos: 46.5,
        title: "courses.MTitle-Batts",
        tasksComplete: 2,
        popupImg: "batt_install.jpg",
        selected: false,
    },
    {
        id: 1,
        building: "residential",
        buildingIdx: 0,
        summary:
            "For contractors and professional remodelers, it's essential to complete projects on budget and on time. Pros can work efficiently because blown-in insulation fills spaces around wires, pipes and irregular-shaped cavities. By choosing blown-in insulation, contractors can use their own pneumatic blowing machines and experienced installers. This method enables them to quickly and properly fill large spaces in residential attics, ceilings and walls.",
        totalSections: 5,
        xPos: 45,
        yPos: 23,
        title: "courses.MTitle-Attic",
        tasksComplete: 1,
        popupImg: "loose_fill.jpg",
        selected: false,
    },
    {
        id: 2,
        building: "residential",
        buildingIdx: 0,
        summary:
            "For contractors and professional remodelers, it's essential to complete projects on budget and on time. Pros can work efficiently because blown-in insulation fills spaces around wires, pipes and irregular-shaped cavities. By choosing blown-in insulation, contractors can use their own pneumatic blowing machines and experienced installers. This method enables them to quickly and properly fill large spaces in residential attics, ceilings and walls.",
        totalSections: 5,
        xPos: 71.5,
        yPos: 41.5,
        title: "courses.MTitle-NetBlow",
        tasksComplete: 0,
        popupImg: "net_blow.jpg",
        selected: false,
    },
    {
        id: 3,
        building: "commercial",
        buildingIdx: 1,
        summary:
            "Commercial and industrial environments present unique challenges to building professionals. Specifically, extreme pipe temperatures present safety and efficiency issues engineers and contractors must overcome. Pipe insulation plays an important role in the design and construction of buildings where pipe temperatures can range from 0°F to 1000°F (-18°C to 538°C) in copper, iron, PVC, CPVC, and stainless-steel piping. Fiberglass pipe insulation and accessories are an ideal solution to meet or even exceed commercial and industrial project requirements.",
        totalSections: 5,
        xPos: 58.5,
        yPos: 57,
        title: "courses.MTitle-PipeIns",
        tasksComplete: 0,
        popupImg: "pipe_install.jpg",
        selected: false,
    },
    {
        id: 4,
        building: "commercial",
        buildingIdx: 1,
        summary:
            "Every piping, tank and vessel project is unique and complex. That can make specifying and installing pipe and tank insulation a challenge. That's why our fiberglass products are engineered for demanding commercial and industrial applications. Fiberglass pipe and tank insulation is noted for thermal performance, energy conservation, condensation control and safety. It's lightweight and easy to install with basic tools. You can trust it to meet your needs and requirements for commercial and industrial projects.",
        totalSections: 5,
        xPos: 88.5,
        yPos: 33,
        title: "courses.MTitle-PipeAndTank",
        tasksComplete: 1,
        popupImg: "pipe_tank.jpg",
        selected: false,
    },
    {
        id: 5,
        building: "commercial",
        buildingIdx: 1,
        summary:
            "When specifying HVAC insulation, your goal is to reduce heat transfer. Fiberglass duct wrap insulation can help you accomplish this in a properly designed and installed system. It can improve thermal performance and help control condensation in commercial and residential HVAC systems. In addition, duct wrap insulation is lightweight and easy to install.",
        totalSections: 5,
        xPos: 11,
        yPos: 5,
        title: "courses.MTitle-DuctWrap",
        tasksComplete: 0,
        popupImg: "duct_wrap.jpg",
        selected: false,
    },
];
class TrainingHubBase extends Component<unknown, TrainingHubState> {
    public hsRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);
        this.state = new TrainingHubState();

        this.setSelectedPanel = this.setSelectedPanel.bind(this);
        this.getPanelContent = this.getPanelContent.bind(this);
        this.getActivityData = this.getActivityData.bind(this);
        this.openCourseList = this.openCourseList.bind(this);
        this.closeAllHotspots = this.closeAllHotspots.bind(this);
        this.setListState = this.setListState.bind(this);
        this.getWrapperWidth = this.getWrapperWidth.bind(this);
        this.getWrapperHeight = this.getWrapperHeight.bind(this);
    }

    public hsData: IHsData[] = hsd; //UIManager.UiDataRef.current.hsData //hsd;

    async componentDidMount(): Promise<void> {
        // show return to entrance button

        // this.hsData = UIManager.UiDataRef.current.hsData;
        //this.getActivityData("fsdfsd3423",0);
        if (
            !sessionStorage.building ||
            sessionStorage.building === "" ||
            sessionStorage.building === "-1" ||
            sessionStorage.building === -1
        ) {
            sessionStorage.building = this.getBuildingIdx("Residential");
        }

        this.getActivityData(this.state.selectedPanelIdx);
        this.arrHS = [];
        setTimeout(() => {
            this.getWrapperHeight();
            // this.getWrapperWidth();
            if (UIManager.UiHeaderRef.current) {
                UIManager.UiHeaderRef.current.setState({
                    showToEntranceButton: false,
                });
            }
        }, 50);

        await this.getAllTasks();

        window.addEventListener("resize", () => {
            this.getWrapperHeight();
            // this.getWrapperWidth();
        });

        //let test = UIManager.UiDataRef.current?.getTransData("intro.StartButton");
    }

    private trans(key: string) {
        return UIManager.UiDataRef.current?.getTransData(key);
    }

    private hs: any;
    private arrHS: UIHotspot[] = [];

    private getActivityData(section: number): void {
        let retData;
        let hsData = this.hsData;
        let bld = section; //this.state.selectedPanelIdx
        retData = hsData
        // this was causing a big issue on the hot spots 
        // if (bld === 0) {
        //     retData = hsData.filter((item) => {
        //         return item.building === "residential";
        //     });
        // }
        // if (bld === 1) {
        //     retData = hsData.filter((item) => {
        //         return item.building === "commercial";
        //     });
        // }
        this.setState({ hsData: retData });
    }

    private getCourseItem(hs: IHsData, bldIdx: number): ReactJSXElement {
        let taskInfo = this.getCompletedTasks(hs.id) as unknown as string[];
        if (bldIdx === hs.buildingIdx) {
            return (
                <Accordion
                    key={hs.id}
                    className="course-details"
                    expanded={this.state.expandedList === hs.id}
                    onClick={() => {
                        this.openCourseList(hs.id);
                    }}
                    onChange={this.setListState}
                >
                    <AccordionSummary
                        expandIcon={
                            <FontAwesomeIcon
                                className="fa-icon"
                                icon={faAngleDown as IconProp}
                            />
                        }
                    >
                        <div className="panel-header-content">
                            <SVGIcon iconName="svgAdd" />
                            <div className="panel-title">
                                {i18n.t(hs.title).toUpperCase()}
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="status">
                            {taskInfo[0]} {i18n.t("courses.Of")} {taskInfo[1]}{" "}
                            {i18n.t("courses.HotspotsComplete")}
                        </div>
                        <div className="btn-wrapper">
                            <Button
                                className="primary-button btn-course"
                                onClick={() => {
                                    this.startCourse(
                                        hs.id.toString(),
                                        hs.title
                                    );
                                }}
                            >
                                <div className="btn-text">
                                    {i18n.t(taskInfo[2])}
                                </div>
                                <FontAwesomeIcon
                                    className="fa-icon"
                                    icon={faArrowRightLong as IconProp}
                                />
                            </Button>
                        </div>
                    </AccordionDetails>
                </Accordion>
            );
        }
    }

    public openCourseList(listIdx: number, dir?: string): void {
        // this is a bandaid to a larger issue of how not to use typscript 
      let commercialOffset = 0
        // if(listIdx > 2){
        //   listIdx = listIdx - 3
        // }
        console.log(listIdx)
        

        let hsData = this.state.hsData;
        // let currHS = this.arrHS[listIdx]
        //let currHS = this.arrHS[listIdx] // hsData[listIdx]
        let currHS = this.arrHS.filter((item) => {
            return item.props.index === listIdx;
        })[0];
        console.log('current hot spot')
        console.log(currHS)
        console.log(hsData )
        if (currHS.state.selected === true) {
            this.setState({ hsOpening: true });
        } else {
            this.setState({ hsOpening: false });
        }

        if (this.state.listIsExpanded === true) {
            this.setState({ accOpening: true });
        } else {
            this.setState({ accOpening: false });
        }

        //this.setState({expandedList:listIdx,hsData:hsData})

        //if(listIdx === this.state.expandedList ){
        // this.setState({expandedList:null,hsData:hsData});
        // this.state.listIsExpanded === true &&
        if (currHS.state.selected === true) {
            // hotspot open ready to close
            //  currHS.selected= false;
            if (this.state.listIsExpanded === true) {
                //  currHS.state.selected= false;
                this.closeAllHotspots();
                this.setState({
                    expandedList: null,
                    hsData: hsData,
                    listIsExpanded: false,
                });
            } else {
                // currHS.state.selected= false;
                this.closeAllHotspots();
                this.setState({
                    expandedList: null,
                    hsData: hsData,
                    listIsExpanded: false,
                });
            }
        } else {
            // hotspot closed ready to open
            currHS.setState({ selected: true });
            this.closeAllHotspots();
            if (this.state.listIsExpanded === true) {
                this.setState({
                    expandedList: listIdx,
                    hsData: hsData,
                    listIsExpanded: true,
                });
                // this.setState({hsData:hsData})
            } else {
                this.setState({
                    expandedList: listIdx,
                    hsData: hsData,
                    listIsExpanded: true,
                });
            }
        }

        // setTimeout(() => {
        //     if (currHS.state.selected === true) {
        //         this.setState({ hsOpening: true });
        //     } else {
        //         this.setState({ hsOpening: false });
        //     }

        //     if (this.state.listIsExpanded === true) {
        //         this.setState({ accOpening: true });
        //     } else {
        //         this.setState({ accOpening: false });
        //     }
        // }, 500);
    }

    private setListState(e: any, expanded: boolean): void {
        if (expanded === false) {
            this.setState({ listIsExpanded: false });
            this.closeAllHotspots();
        } else {
            this.setState({ listIsExpanded: true });
        }
    }

    private setSelectedPanel(evt: React.ChangeEvent<{}>, newVal: number): void {
        if (newVal === 1 || newVal === 0) {
            this.setState({ selectedPanelIdx: newVal });

            this.getActivityData(newVal);
            this.closeAllHotspots();

            let buildingIdx: number;

            if (
                newVal === 0 ||
                !sessionStorage.building ||
                sessionStorage.building === ""
            ) {
                sessionStorage.building = this.getBuildingIdx("Residential");
                // BabylonManager.MainContext.changeBuilding(Buildings.Residential,0)
            }
            if (newVal === 1) {
                sessionStorage.building = this.getBuildingIdx("Commercial");
                //BabylonManager.MainContext.changeBuilding(Buildings.Commercial,0)
            }
        }
    }

    private getBuildingIdx(bName: string): number {
        let buildingIdx: number;
        let buildingArr: string[] = Object.keys(Buildings).filter(
            (key: string, idx: number) => {
                return typeof Buildings[key as any] === "number";
            }
        );

        if (buildingArr.length > 0) {
            buildingIdx = buildingArr.indexOf(bName);
        } else {
            buildingIdx = 0;
        }

        return buildingIdx;
    }

    private async getAllTasks() {
        let allTasks =
            (await UIManager.UiDataRef.current.getUserTasks()) as TaskListItem[];
        let tasks = allTasks.filter((item) => {
            return item.hasHotSpot !== false;
        });
        this.setState({ userTasks: tasks });
    }

    private getCompletedTasks(courseIdx: number): string[] {
        let totalCompleted: number = 0;
        let totalTasks = 0;
        let tasks = this.state.userTasks;

        //  console.log("usertasklist", tasks)
        if (tasks && this.state.userTasks && this.state.userTasks.length > 0) {
            let tasksComplete = this.state.userTasks.filter(
                (item: TaskListItem, idx: number) => {
                    return (
                        item.complete === true && item.courseId === courseIdx
                    );
                }
            );
            let allModuleTasks =
                UIManager.UiDataRef.current.taskListData.filter(
                    (item: TaskListItem, idx: number) => {
                        return item.courseId === courseIdx;
                    }
                );
            totalCompleted = tasksComplete.length;
            totalTasks = allModuleTasks.length;
        }
        let strStart = "";
        if (totalCompleted === 0) {
            strStart = "courses.BtnStart-a";
        }
        if (totalCompleted > 0) {
            strStart = "courses.BtnStart-b";
        }
        if (totalCompleted > 0 && totalCompleted >= totalTasks) {
            strStart = "courses.BtnStart-c";
        }

        return [totalCompleted.toString(), totalTasks.toString(), strStart];
    }

    public closeAllHotspots(): void {
        let hsData = this.state.hsData;

        this.setState({ expandedList: null, listIsExpanded: false });

        hsData.map((item, idx) => {
            item.selected = false;
        });
        this.arrHS.map((item, idx) => {
            if (item.state.selected === true) {
                item.setState({ selected: false });
            }
        });

        //  this.setState({expandedList:null})
        //  if(this.hs.state.selected === true){
        // this.hs.setState({selected:false})
        // this.setState({hsData:hsData})
        //   }
    }

    private getWrapperHeight(): void {
        // this was throwing an error don't know what it was suppose to target
        //  let hsw = document.querySelector(".");
        let hsw = document.querySelector(".main-content");
        let cmain = document.querySelector(".main-content");
        if (hsw) {
            let wrapH = cmain.clientHeight;
            let wrapW = cmain.clientWidth;
            let cw = hsw.clientWidth;
            let ch = hsw.clientHeight;
            if (wrapW / wrapH > 2.02) {
                cw = wrapH * 2.02;
                this.setState({
                    hsWrapperWd: cw + "px",
                    hsWrapperHt: wrapH + "px",
                });
            } else {
                ch = wrapW / 2.02;
                this.setState({
                    hsWrapperWd: wrapW + "px",
                    hsWrapperHt: ch + "px",
                });
            }
            ////}else{
            // this.setState({hsWrapperHt: "auto"})
        }
    }

    private getWrapperWidth(): void {
        let hsw = document.querySelector(".");
        if (hsw) {
            let cw = hsw.clientWidth;
            let ch = hsw.clientHeight;
            if (cw / ch < 2.02) {
                ch = cw / 2.02;
                this.setState({ hsWrapperWd: "auto" });
            } else {
                cw = ch * 2.02;
                this.setState({ hsWrapperWd: cw + "px" });
            }
        } else {
            this.setState({ hsWrapperWd: "auto" });
        }
    }
    private getPanelContent(idx: number): any {
        if (idx === 0 && this.state.hsData && this.state.hsData.length > 0) {
            return (
                <>
                    <div className="hub-content-wrapper">
                        <div className="main-content">
                            <div className="courses-label hidden">
                                {i18n.t("courses.ResidentialLabel")}{" "}
                            </div>
                            <div
                                className="hs-model-wrapper"
                                style={{
                                    width: this.state.hsWrapperWd,
                                    height: this.state.hsWrapperHt,
                                }}
                            >
                                <img
                                    src={assets.fns.getAssetPath(
                                        "residentialHouseExterior",
                                        "uiAssets"
                                    )}
                                    className="training-hub-model"
                                />
                                {this.state.hsData ? (
                                    this.state.hsData.map((item, idx) => {
                                        return (
                                            <UIHotspot
                                                ref={(hs) => {
                                                    this.arrHS.length <
                                                    this.state.hsData.length
                                                        ? this.arrHS.push(hs)
                                                        : "";
                                                }}
                                                key={item.id.toString()}
                                                id={"hs" + item.id.toString()}
                                                index={item.id}
                                                xPos={item.xPos}
                                                yPos={item.yPos}
                                                summary={item.summary}
                                                isSelected={item.selected}
                                                onClick={() => {
                                                    this.openCourseList(
                                                        item.id
                                                    );
                                                }}
                                                title={i18n.t(item.title)}
                                                popupImg={item.popupImg}
                                            ></UIHotspot>
                                        );
                                    })
                                ) : (
                                    <div></div>
                                )}
                            </div>{" "}
                        </div>
                        <div className="course-list">
                            <div className="subtitle">
                                {i18n.t("courses.CoursesLabel").toUpperCase()}
                            </div>
                            {this.hsData.map((item) => {
                                return this.getCourseItem(item, idx);
                            })}
                        </div>
                    </div>
                </>
            );
        }
        if (idx === 1) {
            return (
                <>
                    <div className="hub-content-wrapper">
                        <div className="main-content">
                            <div className="courses-label hidden">
                                {i18n.t("courses.ResidentialLabel")}{" "}
                            </div>
                            <div
                                className="hs-model-wrapper"
                                style={{
                                    width: this.state.hsWrapperWd,
                                    height: this.state.hsWrapperHt,
                                }}
                            >
                                <img
                                    src={assets.fns.getAssetPath(
                                        "commercialExterior",
                                        "uiAssets"
                                    )}
                                    className="training-hub-model"
                                />
                                {this.state.hsData ? (
                                    this.state.hsData.map((item, idx) => {
                                        return (
                                            <UIHotspot
                                                key={item.id.toString()}
                                                ref={(hs) => {
                                                    this.arrHS.length <
                                                    this.state.hsData.length
                                                        ? this.arrHS.push(hs)
                                                        : "";
                                                }}
                                                id={"hs" + item.id.toString()}
                                                index={item.id}
                                                xPos={item.xPos}
                                                yPos={item.yPos}
                                                summary={item.summary}
                                                isSelected={item.selected}
                                                onClick={() => {
                                                    this.openCourseList(
                                                        item.id
                                                    );
                                                }}
                                                title={i18n.t(item.title)}
                                                popupImg={item.popupImg}
                                            ></UIHotspot>
                                        );
                                    })
                                ) : (
                                    <div></div>
                                )}
                            </div>{" "}
                        </div>
                        <div className="course-list">
                            <div className="subtitle">
                                {i18n.t("courses.CoursesLabel")}
                            </div>
                            {this.hsData.map((item) => {
                                return this.getCourseItem(item, idx);
                            })}
                        </div>
                    </div>
                </>
            );
        }
    }

    private startCourse(courseId: string, courseTitle: string): void {
        UIManager.UiOverlayRef.current.setState({
            ShowTrainingHub: false,
            ShowSafetyTools: true,
            CourseId: courseId,
            CourseTitle: courseTitle,
            ShowReturnButton: true,
        });
    }

    public render(): React.ReactElement {
        let ready = false;
        if (
            this.hsData &&
            UIManager.UiOverlayRef.current &&
            this.state.userTasks
        ) {
            ready = true;
        }

        return (
            <>
                {ready === true ? (
                    <>
                        <div className="opacity-layer"></div>
                        <div className="training-hub">
                            <div className="training-hub-menu">
                                <AppBar
                                    className="training-menu"
                                    position="static"
                                >
                                    <Tabs
                                        aria-label="Training Locations"
                                        value={this.state.selectedPanelIdx}
                                        onChange={this.setSelectedPanel}
                                    >
                                        <Tab
                                            label={i18n
                                                .t("courses.ResidentialLabel")
                                                .toUpperCase()}
                                            className={
                                                this.state.selectedPanelIdx ===
                                                0
                                                    ? "selected-panel"
                                                    : ""
                                            }
                                        />
                                        <Tab
                                            label={i18n
                                                .t("courses.CommercialLabel")
                                                .toUpperCase()}
                                            className={
                                                this.state.selectedPanelIdx ===
                                                1
                                                    ? "selected-panel"
                                                    : ""
                                            }
                                        />
                                    </Tabs>
                                </AppBar>
                            </div>

                            {this.getPanelContent(this.state.selectedPanelIdx)}
                        </div>
                    </>
                ) : (
                    ""
                )}
            </>
        );
    }
}

const TrainingHub = TrainingHubBase;
export default TrainingHub;
