
interface ISafetyTool{id:number,building:string,priority:number, title:string,desc:string, tips:string[],subDir?:string, model?:string, image?:string, meshId?:string,zoom?:number, inst?:string, currentSet?: string}
interface IPPEData{id:string,label:string,className?:string,correctSel?:boolean,iconFile?:string, hotSpot:boolean,hotSpotId?:string,hotSpotPosX?:number,hotSpotPosY?:number,visible:boolean,alternates?:string, imgFile:string,building:string,section:string,model?:string,modelSubDir?:string, modelTitle?:string,modelDesc?:string, modelTips?:string[], meshId?:string, zoom?:number,active?:boolean, selected?:boolean, correct?:boolean}
type HotSpot = {id:string,hotSpotPosX?:number,hotSpotPosY?:number,populated:boolean,correct?:boolean, popItem?:IPPEData, zoomY?:number, zoomX?:number, zoom?:number, submitted:boolean}

const ppeHotSpots:HotSpot[] =[
  {id:"headwear", hotSpotPosX:392, hotSpotPosY:0, populated:false, zoomY:70, zoomX:0, zoom:2, submitted: false},
  {id:"eyewear", hotSpotPosX:615, hotSpotPosY:4, populated:false, zoomY:70, zoomX:0, zoom:2, submitted: false},
  {id:"face protection", hotSpotPosX:600, hotSpotPosY:10, populated:false, zoomY:70, zoomX:0, zoom:2, submitted: false},
  {id:"clothing", hotSpotPosX:480, hotSpotPosY:20, populated:false, zoomY:26, zoomX:0, zoom:2, submitted: false},
  {id:"handwear", hotSpotPosX:1021, hotSpotPosY:40, populated:false, zoomY:26, zoomX:0, zoom:2, submitted: false},
  {id:"footwear", hotSpotPosX:812, hotSpotPosY:80, populated:false, zoomY:-32, zoomX:0, zoom:2, submitted: false}
]  

const toolsHotSpots:HotSpot[] =[
  {id:"straight edge", hotSpotPosX:220, hotSpotPosY:20, populated:false, zoomY:26, zoomX:20, zoom:2, submitted: false},
  {id:"measuring tool", hotSpotPosX:550, hotSpotPosY:15, populated:false, zoomY:43, zoomX:3, zoom:2, submitted: false},
  {id:"cutting tool", hotSpotPosX:787, hotSpotPosY:20, populated:false, zoomY:31, zoomX:-12, zoom:2, submitted: false},
  {id:"insulation cutter", hotSpotPosX:572, hotSpotPosY:28, populated:false, zoomY:26, zoomX:0, zoom:2, submitted: false},
  {id:"stapling tool", hotSpotPosX:806, hotSpotPosY:33, populated:false, zoomY:2, zoomX:-12, zoom:2, submitted: false}
  // {id:"mouth", hotSpotPosX:-8, hotSpotPosY:10, populated:false, zoomY:70, zoom:2, submitted: false}
]



const toolsImgData:IPPEData[]=[
  {
    id:"0",
    label:"Body",
    hotSpot:false, 
    imgFile:"pegboard.png",
    className:"char-body", 
    visible:true, 
    building:"Residential",
    modelSubDir:"Commercial",
    section:"Tools" 
  },
  // cutting tool
  {
    id:"1",
    label:"utility shears",
    hotSpot:true,
    hotSpotId:"cutting tool",
    correctSel:true,
    imgFile:"cutting_shears.png",
    iconFile:"cutting_shears_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"2",
    label:"paper scissors",
    hotSpot:true,
    hotSpotId:"cutting tool",
    correctSel:true,
    imgFile:"paper_scissors.png",
    iconFile:"paper_scissors_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"3",
    label:"aviation snips",
    hotSpot:true,
    hotSpotId:"cutting tool",
    correctSel:true,
    imgFile:"snips.png",
    iconFile:"snips_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"4",
    label:"utility knife",
    hotSpot:true,
    hotSpotId:"cutting tool",
    correctSel:true,
    imgFile:"utility_knife.png",
    iconFile:"utility_knife_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:true
  },

  // measuring tool
  {
    id:"5",
    label:"scale ruler",
    hotSpot:true,
    hotSpotId:"measuring tool",
    correctSel:true,
    imgFile:"scale_ruler.png",
    iconFile:"scale_ruler_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"6",
    label:"tape measure",
    hotSpot:true,
    hotSpotId:"measuring tool",
    correctSel:true,
    imgFile:"tapemeasure.png",
    iconFile:"tapemeasure_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:true
  },
  {
    id:"7",
    label:'6\" wooden ruler',
    hotSpot:true,
    hotSpotId:"measuring tool",
    correctSel:true,
    imgFile:"wood_ruler.png",
    iconFile:"wood_ruler_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"8",
    label:"drafting compass",
    hotSpot:true,
    hotSpotId:"measuring tool",
    correctSel:true,
    imgFile:"compass.png",
    iconFile:"compass_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  // straight edge
  {
    id:"9",
    label:"straight edge ruler",
    hotSpot:true,
    hotSpotId:"straight edge",
    correctSel:true,
    imgFile:"se_ruler.png",
    iconFile:"se_ruler_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:true
  },
  {
    id:"10",
    label:"block of wood",
    hotSpot:true,
    hotSpotId:"straight edge",
    correctSel:true,
    imgFile:"wood_block.png",
    iconFile:"wood_block_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"11",
    label:"broom handle",
    hotSpot:true,
    hotSpotId:"straight edge",
    correctSel:true,
    imgFile:"broom_hadle.png",
    iconFile:"broom_hadle_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"12",
    label:"tool box",
    hotSpot:true,
    hotSpotId:"straight edge",
    correctSel:true,
    imgFile:"toolbox.png",
    iconFile:"toolbox_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  // insulation cutter
  {
    id:"13",
    label:"hacksaw",
    hotSpot:true,
    hotSpotId:"insulation cutter",
    correctSel:true,
    imgFile:"hacksaw.png",
    iconFile:"hacksaw_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"14",
    label:"insulation knife",
    hotSpot:true,
    hotSpotId:"insulation cutter",
    correctSel:true,
    imgFile:"insulation_knife.png",
    iconFile:"insulation_knife_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:true
  },
  {
    id:"15",
    label:"rotary cutter",
    hotSpot:true,
    hotSpotId:"insulation cutter",
    correctSel:true,
    imgFile:"rotary_cutter.png",
    iconFile:"rotary_cutter_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"16",
    label:"kitchen knife",
    hotSpot:true,
    hotSpotId:"insulation cutter",
    correctSel:true,
    imgFile:"kitchen_knife.png",
    iconFile:"kitchen_knife_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  // stapling tool
  {
    id:"17",
    label:"Pliers",
    hotSpot:true,
    hotSpotId:"stapling tool",
    correctSel:true,
    imgFile:"pilars.png",
    iconFile:"pilars_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"18",
    label:"hammer",
    hotSpot:true,
    hotSpotId:"stapling tool",
    correctSel:true,
    imgFile:"hammer.png",
    iconFile:"hammer_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"19",
    label:"staple gun",
    hotSpot:true,
    hotSpotId:"stapling tool",
    correctSel:true,
    imgFile:"staple_gun.png",
    iconFile:"staple_gun_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:true
  },
  {
    id:"20",
    label:"screw driver",
    hotSpot:true,
    hotSpotId:"stapling tool",
    correctSel:true,
    imgFile:"screwdriver.png",
    iconFile:"screwdriver_icon.png",
    className:"over-peg",
    visible:false,
    building:"Residential",
    section:"Tools",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"21",
    label:"hose",
    hotSpot:true,
    hotSpotId:"extra item",
    correctSel:true,
    imgFile:"hose.png",
    iconFile:"screwdriver_icon.png",
    className:"over-peg extra-tool",
    visible:true,
    building:"Residential",
    section:"Tools",
    active:true,
    selected:true,
    correct:true
  },
  {
    id:"22",
    label:"latter",
    hotSpot:true,
    hotSpotId:"extra item",
    correctSel:true,
    imgFile:"latter.png",
    iconFile:"screwdriver_icon.png",
    className:"over-peg extra-tool",
    visible:true,
    building:"Commercial",
    section:"Tools",
    active:true,
    selected:true,
    correct:true
  },
]

const ppeImgData:IPPEData[]=[
  {
    id:"3",
    label:"Body",
    hotSpot:false,
    imgFile:"body_base_layer.png",
    className:"char-body",
    visible:true,
    building:"Residential",
    modelSubDir:"Commercial",
    section:"PPE"
  },
  // headwear
  {
    id:"4",
    label:"Hard Hat",
    hotSpot:true,
    hotSpotId:"headwear",
    correctSel:true,
    imgFile:"hardhat.png",
    iconFile:"hardhat_icon.png",
    className:"over-model char-hardhat",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    model:"hardhat.glb",
    modelTitle:"Hardhat",
    modelDesc:"model description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales dui ligula, sed egestas augue convallis eget. Ut ac fermentum justo. Nullam vel molestie ipsum. Morbi ac quam quis lectus blandit luctus quis eget erat. Nunc in erat vitae nunc euismod facilisis eu sit amet lorem.",
    modelTips:["Integer sodales dui ligula, sed egestas augue eget.","Integer sodales dui ligula, sed egestas augue eget."],
    active:false,
    selected:false,
    correct:true
  },
  {
    id:"5",
    label:"Baseball Cap",
    hotSpot:true,
    hotSpotId:"headwear",
    correctSel:false,
    imgFile:"cap.png",
    iconFile:"cap_icon.png",
    className:"over-model char-cap",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    model:"cap.glb",
    modelTitle:"Baseball Cap",
    modelDesc:"model description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales dui ligula, sed egestas augue convallis eget. Ut ac fermentum justo. Nullam vel molestie ipsum. Morbi ac quam quis lectus blandit luctus quis eget erat. Nunc in erat vitae nunc euismod facilisis eu sit amet lorem.",
    modelTips:["tip1","tip2"],
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"6",
    label:"Beanie",
    hotSpot:true,
    hotSpotId:"headwear",
    correctSel:false,
    imgFile:"beanie.png",
    iconFile:"beanie_icon.png",
    className:"over-model char-beanie",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    model:"cap.glb",
    modelTitle:"Beanie",
    modelDesc:"model description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales dui ligula, sed egestas augue convallis eget. Ut ac fermentum justo. Nullam vel molestie ipsum. Morbi ac quam quis lectus blandit luctus quis eget erat. Nunc in erat vitae nunc euismod facilisis eu sit amet lorem.",
    modelTips:["tip1","tip2"],
    active:false,
    selected:false,
    correct:false
  },
  {
    id:"7",
    label:"Headband",
    hotSpot:true,
    hotSpotId:"headwear",
    correctSel:false,
    imgFile:"bandana.png",
    iconFile:"bandana_icon.png",
    className:"over-model char-bandana",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    model:"cap.glb",
    modelTitle:"Bandana",
    modelDesc:"model description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales dui ligula, sed egestas augue convallis eget. Ut ac fermentum justo. Nullam vel molestie ipsum. Morbi ac quam quis lectus blandit luctus quis eget erat. Nunc in erat vitae nunc euismod facilisis eu sit amet lorem.",
    modelTips:["tip1","tip2"],
    active:false,
    selected:false,
    correct:false
  },

// footwear
  {
    id:"8",
    label:"Work Boots",
    hotSpot:true,
    hotSpotId:"footwear",
    correctSel:true,
    imgFile:"boots.png",
    iconFile:"boots_icon.png",
    className:"over-model char-boots",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    model:"boots.glb",
    modelTitle:"Work Boots",
    modelDesc:"model description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales dui ligula, sed egestas augue convallis eget. Ut ac fermentum justo. Nullam vel molestie ipsum. Morbi ac quam quis lectus blandit luctus quis eget erat. Nunc in erat vitae nunc euismod facilisis eu sit amet lorem.",
    modelTips:["tip1","tip2"],
    active:false,
    selected:false,
    correct:true
  },
  {
    id:"9",
    label:"Sneakers",
    hotSpot:true,
    hotSpotId:"footwear",
    correctSel:false,
    imgFile:"sneakers.png",
    iconFile:"sneakers_icon.png",
    className:"over-model char-sneakers",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    model:"runners.glb",
    modelTitle:"Sneakers",
    modelDesc:"model description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales dui ligula, sed egestas augue convallis eget. Ut ac fermentum justo. Nullam vel molestie ipsum. Morbi ac quam quis lectus blandit luctus quis eget erat. Nunc in erat vitae nunc euismod facilisis eu sit amet lorem.",
    modelTips:["tip1","tip2"],
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"10",
    label:"Sandals",
    hotSpot:true,
    hotSpotId:"footwear",
    correctSel:false,
    imgFile:"flops.png",
    iconFile:"flops_icon.png",
    className:"over-model  char-flops",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    model:"runners.glb",
    modelTitle:"Flip Flops",
    modelDesc:"model description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales dui ligula, sed egestas augue convallis eget. Ut ac fermentum justo. Nullam vel molestie ipsum. Morbi ac quam quis lectus blandit luctus quis eget erat. Nunc in erat vitae nunc euismod facilisis eu sit amet lorem.",
    modelTips:["tip1","tip2"],
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"11",
    label:"Dress Shoes",
    hotSpot:true,
    hotSpotId:"footwear",
    correctSel:false,
    imgFile:"dress_shoes.png",
    iconFile:"dress_shoes_icon.png",
    className:"over-model char-dress-shoes",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    model:"runners.glb",
    modelTitle:"Dress Shoes",
    modelDesc:"model description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales dui ligula, sed egestas augue convallis eget. Ut ac fermentum justo. Nullam vel molestie ipsum. Morbi ac quam quis lectus blandit luctus quis eget erat. Nunc in erat vitae nunc euismod facilisis eu sit amet lorem.",
    modelTips:["tip1","tip2"],
    active:false,
    selected:false,
    correct:false,
  },

// clothing
  {
    id:"12",
    label:"Short Sleeve",
    hotSpot:true,
    hotSpotId:"clothing",
    correctSel:false,
    imgFile:"tshirt.png",
    iconFile:"tshirt_icon.png",
    className:"over-model char-tshirt",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"13",
    label:"Long Sleeve Shirt",
    hotSpot:true,
    hotSpotId:"clothing",
    correctSel:false,
    imgFile:"work_shirt.png",
    iconFile:"work_shirt_icon.png",
    className:"over-model char-work-shirt",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:true,
  },
  {
    id:"14",
    label:"SS Button Up",
    hotSpot:true,
    hotSpotId:"clothing",
    correctSel:false,
    imgFile:"dress_shirt.png",
    iconFile:"dress_shirt_icon.png",
    className:"over-model char-dress-shirt",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"15",
    label:"Tank Top",
    hotSpot:true,
    hotSpotId:"clothing",
    correctSel:false,
    imgFile:"tank_top.png",
    iconFile:"tank_top_icon.png",
    className:"over-model char-tank-top",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },

// EYEwear
  {
    id:"16",
    label:"Visor",
    hotSpot:true,
    hotSpotId:"eyewear",
    correctSel:false,
    imgFile:"visor.png",
    iconFile:"visor_icon.png",
    className:"over-model char-visor",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"17",
    label:"Safety Glasses",
    hotSpot:true,
    hotSpotId:"eyewear",
    correctSel:false,
    imgFile:"safety_glasses.png",
    iconFile:"safety_glasses_icon.png",
    className:"over-model char-saftey_glasses",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:true,
  },
  {
    id:"18",
    label:"Sun Glasses",
    hotSpot:true,
    hotSpotId:"eyewear",
    correctSel:false,
    imgFile:"sunglasses.png",
    iconFile:"sunglasses_icon.png",
    className:"over-model char-sunglasses",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"19",
    label:"Prescription Glasses",
    hotSpot:true,
    hotSpotId:"eyewear",
    correctSel:false,
    imgFile:"p_glasses.png",
    iconFile:"p_glasses_icon.png",
    className:"over-model char-faceshield",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
// face protection
  {
    id:"20",
    label:"Bandana",
    hotSpot:true,
    hotSpotId:"face protection",
    correctSel:false,
    imgFile:"face_bandana.png",
    iconFile:"face_bandana_icon.png",
    className:"over-model char-scarf",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"21",
    label:"Face Buff",
    hotSpot:true,
    hotSpotId:"face protection",
    correctSel:false,
    imgFile:"scarf.png",
    iconFile:"scarf_icon.png",
    className:"over-model char-scarf",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"22",
    label:"Face Shield",
    hotSpot:true,
    hotSpotId:"face protection",
    correctSel:false,
    imgFile:"faceshield.png",
    iconFile:"faceshield_icon.png",
    className:"over-model char-mask",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"23",
    label:"Dust/N95 Mask",
    hotSpot:true,
    hotSpotId:"face protection",
    correctSel:false,
    imgFile:"mask.png",
    iconFile:"mask_icon.png",
    className:"over-model char-mask",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:true,
  },

// HANDwear
  {
    id:"24",
    label:"Neoprene Gloves",
    hotSpot:true,
    hotSpotId:"handwear",
    correctSel:false,
    imgFile:"neoprene_gloves.png",
    iconFile:"neoprene_gloves_icon.png",
    className:"under-shirt char-fingerless-gloves",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"25",
    label:"Open Finger Gloves",
    hotSpot:true,
    hotSpotId:"handwear",
    correctSel:false,
    imgFile:"fingerless_gloves.png",
    iconFile:"fingerless_gloves_icon.png",
    className:"under-shirt char-fingerless-gloves",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },
  {
    id:"26",
    label:"Cut Resistant Gloves",
    hotSpot:true,
    hotSpotId:"handwear",
    correctSel:false,
    imgFile:"work_gloves.png",
    iconFile:"work_gloves_icon.png",
    className:"under-shirt char-work-gloves",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:true,
  },
  {
    id:"27",
    label:"Latex Gloves",
    hotSpot:true,
    hotSpotId:"handwear",
    correctSel:false,
    imgFile:"latex_gloves.png",
    iconFile:"latex_gloves_icon.png",
    className:"under-shirt char-fingerless-gloves",
    visible:false,
    building:"Residential",
    section:"PPE",
    modelSubDir:"Commercial",
    active:false,
    selected:false,
    correct:false,
  },

]
export default class SafetyToolsState {
	public isCompleted:Boolean;
    public safetyData:ISafetyTool[];
    public currentTool:string=null;
    public currentIdx=0;
    public currentItem:ISafetyTool;
    public canView:string;
    public currentHotSpot:string;
    public toolsHotspots =  toolsHotSpots as HotSpot[];
    public ppeHotspots =  ppeHotSpots as HotSpot[];
    public hotSpotList = ppeHotSpots as HotSpot[];
    public selectedItem:IPPEData;
    public activeItem:IPPEData;
    public currentEquipment = ppeImgData as IPPEData[];
    public ppeEquipment = ppeImgData as IPPEData[];
    public toolsEquipment = toolsImgData as IPPEData[];
    public currentSet = 'ppe' as String;
    public ppeIcons:IPPEData[];
    public modelViewerOpen:boolean;
    public readyToEval = false;
    public totalCorrect:number;
    public totalErrors:number;
    public showResults:boolean;
    public introView = true;
    public zoomedView = false;
    public completionTable = 'open'
  }