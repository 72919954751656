/**
 * Enum containing a list of all possible 3D buildings, and None.
 */
enum Buildings {
    None,
    Placeholder,
    Residential,
    Commercial,
}

export default Buildings;
