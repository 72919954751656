import React, { useEffect, useState } from "react"

export interface ProgressUpdate {
  key: string
  value: number
  max: number
}

export const LoadingProgress = () => {
  const [progress, setProgress] = useState<Record<string, {value: number, max: number}>>({})

  useEffect(() => {
    const handleProgress = (event: Event) => {
      const { key, value, max }: ProgressUpdate = (event as CustomEvent).detail
      setProgress({
        ...progress,
        [key]: {value, max}
      })
    }

    document.body.addEventListener('model-loading-progress', handleProgress)

    return () => {
      document.body.removeEventListener('model-loading-progress', handleProgress)
    }
  }, [progress])

  const progressItems = Object.values(progress)
  const value = progressItems.reduce((sum, { value }) => sum + value, 0)
  const max = progressItems.reduce((sum, { max }) => sum + max, 0)

  if (max === 0) {
    return <progress></progress>
  }

  return (
    <progress value={value} max={max}></progress>
  )
}