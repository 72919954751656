import React from "react";
import { TaskListItem } from "../Data/dataSetTypes";

export default class TrainingHubState {
    public selectedPanel:string = "residential";
    public selectedPanelIdx:number = 0;
    public selectedHotspot:{id:number,xPos:number,yPos:number, summary:string, tasksComplete:number,totalSections:number,building:string,title:string, popupImg:string,selected:boolean,};
public hsSummaryOpen:boolean=false;
public hsData:{id:number,xPos:number,yPos:number, summary:string, tasksComplete:number,totalSections:number,building:string,buildingIdx: number,title:string, popupImg:string,selected:boolean,}[];
public IhsData:{id:number,xPos:number,yPos:number, summary:string, tasksComplete:number,totalSections:number,building:string,buildingIdx: number,title:string, popupImg:string,selected:boolean,}[];
public expandedList:number=null;
public listIsExpanded:boolean = false;
public hsOpening:boolean = false;
public accOpening:boolean = false;
public hsWrapperHt:string = null;
public hsWrapperWd:string = null;
public userTasks:any ;//TaskListItem[];
//public showCourseLandingPanel:boolean = false;

}
