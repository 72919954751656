import React, {Component} from "react";
import "./UiQuestionMultipleChoice.css";
import Configs from "../../../Config/Configs";
import { TrainingAnswer } from "../../../TrainingSession.props";
import IUiTrainingAnswerProps from "./UiTrainingAnswer.props";

export default class UiQuestionMultipleChoice extends Component<IUiTrainingAnswerProps> {

    constructor(props: any) {
        super(props);
    }

    private answerKey = this.props.productIndex + "_" + this.props.questionIndex + "_";

    private clearWrongSelection(): void {
        const selections = Array.from(document.getElementsByClassName("QuestionMultipleChoice")) as HTMLElement[];

        selections.forEach((selection: HTMLElement) => {
            selection.firstElementChild.classList.remove("wrong");
        })
    }

    protected onOptionClick(event: React.MouseEvent<HTMLButtonElement>): void {
        const option = event.target as HTMLButtonElement;
        const answerIndex = Number(option.getAttribute("data-answer-index"));

        this.clearWrongSelection();
        this.props.onAnswerClick(option, answerIndex);
    }

    public render(): React.ReactElement {
        return (
            <ul className="TrainingWindowQuestionMultipleChoice">
                {this.props.answers.map((answer: TrainingAnswer, index: number) => {
                    return (
                        <li key={this.answerKey + "_" + index} className="QuestionMultipleChoice">
                            <button onClick={(e) => this.onOptionClick(e)} data-answer-index={index}>{answer.title}</button>
                        </li>
                    );
                })}
            </ul>
        );
    }
}
