 export default class Configs {
    //I can never freaking remember if it has a trailing slash or not so let's just bake it into the name
    public static ContentUrlWithTrailingSlash: string = process.env.NODE_ENV === "production" ? "assets/" : "";

   public static IsMobile(): boolean {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true;
      } else if((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad') {
          return true;
      } else {
          return false;
      }
   }

   public static IsIosSafari(): boolean {
      const is_safari = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") == -1;

      if(/iPhone|iPad|iPod/i.test(navigator.userAgent) && is_safari) {
          return true;
      } else if((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0 && is_safari) || (navigator.platform === 'iPad' && is_safari)) {
         return true;
      } else {
          return false;
      }
   }

   public static IsIos(): boolean {
      if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") == -1) {
          if (/iPhone|iPad|iPod/i.test(navigator.userAgent) || navigator.userAgent.indexOf("Macintosh") > -1) {
              return true;
          }
      }
   }
 }

