// GOOGLE TRANSLATED ONLY
const transResourcesES ={
    "es": {
     "translation": {
      "intro":{
        "Welcome-a": "Bienvenido a la academia",
        "Welcome-b": "de entrenamiento Knauf",
        "Subtitle":"Seleccione entre el entorno residencial o comercial para comenzar su capacitación o visite la Exhibición de productos de Knauf para ver una vista detallada de nuestros productos.",
      "StartButton":"Empezar" ,
      "PopupTitle": "Para la mejor Experiencia",
      "PopupLang": "Seleccione el idioma",
      "PopupAudio": "Activar sonidolscreen",
      "PopupFullscreen": "Ver a pantalla completa",
      "Footer-a":"Usamos cookies para mejorar su experiencia y analizar el uso de nuestro sitio. Por favor, consulte nuestra ",
      "FooterLink": " política de privacidad  ",
      "Footer-b": " política de privacidad para más información."
      },
      "header":{
        "TTLang":"Seleccione el idioma",
        "TTAudio-a":"Silenciar el audio",
        "TTAudio-b":"Habilitar audio",
        "TTFullscreen-a":"Pantalla completa",
        "TTFullscreen-b":"Salir de pantalla completa",
  "Lang-English":"Inglés",
  "Lang-Spanish":"Español"
      },
      "courses":{
        "ResidentialLabel": "Entrenamiento Residencial",
        "CommercialLabel": "Capacitación comercial e industrial",
        "ProductsLabel": "Escaparate de productos",
        "CoursesLabel":"Cursos de formación",
        "MTitle-Batts":"Instalación de bloques",
        "MTitle-Attic":"Ático de relleno suelto",
        "MTitle-NetBlow":"Red y golpe",
        "MTitle-PipeIns":"Aislamiento de tuberías",
      "MTitle-DuctWrap":"Envoltura de conductos",
      "MTitle-DuctLiner":"Revestimiento de conducto",
      "MTitle-PipeAndTank":"Tubería y tanque",
        "Of": "de ",
        "HotspotsComplete":"puntos de acceso completos",
        "BtnStart-a":"Iniciar curso",
        "BtnStart-b":"Continuar Curso",
      },
        "NavButtonText.Default":"ES-Return To Product Overview",
        "NavButtonText.LivingRoom":"Ir a la sala de estar",
        "NavButtonText.Attic":"Ir al ático",
        "Quiz-Ecobatt":{
          "title":"EcoBatt® Insulation",
          "description":"Quality engineered thermal and acoustical fiberglass batt insulation with innovative ECOSE® technology is ideal for floors, walls, ceilings and attics.",
          "Q1":{
            "title":"Almacenamiento previo a la instalación",
            "question": "El embalaje del aislamiento EcoBatt debe abrirse como mínimo 24 horas antes de la instalación.",
          "answers":{
          "true":{"title":"Falsa","response":"¡Correcto! Abra el paquete solo cuando esté listo para instalar. El aislamiento se recuperará y expandirá hasta el grosor indicado en la etiqueta una vez que se abra el paquete." },
          "false":{"title":"Verdadera","response":"¡Ups! Respuesta incorrecta. Intentar otra vez." },
          }}
          
                },
      }
    },
   
  }

  export default transResourcesES