import TrainingManager from "../../../Managers/TrainingManager";
import { TrainingStatus } from "../../../TrainingSession.props";
import { TrainingWindowSections } from "./UiTrainingWindow";

export default class ToolStripState {
   public isVisible: boolean=true;
   public txtPopupIsVisible:boolean= false
   public posX: number=0;
   public posY: number=0;
   public toolIds:string[]= [ "tapemeasure","knife"];
   public isOverToolStrip:boolean = false;
   public isDraggingTool:boolean = false;
   public expandTools:boolean = false;
   public dragToolId:string = null;
public actionList:{label:string,action:any}[];
}
