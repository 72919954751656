import React, { Component, createRef, LegacyRef, RefObject } from "react";
import Configs from "../Config/Configs";
//import IUiDataProps from "../Data/UIData.props"
import UiDataState from "../Data/UiData.state";
import {
    TaskStep,
    TaskListItem,
    UserData,
    UserDataPromise,
    Toolset,
    IHsData,
    PpeStatusPromise,
} from "./dataSetTypes";
import { getTaskListDataSet, getTaskStepsDataSet } from "../Data/datasets";
import TrainingManager from "../Managers/TrainingManager";
import UIManager from "../Managers/UIManager";
import i18n from "../i18n";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { resolve } from "dns";
import { GLTFFileLoader } from "@babylonjs/loaders/glTF";

const firebaseConfig = {
  apiKey: "AIzaSyBX2wPSo44RW8zYmduCADP7AW1yAsqsChU",
  authDomain: "knaufacademy.firebaseapp.com",
  databaseURL: "https://knaufacademy-default-rtdb.firebaseio.com",
  projectId: "knaufacademy",
  storageBucket: "knaufacademy.appspot.com",
  messagingSenderId: "69685494204",
  appId: "1:69685494204:web:7df73389e6b4334f37ebb9",
  measurementId: "G-FF5168GNMX",
};

let app;

//import { withTranslation,WithTranslation, useTranslation  } from 'react-i18next';

export default class UIData extends Component<unknown, UiDataState> {
	constructor(props: any) {
		super(props);
		this.state = new UiDataState();
	}

	public toolSet1: Toolset;
	public taskListData: TaskListItem[] = getTaskListDataSet();
	public taskStepsData: TaskStep[] = getTaskStepsDataSet();

  private cachebuster = '&v=' + Math.round(new Date().getTime() / 1000);
  
    
	async componentDidMount(): Promise<void> {
					// Initialize Firebase
		app = initializeApp(firebaseConfig);
		// handleTTLRequest
		//  let title = document.getElementsByClassName("IntroScreenDiv")[0]
		//  title.addEventListener("click",()=>{
		//   this.handleTTLRequest("test")
		//})
		let userData: UserData;
		if (!localStorage.loginEmail || localStorage.loginEmail === null || localStorage.loginEmail === "" || localStorage.loginEmail === "undefined") {
			let resp = (await this.getTasksWithUserEmail()) as UserDataPromise;
			userData = resp.response as UserData;
			localStorage.loginEmail = userData.email;
			sessionStorage.userData = userData;
		} else {
			console.log("email exists", localStorage.loginEmail);
			userData = (await this.getTasksWithUserEmail(localStorage.loginEmail)) as unknown as UserData;
      console.log('local storage data',  userData)
			if (UIManager.UiOverlayRef.current) {
					this.getUserTasks(UIManager.UiOverlayRef.current.state.CourseId);
			}
			sessionStorage.userData = userData;
		}
	}

	public async setUserTasks(courseId: string, userTasks?: string): Promise<UserDataPromise> {
		let userData = await fetch("https://us-central1-knaufacademy.cloudfunctions.net/saveUserTasks?uid=" + sessionStorage.uid + "&userTasks=" + userTasks + "&courseId=" + courseId + this.cachebuster);
		let userTasksList = await userData.json();
		return Promise.resolve(userTasksList);
	}
    
	public async setUserSteps(userSteps?: string): Promise<UserDataPromise> {
		try {
			let userData = await fetch("https://us-central1-knaufacademy.cloudfunctions.net/saveUserSteps?uid=" + sessionStorage.uid + "&userSteps=" + userSteps + this.cachebuster);
			let userStepsList = await userData.json();
			return Promise.resolve(userStepsList);
		} catch (err) {
			console.log(err);
			return Promise.resolve({});
		}
	}

	public async clearUserSteps(): Promise<UserDataPromise> {
    console.log('RESETTING USER STEPS')
		let userSteps = "{}";
		try {
			let userData = await fetch("https://us-central1-knaufacademy.cloudfunctions.net/saveUserSteps?uid=" + sessionStorage.uid + "&userSteps=" + userSteps);
			let userStepsList = await userData.json();
			return Promise.resolve(userStepsList);
		} catch (err) {
			return Promise.resolve({});
		}
	}

	public async getPPEStatus(ppeKey: string): Promise<PpeStatusPromise> {
		let userData;
		let userTasksListResp: any;
		if (ppeKey) {
			userData = await fetch("https://us-central1-knaufacademy.cloudfunctions.net/getUserTasks?uid=" + sessionStorage.uid + "&courseId=" + ppeKey + this.cachebuster);
			let resp = await userData.json();
			userTasksListResp = resp;
			return Promise.resolve(userTasksListResp);
		}
	}

	public async getUserTasks(courseId?: string): Promise<UserDataPromise> {
		let userData;
		let userTasksListResp: any;

    if (courseId) {
      userData = await fetch("https://us-central1-knaufacademy.cloudfunctions.net/getUserTasks?uid=" + sessionStorage.uid + "&courseId=" + courseId + this.cachebuster);
      let resp = await userData.json();
      userTasksListResp = resp;
    } else {
      userData = await fetch("https://us-central1-knaufacademy.cloudfunctions.net/getUserTasks?uid=" + sessionStorage.uid + this.cachebuster);
      let resp = await userData.json();
      userTasksListResp = resp.taskList;
    }
    
    let userTasksList = this.taskListData;
    console.log(this.cachebuster)
    console.log('data loaded from server',  userTasksList)
    userTasksList.map((task) => {
        let cid = task.courseId.toString();
        if (userTasksListResp) {
          let userCTasks = userTasksListResp[cid];
          if (userCTasks) {
            Object.keys(userCTasks).map((tKey) => {
              if (task.id === userCTasks[tKey].id) {
                task.complete = userCTasks[tKey].complete;
                task.active = userCTasks[tKey].active;
              }
            });
          }
        }
    });

    if (!userTasksList || userTasksList === null) {
      userTasksList = this.taskListData;
    }

    let fullUserTasks = await Promise.resolve(userTasksList as UserDataPromise);
    return fullUserTasks;
	}

  public async getUserSteps(): Promise<UserDataPromise> {
    let userData = await fetch("https://us-central1-knaufacademy.cloudfunctions.net/getUserSteps?uid=" + sessionStorage.uid + this.cachebuster);
    let userStepsListResp = await userData.json();
    let userStepsList = userStepsListResp.stepList;
    if (!userStepsList || userStepsList === null) {
      userStepsList = this.taskStepsData;
    }
    return Promise.resolve(userStepsList);
  }

  private async getTasksWithUserEmail(email?: string): Promise<UserDataPromise> {
      let userData = await fetch("https://us-central1-knaufacademy.cloudfunctions.net/getUserData?email=" + email + this.cachebuster);
      let user = await userData.json();
      sessionStorage.uid = user.response.id;
      return Promise.resolve(user);
  }

  public signOut(): void {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
          console.log("user signed out");
      })
      .catch((error) => {
          console.log("error logging out", error);
      });
  }
  
    public getTransData(strKey: string): string {
        let tranTxt = i18n.t(strKey);
        // console.log("translation",tranTxt)
        return tranTxt;
    }

    public async resetData(): Promise<void> {
        
        this.taskStepsData = getTaskStepsDataSet();
        let currentData = (await Promise.resolve(
            this.getUserTasks(UIManager.UiOverlayRef.current.state.CourseId)
        )) as unknown as TaskListItem[];
        this.taskListData = getTaskListDataSet();
        // merge items status
        // console.log("currentData", currentData)
        this.taskListData.map((item) => {
            let currItem = currentData.filter((curr) => {
                return item.id === curr.id;
            });
            // console.log("currItem", currItem)
            if (currItem.length > 0) {
                let statusItem = currItem[0];
                item.complete = statusItem.complete;
                item.active = statusItem.active;
            }
        });

        //  console.log(  this.taskListData)
        //  console.log(this.taskStepsData)

        this.getFilteredCourseTasks();
    }

    public async getFilteredCourseTasks(): Promise<UserDataPromise> {
        //TaskListItem[]
        let courseId = UIManager.UiOverlayRef.current.state.CourseId;
        // console.log("getdatafor", courseId);
        let userTasks = (await this.getUserTasks(
            UIManager.UiOverlayRef.current.state.CourseId
        )) as unknown as TaskListItem[];
        let tranTasks: TaskListItem[] = this.taskListData; // Promise.resolve(this.getTransTaskData()) as unknown as TaskListItem[]
        //let autoStart = tranTasks.filter((item:TaskListItem)=>{

        // console.log("WHAT LIST!?");
        // console.log("trantasks",tranTasks)
        // console.log(courseId)
        let courseTasks = tranTasks.filter((item: TaskListItem) => {
            let savedTask = userTasks.find((savedTask: TaskListItem) => {
                return item.id === savedTask.id && savedTask.complete === true;
            });

            if (savedTask) {
                item.complete = true;
            }
            return item.courseId.toString() === courseId.toString();
            //&& item.autoStart !== true
        });
        this.taskListData = courseTasks;
        console.log("COURSETASKS", courseTasks);
        UIManager.UiOverlayRef.current.setState({ ShowTaskList: true }); //, TaskData:courseTasks
        UIManager.UiDataRef.current.setState({ tasksData: courseTasks });
        setTimeout(() => {
            if (UIManager.TaskListRef.current) {
                UIManager.TaskListRef.current.setState({
                    taskData: courseTasks,
                });
            }
        }, 200);
        return Promise.resolve(courseTasks as UserDataPromise);
        //return courseTasks
    }

    public getAutostartTasks(): TaskListItem[] {
        let courseId = UIManager.UiOverlayRef.current.state.CourseId;
        // console.log("preautostart", this.taskListData)
        let tranTasks: TaskListItem[] = Promise.resolve(
            this.getTransTaskData()
        ) as unknown as TaskListItem[];
        let autoStart = tranTasks.filter((item: TaskListItem) => {
            // console.log("autostart? : " , item)
            return item.autoStart === true;
        });
        return autoStart;
    }

    public async getTransTaskData(): Promise<any> {
        this.taskListData = (await this.getUserTasks(
            UIManager.UiOverlayRef.current.state.CourseId
        )) as TaskListItem[];

        console.log("resolved", this.taskListData);
        return await this.taskListData;
    }
    public render(): React.ReactElement {
        return (
            <>
                <div></div>
            </>
        );
    }
}

//const UIData = withTranslation()(UIDataBase)
//export default UIData
