import React, {Component} from "react";
import "./UiProductMenuItem.css";
import Configs from "../../Config/Configs";
import BabylonManager from "../../Managers/BabylonManager";
import TrainingManager from "../../Managers/TrainingManager";
import IUiProductMenuItemProps from "./UiProductMenuItem.props";
import UIManager from "../../Managers/UIManager";
import UiProductMenuItemState from "./UiProductMenuItem.state";

export default class UiProductMenuItem extends Component<IUiProductMenuItemProps, UiProductMenuItemState> {

    constructor(props: any) {
        super(props);
        this.state = new UiProductMenuItemState();
    }

    private getProductThumbnail(): string {
        return Configs.ContentUrlWithTrailingSlash + "Images/Products/" + this.props.product.thumbnail;
    }

    protected onProductClick(): void {
        UIManager.UiOverlayRef.current?.showTransition();
        BabylonManager.MainContext.changeRoomState(this.props.product.state);
        TrainingManager.Session.goToProduct(this.props.index);
    }

    public render(): React.ReactElement {
            return (
                <li className={this.state.hovered ? "ProductMenuItem active" : "ProductMenuItem"} onPointerEnter={() => this.setState({hovered: true})} onPointerLeave={() => this.setState({hovered: false})}>
                    <div className="ProductMenuItemInfo">
                        <div className="ProductMenuItemIndex">0{this.props.index + 1}</div>
                        <div className="ProductMenuItemTitle">{this.props.product.title}</div>
                        <div className="ProductMenuItemDetails">
                            <div className="ProductMenuItemDetailsTitle">{this.props.product.title}</div>
                            <div className="ProductMenuItemDetailsDescription">{this.props.product.description}</div>
                            <button onClick={() => this.onProductClick()}>Start</button>
                        </div>
                    </div>
                    <div className="ProductMenuItemImage" style={{ backgroundImage: `url(${this.getProductThumbnail()})`}} />
                </li>
            );
        }
}
