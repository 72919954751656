import {Tools, Vector3 } from "@babylonjs/core";
import { assets } from "../../assetMap";
import {ImgTextData, TaskListItem} from "../dataSetTypes"

let meshNames ={
	toolHub:"toolsetHub",
	tapeMeasureBlue:"tapeMeasureBlue",
}

let iconNames ={	
	tapeMeasure:"tapemeasure",	
	utilityKnife:"utilityknife"
}

const ductYouKnow1: ImgTextData[] = [{
  heading: "Did You Know?",
  // imageFileName: "assets/images/Dig_Out.png",
  imageFileName:assets.fns.getAssetPath("kwikFlex", "courseAssets","ductWrap").toString(),
  strHtml: "Did you know that pipe and tank can also be used for round and or oval duct work for both commercial and industrial? ",
  arrLinks: [],
}]

const ductYouKnow2: ImgTextData[] = [{
  heading: "Temporary Testing Pipes",
  // imageFileName: "assets/images/Dig_Out.png",
  imageFileName:assets.fns.getAssetPath("wrapforPP", "courseAssets","ductWrap").toString(),
  strHtml: "Did you know that duct wrap can also be used for <b>temporary personal protection</b> while hydro testings is taking place prior to installing insulation. Piping can not exceed 250 degrees F.",
  arrLinks: [],
}]

export let ductMeasureTools = {
	parentMeshName:meshNames.toolHub,
	position: new Vector3(-3.722, 5.1, 5),   
  rotation: new Vector3(Tools.ToRadians(90),0,0),
	onFloor:true,
	toolItems:[
			{name: meshNames.tapeMeasureBlue, hasHitbox:true,iconName:iconNames.tapeMeasure, isCorrect:false,position: new Vector3(0,.01,0), scaling: new Vector3(.5,.5,.5),rotation: new Vector3(Tools.ToRadians(0),Tools.ToRadians(0),Tools.ToRadians(0))}
	]
}
export let ductMeasureTools2 = {
	parentMeshName:meshNames.toolHub,
  position: new Vector3(-2.75, 5.011, 5),
  rotation: new Vector3(0,Tools.ToRadians(-90),0),
	onFloor:true,
	toolItems:[
			{name: meshNames.tapeMeasureBlue, hasHitbox:true,iconName:iconNames.tapeMeasure, isCorrect:false,position: new Vector3(0,.01,0), scaling: new Vector3(.5,.5,.5),rotation: new Vector3(Tools.ToRadians(0),Tools.ToRadians(0),Tools.ToRadians(0))}
	]
}

export let hotspotsDuctWrap = [
  {
    id: "didYouKnow",
    parentId: "did-you-know",
    task: "viewDidYouKnow",
    priority: 0,
    inst: "",
    type: "imagetext",
    fnId: "",
    params: {
      panelData: ductYouKnow1
    },
    active: false,
    complete: false,
  },
  {
    id: "temporaryTesting",
    parentId: "temporary-testing",
    task: "viewTemporaryTesting",
    priority: 0,
    inst: "",
    type: "imagetext",
    fnId: "",
    params: {
      panelData: ductYouKnow2
    },
    active: false,
    complete: false,
  }

]

export let interactiveDuctWrap = [
  {
    id: "step-1-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 1,
    inst: "",
    type: "popup",
    fnId: "",
    params: {
      // autoAdvance: false,
      text: "Welcome to the Duct Wrap Installation Training.<br/><br/>Here you will learn all of the steps required for installing the Atmosphere® Duct Wrap Insulation and ensuring correct duct covering.<br/><br/>Browse through the mechanical room and hotspots to learn more about the process. If you have any questions, please reach out to anyone of your Academy Team members.",
      title: "DUCT WRAP INTERACTIVE INSTALLATION",
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Start Training",
      navOptions: { displayPopupAsNavPanel: false },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-2-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 2,
    inst: "",
    type: "checklist",
    fnId: "",
    params: {
      text: "Before installation, make sure you have completed the jobsite preparation.",
      checklist: ['Install over clean, dry sheet metal ducts.', 'All sheet metal joints and seams must be sealed to prevent air leakage.'],
      title: "DUCT WRAP INTERACTIVE INSTALLATION",
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
    },
    
    active: false,
    complete: false,
  },
  {
    id: "step-3-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 3,
    inst: "",
    type: "simple-measure",
    fnId: "",
  
    params: {
      tools: ductMeasureTools,
      measureAxis:"x",
      actualDrawAxis:"x",
      dragAxis:"x",
      // drawAxis:"z",
      measureTarget:'Air_Duct_Section_02.002',
      measureTargetId:'Air_Duct_Section_02.002',
      measureTargetPoint: new Vector3(10.5, 10.10, -6),
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      comment: "Select the tape measure to pick it up. Next, press on one edge of the duct and then press on the opposite edge to measure the diameter.",
      commentTitle: "Measure Duct Perimeter",
      retainPopup: true,
      zoomOptions: {
        name: "zoomDuctMeasure",
        pos: new Vector3(-3.4, 6.5, 5),
        rotation: new Vector3(
          Tools.ToRadians(90),
          Tools.ToRadians(-90),
          Tools.ToRadians(0)
        ),
        fov: 1,
      },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-measure1-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 3.25,
    inst: "",
    type: "popup",
    fnId: "",
  
    params: {
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      text: "You have measured <span id='measure-in-step'>measurementVar inches.</span> Click next to continue",
      title: "MEASURE DUCT OUTER Perimeter",
      zoomOptions: {
        name: "zoomDuctMeasure",
        pos: new Vector3(-3.4, 6.5, 5),
        rotation: new Vector3(
          Tools.ToRadians(90),
          Tools.ToRadians(-90),
          Tools.ToRadians(0)
        ),
        fov: 1,
      },
    },
    
    active: false,
    complete: false,
  },
  {
    id: "step-measure2-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 3.5,
    inst: "",
    type: "simple-measure",
    fnId: "",
  
    params: {
      tools: ductMeasureTools2,
      measureAxis:"y",
      actualDrawAxis:"y",
      dragAxis:"y",
      // drawAxis:"y",
      measureTarget:'Air_Duct_Section_02.002',
      measureTargetId:'Air_Duct_Section_02.002',
      measureTargetPoint: new Vector3(10.5, 10.10, -6),
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      comment: "Select and drag the measuring tape to the bottom edge of the duct to measure the second side.",
      commentTitle: "MEASURE DUCT PERIMETER",
      retainPopup: true,
      zoomOptions: {
        name: "zoomDuctMeasure2",
        pos: new Vector3(-1.55, 5, 5),
        rotation: new Vector3(
          Tools.ToRadians(0),
          Tools.ToRadians(-90),
          Tools.ToRadians(0)
        ),
        fov: 1,
      },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-measure3-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 3.75,
    inst: "",
    type: "popup",
    fnId: "",
  
    params: {
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      text: "You have measured <span id='measure-in-step'>measurementVar inches.</span> Click next to continue",
      title: "MEASURE Duct OUTER Perimeter",
      zoomOptions: {
        name: "zoomDuctMeasure2",
        pos: new Vector3(-1.55, 5, 5),
        rotation: new Vector3(
          Tools.ToRadians(0),
          Tools.ToRadians(-90),
          Tools.ToRadians(0)
        ),
        fov: 1,
      },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-measure-end-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 3.9,
    inst: "",
    type: "popup",
    fnId: "",
  
    params: {
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      text: "To calculate the circumference, add up the two measurements and multiply by 2.  <span id='measure-in-step'>measurementVar</span> Click next to continue",
      title: "MEASURE DUCT OUTER Perimeter",
      zoomOptions: {
        name: "zoomDuctMeasure2",
        pos: new Vector3(-1.55, 5, 5),
        rotation: new Vector3(
          Tools.ToRadians(0),
          Tools.ToRadians(-90),
          Tools.ToRadians(0)
        ),
        fov: 1,
      },
    },
    active: false,
    complete: false,
  },
  
  {
    id: "step-4-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 4,
    inst: "",
    type: "popup",
    fnId: "",
  
    params: {
      text: 'To determine the length of duct wrap insulation required, you will need to make a calculation based on the shape of the duct and thickness of insulation needed for that particular application.<br/><br />In this installation, you will be installing 2 inches thick insulation onto a rectangular duct.<br/><br/>The strech out formula for a rectangular duct with 2 inch thick insulation is Perimeter plus 8 inches. <span id="link-in-step">To ensure that you are using the correct thickness, as well as proper "stretch out" lengths, refer to the Product Data Sheet.</span>',
      title: "IDENTIFY CORRECT INSULATION THICKNESS",
      xPos: 25,
      yPos: 25,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      zoomOptions: {
        name: "zoomDuctBox",
        pos: new Vector3(-2, 2, 1.7),
        rotation: new Vector3(
          Tools.ToRadians(10),
          Tools.ToRadians(-220),
          Tools.ToRadians(0)
        ),
        fov: 1,
      },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-5-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 5,
    inst: "",
    type: "clicked-animation",
    fnId: "",
  
    params: {
      comment: 'Select the utility knife to pick up and open the duct wrap package.',
      commentTitle: "Open Duct Wrap packaging",
      xPos: 25,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      autoAdvance: false,
      destroyOnInit: false,
      animation: "ANIM-DuctWrap_PackageOpening_01",
      animId: "ANIM-DuctWrap_PackageOpening_01",
      startAnim: 1.5,
      endAnim: 14,
      correctToolName: "DuctWrap_UtilityKnife",
      targetName: "DuctWrap_UtilityKnife_primitive0",
      zoomOptions: {
        name: "zoomDuctBox2",
        pos: new Vector3(-2, 2.5, 4),
        rotation: new Vector3(
          Tools.ToRadians(10),
          Tools.ToRadians(-200),
          Tools.ToRadians(0)
        ),
        fov: 0.7,
      },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-6-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 6,
    inst: "",
    type: "clicked-animation",
    fnId: "",
  
    params: {
      comment: 'Press on the insulation to roll out and cut at the “stretch out” length including the 2"  flap.',
      commentTitle: "Roll Out and cut insulation",
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      autoAdvance: false,
      destroyOnInit: false,
      animation: "ANIM-DuctWrap_PackageOpening_01",
      animId: "ANIM-DuctWrap_PackageOpening_01",
      startAnim: 14,
      endAnim: 28,
      correctToolName: "DuctWrap_Roll.001_primitive1",
      targetName: "DuctWrap_Roll.001_primitive1",
      zoomOptions: {
        name: "zoomDuctBox3",
        pos: new Vector3(-2.5, 2.5, 4),
        rotation: new Vector3(
          Tools.ToRadians(10),
          Tools.ToRadians(-190),
          Tools.ToRadians(0)
        ),
        fov: 1,
      },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-7-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 7,
    inst: "",
    type: "clicked-animation",
    fnId: "",
  
    params: {
        commentTitle: "Apply duct wrap",
        comment: "Press on the duct wrap to apply around duct.",
        xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
        yPos: 175,
        btnText: "Next",
        navOptions: { displayPopupAsNavPanel: false },
        autoAdvance: false,
        destroyOnInit: false,
        animation: "Anim-DuctwrapApplicationAndStaple",
        animId: "Anim-DuctwrapApplicationAndStaple",
        startAnim: 0,
        endAnim: 7.25,
        animationName: "Anim-DuctwrapApplicationAndStaple",
        targetName: "DuctWrap_Insulation_primitive0",
  
        zoomOptions: {
          name: "zoomDuctWrap",
          pos: new Vector3(-1.55, 5.75, 5),
          rotation: new Vector3(
            Tools.ToRadians(30),
            Tools.ToRadians(-90),
            Tools.ToRadians(0)
          ),
          fov: 1,
        },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-8-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 8,
    inst: "",
    type: "clicked-animation",
    fnId: "",
  
    params: {
        comment: 'Press on the staple gun to staple longitudinal and perimeter lap seams.',
        commentTitle: "staple lap seams",
        xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
        yPos: 175,
        btnText: "Next",
        navOptions: { displayPopupAsNavPanel: false },
        autoAdvance: false,
        destroyOnInit: false,
        animation: "Anim-DuctwrapApplicationAndStaple",
        animId: "Anim-DuctwrapApplicationAndStaple",
        startAnim: 8.628,
        endAnim: 13.4,
        animationName: "Anim-DuctwrapApplicationAndStaple",
        targetName: "StapleGun",
    },
    active: false,
    complete: false,
  },
  {
    id: "step-9-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 9,
    inst: "",
    type: "clicked-animation",
    fnId: "",
  
    params: {
      comment: "Select the FSK strip to apply tape to all seams.<span id='link-in-step'>Foil, scrim and kraft tape (FSK tape) has laminate backing for use on fibrous and sheet metal ducts. This flexible backing conforms well to curved or irregular surfaces</span>",
      commentTitle: "Apply FSK tape",
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      animation: "ANIM-Ductwrap_TapeAndSqueegee",
      animId: "ANIM-Ductwrap_TapeAndSqueegee",
      startAnim: 0.1,
      endAnim: 7.49,
      animationName: "ANIM-Ductwrap_TapeAndSqueegee",
      targetName: "LongitudSeamLap",
    },
    active: false,
    complete: false,
  },  
  {
    id: "step-10-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 10,
    inst: "",
    type: "clicked-animation",
    fnId: "",
  
    params: {
        // autoAdvance: false,
        comment: 'Select the squeegee to rub down all seams.',
        commentTitle: "RUB DOWN SEAMS",
        xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
        yPos: 175,
        btnText: "Next",
        navOptions: { displayPopupAsNavPanel: false },
        animation: "ANIM-Ductwrap_TapeAndSqueegee",
        animId: "ANIM-Ductwrap_TapeAndSqueegee",
        startAnim: 7.8,
        endAnim: 18,
        animationName: "ANIM-Ductwrap_TapeAndSqueegee",
        targetName: "DuctWrap_Squeegee",
    },
    active: false,
    complete: false,
  },
  {
    id: "step-11-duct-wrap",
    parentId: "duct-wrap",
    task: "viewDuctWrap",
    priority: 11,
    inst: "",
    type: "popup",
    fnId: "",
  
    params: {
        // autoAdvance: false,
        text: '<b>You have successfully completed the<br/>Duct Wrap Insulation Training.</b>',
        title: "CONGRATULATIONS!",
        xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
        yPos: 175,
        btnText: "EXIT HOTSPOT",
        navOptions: { displayPopupAsNavPanel: false },
    },
    active: false,
    complete: false,
  }

]


export let tasksDuctWrap:TaskListItem[] = [
  {
    id: "did-you-know",
    courseId: 5,
    title: "Did you know",
    desc: "",
    active: false,
    complete: false,
    xPos: -1.5,
    yPos: 5,
    zPos: 7,
    hoverMesh: null,
    taskFn: "viewDidYouKnow",
    cameraOptions: {
      xPos: -1.7,
      yPos: 5.25,
      zPos: 5,
      FOV: 0.7,
      targetMesh: null,
      rotationX: Tools.ToRadians(0), //9.96
      rotationY: Tools.ToRadians(30),
      rotationZ: 0,
      rotationEnabled: false,
      navEnabled: false,
      zoomEnabled: false
    }
  },
  {
    id: "temporary-testing",
    courseId: 5,
    title: "Temporary Testing",
    desc: "",
    active: false,
    complete: false,
    xPos: -2.75,
    yPos: 5.7,
    zPos: 3,
    hoverMesh: null,
    taskFn: "viewTemporaryTesting",
    cameraOptions: {
      xPos: -1.7,
      yPos: 5.25,
      zPos: 5,
      FOV: 0.7,
      targetMesh: null,
      rotationX: Tools.ToRadians(0), //9.96
      rotationY: Tools.ToRadians(-160),
      rotationZ: 0,
      rotationEnabled: false,
      navEnabled: false,
      zoomEnabled: false
    }
  },
  {
    id: "duct-wrap",
    courseId: 5,
    title: "Duct Wrap",
    desc: "",
    active: false,
    complete: false,
    xPos: -2.75,
    yPos: 5,
    zPos: 5,
    hoverMesh: null,
    taskFn: "viewDuctWrap",
    navOptions: { displayPopupAsNavPanel: true },
    cameraOptions: {
      xPos: -1,
      // xPos: -1.5,
      yPos: 5.5,
      zPos: 5,
      FOV: 0.9,
      targetMesh: null,
      rotationX: Tools.ToRadians(0),
      rotationY: Tools.ToRadians(-90),
      rotationZ: 0,
      rotationEnabled: false,
      navEnabled: false,
      zoomEnabled: false,
    },
  },
]