/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {Component, createRef, RefObject} from "react";
import "./UiTrainingWindow.css";
import Configs from "../../../Config/Configs";
import TrainingWindowState from "./UiTrainingWindow.state";
import BabylonManager from "../../../Managers/BabylonManager";
import TrainingManager from "../../../Managers/TrainingManager";
import UIManager from "../../../Managers/UIManager";
import { TrainingAnswer, TrainingPreCheck, TrainingPreCheckTypes, TrainingProduct, TrainingQuestion, TrainingQuestionTypes } from "../../../TrainingSession.props";
import UiPreCheckSituation from "./UiPreCheckSituation";
import UiPreCheckList from "./UiPreCheckList";
import UiQuestionTrueOrFalse from "./UiQuestionTrueOrFalse";
import UiQuestionMultipleChoice from "./UiQuestionMultipleChoice";
import ResidentialHouseStates from "../../../Babylon/Main/Rooms/ResidentialHouse/ResidentialHouseStates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Rooms from "../../../Babylon/Main/Rooms/Rooms";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next'
import ResidentialHouse from "../../../Babylon/Main/Rooms/ResidentialHouse/ResidentialHouse";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export enum TrainingWindowSections {
    PreChecks,
    Questions,
    Completion
}

export  class UiTrainingWindowBase extends Component<unknown, TrainingWindowState> {

    public trainingWindowRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
public t:any;
    constructor(props:any) {
        super(props);
        console.log("twprops",props)
        this.state = new TrainingWindowState();
       
        this.onOptionButtonClick = this.onOptionButtonClick.bind(this);
       // set up reference to main i18n lang function
        this.t= props.t;
        console.log("training window state",this.state)
    }

    componentDidUpdate(): void {
        console.log("training window state",this.state)
    }
    private checkMark: string = Configs.ContentUrlWithTrailingSlash + "Images/Icons/checkmark.png"; // TODO Replace with actual icon
   
    private resetTrainingState(): void {
        this.setState({
            TrainingStatus: TrainingManager.Session.status,
            CurrentSection: TrainingWindowSections.PreChecks,
            CompletedSection: false,
            ShowPreChecks: true,
            ShowQuestions: false,
            ShowCompleted: false,
            ShowResponse: false,
            ResponseType: "",
            ResponseText: "",
            NextButtonText: "Continue"
        });
    }

    public syncTrainingStatus(): void {
        console.log(TrainingManager.Session.status)
        this.setState({TrainingStatus: TrainingManager.Session.status});
    }

    private handleNextPreCheck(): void {
        TrainingManager.Session.goToPreCheck(this.state.TrainingStatus.preCheckIndex + 1);
        this.syncTrainingStatus();
        let currAction= BabylonManager.MainContext.getNextAction()
        console.log(currAction)
      if(currAction.actionType === "quiz"){
        let currId = currAction.id
        BabylonManager.MainContext.handleSectionComplete(currId,this.state.TrainingStatus.preCheckIndex )
    }
    }

    public handleNextQuestion(): void {
        TrainingManager.Session.goToQuestion(this.state.TrainingStatus.questionIndex + 1);
        this.updateQuestionProgressBar();
       this.syncTrainingStatus();
      
     
    }
    public handleRequestedQuestion(): void {
       // this.onNextButtonClick()
        TrainingManager.Session.goToQuestion(this.state.TrainingStatus.questionIndex + 1);
      
      this.setState({ShowQuestions:true,CompletedSection: false, ShowResponse: false});
     // this.handleNextQuestion();

        //  this.updateQuestionProgressBar();
      // this.syncTrainingStatus();
    }
   

    private handleCompletion(nextIndex: number): void {
      
        const nextProductIndex = nextIndex;
        const nextProduct = this.state.TrainingStatus.location.products[nextProductIndex];
       // setTimeout(()=>{i18next.changeLanguage("es")},0)
const txt = this.t("NavButtonText.LivingRoom")
      //  if(BabylonManager.MainContext.currentRoom === ""){}
console.log("quiz completed")

       // UIManager.UiOverlayRef.current?.showTransition();
       // UIManager.UiOverlayRef.current?.setState({ShowTrainingWindow:false,ShowHeader:true,ShowNavigationButton:true,NavigationButtonText:txt, ShowIntro:false,});
    //   BabylonManager.MainContext.handleComplete("quiz_1")
       let currAction= BabylonManager.MainContext.getNextAction()
       console.log(currAction)
     if(currAction.actionType === "quiz"){
       let currId = currAction.id
       BabylonManager.MainContext.handleComplete(currId )
   }
      //  BabylonManager.MainContext.savePath(true)
        // BabylonManager.MainContext.changeRoomState(nextProduct.state);
       // TrainingManager.Session.goToProduct(nextProductIndex);

        //Offset for UI transition fade
       // setTimeout(() => {
           // this.clearAnimations();
          //  this.resetTrainingState();
      //  }, 800);
    }

    private doesNextProductExist(): boolean {
        const nextProductIndex = this.state.TrainingStatus.productIndex + 1;
        const nextProduct = this.state.TrainingStatus.location.products[nextProductIndex];
        return (nextProduct !== undefined);
    }

    private doesPreviousProductExist(): boolean {
        const previousIndex = this.state.TrainingStatus.productIndex - 1;
        const prevProduct = this.state.TrainingStatus.location.products[previousIndex];
        return !!prevProduct;
    }

    private getQuestionProgressBarWidth(): string {
        const progressCurrent = this.state.TrainingStatus.questionIndex + 1;
        const progressMax = this.state.TrainingStatus.product.questions.length;
        return ((progressCurrent / progressMax) * 100) + "%";
    }

    private updateQuestionProgressBar(): void {
        const progressBar = document.getElementsByClassName("TrainingWindowQuestionProgressBar")[0] as HTMLElement;
        progressBar.style.width = this.getQuestionProgressBarWidth();
    }

    private isAnswerCorrect(answerIndex: number, isPreCheckList = false): boolean {

        if (this.state.CurrentSection === TrainingWindowSections.PreChecks) {
            if (isPreCheckList) {
                return true;
            }
            else {
                return this.state.TrainingStatus.preCheck.answers[answerIndex].value as boolean;
            }
        }
        else {
            return this.state.TrainingStatus.question.answers[answerIndex].value as boolean;
        }
    }

    protected onOptionButtonClick(button: HTMLButtonElement, answerIndex: number, isPreCheckList: boolean = false): void {

        if (this.state.CompletedSection) return;

        const isAnswerCorrect = this.isAnswerCorrect(answerIndex, isPreCheckList);
        const productIndex = this.state.TrainingStatus.productIndex;
        const questionIndex = this.state.TrainingStatus.questionIndex;
        const preCheckIndex = this.state.TrainingStatus.preCheckIndex;
        let responseText;
        let animationName;

        if (this.state.CurrentSection === TrainingWindowSections.PreChecks) {
            responseText = this.state.TrainingStatus.preCheck.answers[answerIndex].response;
            animationName = this.state.TrainingStatus.location.products[productIndex].preChecks[preCheckIndex].animation;
        }
        else {
            responseText = this.state.TrainingStatus.question.answers[answerIndex].response;
            animationName = this.state.TrainingStatus.location.products[productIndex].questions[questionIndex].animation;
        }

        if (isAnswerCorrect) {

            if (isPreCheckList) {
                this.setState({CompletedSection: true, ShowResponse: true, ResponseType: "correct", ResponseText: "Correct!"});
            }
            else {
                button.classList.add("correct");
                this.setState({CompletedSection: true, ShowResponse: true, ResponseType: "correct", ResponseText: responseText});

                // Play training animation
                if (animationName !== undefined && animationName.length > 0) {
                    BabylonManager.MainContext.changeAnimation(animationName);
                }
            }

            setTimeout(() =>
            {
                this.trainingWindowRef.current.scrollTo(0, this.trainingWindowRef.current.scrollHeight);
            }, 50);

        }
        else {
            this.setState({CompletedSection: false, ShowResponse: true, ResponseType: "wrong", ResponseText: responseText});
            button.classList.add("wrong");
        }

    }

    public onNextButtonClick(): void {
console.log(this.state.CompletedSection);
console.log(this.state.TrainingStatus.preCheckIndex);
console.log(this.state.TrainingStatus);
console.log(this.state.CurrentSection);
console.log(this.state.CompletedSection);
//console.log("trigger completion");
//this.handleCompletion(10)
        if (!this.state.CompletedSection) return;

        if (this.state.CurrentSection === TrainingWindowSections.PreChecks) {

            if (this.state.TrainingStatus.preCheckIndex + 1 === this.state.TrainingStatus.product.preChecks.length) {
                // Switch to questions
                this.setState({
                    ShowPreChecks: false,
                    ShowQuestions: true,
                    CurrentSection: TrainingWindowSections.Questions,
                    CompletedSection: false,
                    ShowResponse: true,
                    NextButtonText: "Next Question"
                });
            }
            else {
                this.setState({CompletedSection: false, ShowResponse: false});
                this.handleNextPreCheck();
            }

        }
        else if (this.state.CurrentSection === TrainingWindowSections.Questions) {

            if (this.state.TrainingStatus.questionIndex + 1 === this.state.TrainingStatus.product.questions.length) {
                // Switch to complete
                this.setState({ShowQuestions: false, ShowCompleted: true, CurrentSection: TrainingWindowSections.Completion, CompletedSection: false, ShowResponse: false});
            }
            else {
                this.setState({CompletedSection: false, ShowResponse: false});
                this.handleNextQuestion();
            }

        }

    }

    protected onNextProductButtonClick(): void {
        this.handleCompletion(this.state.TrainingStatus.productIndex + 1);
    }

    protected onPreviousProductButtonClick(): void {
        this.handleCompletion(this.state.TrainingStatus.productIndex - 1);
    }

    private closeTrainingWindow():void{

       // this.setState({isVisible:false})
        UIManager.UiOverlayRef.current.setState({HideOverlay:true}) 
    }

    protected onExitButtonClick(): void {
        UIManager.UiOverlayRef.current?.showTransition();
      //  BabylonManager.MainContext.changeRoomState(ResidentialHouseStates.Outside); // TODO: remove hardcoded house state, add a "products room" field for locations

        //Offset for UI transition fade
        setTimeout(() => {
            this.clearAnimations();
            this.handleCompletion(this.state.TrainingStatus.productIndex + 1)
        }, 800);
    }

    protected clearAnimations(): void {
        BabylonManager.MainContext.clearAllAnimations();
    }

    public render(): React.ReactElement {
console.log("prechecks:",this.state.ShowPreChecks)
console.log("show questions:",this.state.ShowQuestions)




        return (
            <div className="TrainingWindowContainer">
                <div className="TrainingWindow" ref={this.trainingWindowRef}>
                <div className="ClosePopup" onClick={()=>this.closeTrainingWindow()}><FontAwesomeIcon icon={faTimesCircle as IconProp} /></div>
              
                    {(this.state.ShowPreChecks || this.state.ShowQuestions) &&
                    <>
                        <div className="TrainingWindowHeader">
                           
                            
                            {(this.state.CurrentSection === TrainingWindowSections.PreChecks) &&
                            <>
                                <h2>{this.state.TrainingStatus.preCheck.title}</h2>
                                <h3>{this.state.TrainingStatus.preCheck.description ?? ""}</h3>
                            </>
                            }
                            {(this.state.CurrentSection === TrainingWindowSections.Questions) &&
                            <>
                                <h2>{this.state.TrainingStatus.product.title}</h2>
                                <h3>{this.state.TrainingStatus.product.description}</h3>
                            </>
                            }
                        </div>
                        <div className="TrainingWindowContent">
                            {this.state.ShowPreChecks &&
                            <div className="TrainingWindowPreCheck">
                                <h4 className="TrainingWindowPreCheckTitle">{this.state.TrainingStatus.preCheck.question}</h4>
                                {this.state.ShowResponse &&
                                <h5 className={`TrainingWindowPreCheckResponse ${this.state.ResponseType}`}>{this.state.ResponseText}</h5>
                                }
                                {(this.state.TrainingStatus.preCheck.type === TrainingPreCheckTypes.situation) &&
                                <UiPreCheckSituation
                                    key={this.state.TrainingStatus.productIndex + "_" + this.state.TrainingStatus.preCheckIndex}
                                    productIndex={this.state.TrainingStatus.productIndex}
                                    preCheckIndex={this.state.TrainingStatus.preCheckIndex}
                                    onAnswerClick={this.onOptionButtonClick}
                                    answers={this.state.TrainingStatus.preCheck.answers}
                                />
                                }
                                {(this.state.TrainingStatus.preCheck.type === TrainingPreCheckTypes.list) &&
                                <UiPreCheckList
                                    key={this.state.TrainingStatus.productIndex + "_" + this.state.TrainingStatus.preCheckIndex}
                                    productIndex={this.state.TrainingStatus.productIndex}
                                    preCheckIndex={this.state.TrainingStatus.preCheckIndex}
                                    onAnswerClick={this.onOptionButtonClick}
                                    answers={this.state.TrainingStatus.preCheck.answers}
                                />
                                }
                            </div>
                            }
                            {this.state.ShowQuestions &&
                            <div className="TrainingWindowQuestions">
                                <div className="TrainingWindowQuestionProgress">
                                    <h6>Question
                                        <span className="TrainingWindowQuestionProgressQuestionCurrent">{this.state.TrainingStatus.questionIndex + 1}</span>/<span
                                            className="TrainingWindowQuestionProgressQuestionMax">{this.state.TrainingStatus.product.questions.length}</span>
                                    </h6>
                                    <div className="TrainingWindowQuestionProgressBarWrap">
                                        <div className="TrainingWindowQuestionProgressBar" style={{width: this.getQuestionProgressBarWidth()}}></div>
                                    </div>
                                </div>
                                <h4 className="TrainingWindowQuestionTitle">{this.state.TrainingStatus.question.title}</h4>
                                {this.state.ShowResponse &&
                                <h5 className={`TrainingWindowQuestionResponse ${this.state.ResponseType}`}>{this.state.ResponseText}</h5>
                                }
                                {(this.state.TrainingStatus.question.type === TrainingQuestionTypes.trueOrFalse) &&
                                <UiQuestionTrueOrFalse
                                    key={this.state.TrainingStatus.productIndex + "_" + this.state.TrainingStatus.questionIndex}
                                    productIndex={this.state.TrainingStatus.productIndex}
                                    questionIndex={this.state.TrainingStatus.questionIndex}
                                    onAnswerClick={this.onOptionButtonClick}
                                    answers={this.state.TrainingStatus.question.answers}
                                />
                                }
                                {(this.state.TrainingStatus.question.type === TrainingQuestionTypes.multipleChoice) &&
                                <UiQuestionMultipleChoice
                                    key={this.state.TrainingStatus.productIndex + "_" + this.state.TrainingStatus.questionIndex}
                                    productIndex={this.state.TrainingStatus.productIndex}
                                    questionIndex={this.state.TrainingStatus.questionIndex}
                                    onAnswerClick={this.onOptionButtonClick}
                                    answers={this.state.TrainingStatus.question.answers}
                                />
                                }
                            </div>
                            }
                            <button onClick={() => this.onNextButtonClick()} className="TrainingWindowNextButton" disabled={!this.state.CompletedSection}>{this.state.NextButtonText}</button>
                        </div>
                    </>
                    }
                    {this.state.ShowCompleted &&
                    <div className="TrainingWindowCompleted">
                        <div className="TrainingWindowCompletedContent">
                            <img className="TrainingWindowCompletedIcon" src={this.checkMark}/>
                            <h2 className="TrainingWindowCompletedTitle">Congratulations!</h2>
                            <h3 className="TrainingWindowCompletedDescription">You’ve passed the Knauf Academy insulation installation training
                                for {this.state.TrainingStatus.product.title}.</h3>
                        </div>
                        {this.doesPreviousProductExist() &&
                            <button onClick={() => this.onPreviousProductButtonClick()} className="TrainingWindowCompleteNextButton">Previous Product</button>
                        }
                        {this.doesNextProductExist() &&
                            <button onClick={() => this.onNextProductButtonClick()} className="TrainingWindowCompleteNextButton">Next Product</button>
                        }
                        <button onClick={() => this.onExitButtonClick()} className="TrainingWindowCompleteExitButton">NEXT</button>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

const UiTrainingWindow = (UiTrainingWindowBase) 

export default  UiTrainingWindow              