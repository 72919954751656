
export default class PdfOverlayState {
   public isVisible: boolean=false;
   public posX: number=0;
   public posY: number=0;
public pdfUrl:string;
public closeAction:any;
public title:string;
public stepId:string;
public percentComplete:number=0;
public maxPage:number = 1;
public pageNum:number = 1;
public numPages:number = 1;
}
