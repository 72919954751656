

export default class UINotepadState {
  public completed:boolean;
  public isVisible:boolean = false;
  public outlineWidth:number;
  public  outlineHeight:number;
  public  line1Length:number;
  public line1Dir:string;
  public line1Label:number;
  public  line2Length:number;
  public line2Dir:string;
  public line2Label:number;
}
