import React, { Component, createRef, LegacyRef, RefObject } from "react";
import UIHeaderState from "./UiHeader.state";
import Configs from "../../Config/Configs";
import LocationManager, { Locations } from "../../Managers/LocationManager";
import IUiHeaderProps from "./UiHeader.props";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { faMinimize } from "@fortawesome/free-solid-svg-icons";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    Badge,
    Button,
    Card,
    Fade,
    MenuItem,
    Popover,
    Select,
    Slide,
    Tooltip,
} from "@material-ui/core";
import { TransitionProps } from "@mui/material/transitions";
import i18next from "i18next";
import i18n from "../../i18n";
import { withTranslation, WithTranslation } from "react-i18next";
import UIManager from "../../Managers/UIManager";
import BabylonManager from "../../Managers/BabylonManager";
import SVGIcon from "../../icons/SVGIcon";
import BabylonContextMain from "../../Babylon/Main/BabylonContextMain";
import BaseRoom from "../../Babylon/Main/Rooms/BaseRoom";
import Buildings from "../../Babylon/Main/Rooms/Buildings";
import IframeFunctions from "./iframe";

export default class UiHeader extends Component<
    IUiHeaderProps,
    UIHeaderState,
    unknown
> {
    public navLangRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);
        this.state = new UIHeaderState();
        this.handleLangChange = this.handleLangChange.bind(this);
        this.handleLangSelect = this.handleLangSelect.bind(this);
        this.handleLangClick = this.handleLangClick.bind(this);
        this.handleLangClose = this.handleLangClose.bind(this);
        this.handleEnterLang = this.handleEnterLang.bind(this);
        this.handleExitLang = this.handleExitLang.bind(this);
        this.getAudioIcon = this.getAudioIcon.bind(this);
        this.getSvgAudioIcon = this.getSvgAudioIcon.bind(this);
        this.handleAudioClick = this.handleAudioClick.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.setAnchor = this.setAnchor.bind(this);
    }

    componentDidMount(): void {
        if (sessionStorage.lang && sessionStorage.lang != "") {
            this.setState({ currentLang: sessionStorage.lang });
        }

        console.log(UIManager.UiOverlayRef.current.state.enableAudio);
        if (UIManager.UiOverlayRef.current.state.enableAudio === false) {
            this.setState({ audioEnabled: false });
        }
    }

    private logoMain =
        Configs.ContentUrlWithTrailingSlash + "Images/Logo-Color.png";

    protected onNavigateHome(): void {
        UIManager.LocationRouterRef.current.setState({
            ShowBabylonContainer: false,
        });
        LocationManager.NavigateTo(Locations.Home);
    }

    private handleLangChange(e: any): void {
        //let newLang = e.target.value;
        this.setState({ currentLang: e.target.value, langOpen: false });
        console.log("current lang", e.target.value);
        console.log(this.state);
        this.setState({ langOpen: false });

        i18next.changeLanguage(e.target.value, () => {
            UIManager.UiDataRef.current.resetData();
        });
        sessionStorage.lang = e.target.value;
        i18n.init({ lng: e.target.value });
        console.log(this.state);
    }

    private setAnchor(): HTMLElement {
        if (this.navLangRef) {
            return this.navLangRef as unknown as HTMLElement;
        } else {
            this.navLangRef = createRef<HTMLDivElement>();
            this.navLangRef as unknown as HTMLElement;
        }
    }

    private handleLangSelect(e: any): void {
        //let newLang = e.target.value;

        console.log(e);
    }

    private handleLangClick(e: any) {
        this.navLangRef = e.currentTarget;
        this.setState({ langOpen: !this.state.langOpen, langTTOpen: false });
    }

    private handleLangClose(e: any) {
        this.navLangRef = null;
    }

    private handleEnterLang(): void {
        this.setState({ langTTOpen: true });
    }

    private handleExitLang(): void {
        this.setState({ langTTOpen: false });
    }

    private getAudioIcon(): IconProp {
        if (this.state.audioEnabled === true) {
            return faVolumeHigh as IconProp;
        } else {
            return faVolumeMute as IconProp;
        }
    }
    public getSvgAudioIcon(): string {
        if (this.state.audioEnabled === true) {
            return "svgAudio";
        } else {
            return "svgMute";
        }
    }

    private getFullScreenIcon(): IconProp {
        if (this.state.fullScreen === true) {
            return faMinimize as IconProp;
        } else {
            return faExpand as IconProp;
        }
    }

    private getSvgFullScreenIcon(): string {
        if (this.state.fullScreen === true) {
            return "svgExitFullScreen";
        } else {
            return "svgFullScreen";
        }
    }

    private handleAudioClick(): void {
        if (this.state.audioEnabled === true) {
            UIManager.UiTTLRef.current.stopAudio();
        }
        this.setState({ audioEnabled: !this.state.audioEnabled });
    }

    private getBuildingIdx(bName: string): number {
        let buildingIdx: number;
        let buildingArr: string[] = Object.keys(Buildings).filter(
            (key: string, idx: number) => {
                return typeof Buildings[key as any] === "number";
            }
        );

        if (buildingArr.length > 0) {
            buildingIdx = buildingArr.indexOf(bName);
        } else {
            buildingIdx = 0;
        }

        return buildingIdx;
    }

    private returnToHub(): void {
        console.log("return to training hub");
        sessionStorage.courseIdx = "";
        sessionStorage.building = this.getBuildingIdx("Residential");
        UIManager.UiDataRef.current.resetData();
        BabylonManager.MainContext.onBabylonDispose();
        if (UIManager.UiToolStripRef.current) {
            UIManager.UiToolStripRef.current.removeDragIcon();
        }

        UIManager.UiOverlayRef.current.setState({
            ShowQuiz: false,
            ShowTaskList: false,
            ShowReturnButton: false,
            ShowSafetyTools: false,
            HideOverlay: false,
            ShowTrainingHub: true,
            ShowTrainingWindow: false,
        });
        UIManager.UiPopupRef.current.setState({ isVisible: false });
        if (
            UIManager.LocationRouterRef.current.state.ShowBabylonContainer ===
            true
        ) {
            UIManager.LocationRouterRef.current.setState({
                ShowBabylonContainer: false,
                LoadBabylonContainer: false,
                BabylonLoaded: false,
            });
        }
        UIManager.UiOverlayRef.current.setState({ CourseId: null });

        BabylonManager.MainContext.handleFn("removePointer");
    }

    private returnToEntrance(): void {
        UIManager.UiToolStripRef.current.removeDragIcon();
        BabylonManager.MainContext.navigateToEntrance();
    }

    private returnToModule(): void {
        UIManager.UiToolStripRef.current.removeDragIcon();
        BabylonManager.MainContext.navigateTo("livingRoom");
    }

    private toggleFullscreen(): void {
        this.setState({ fullScreen: !this.state.fullScreen });
        let elem = document.getElementsByTagName("body")[0]; //document.querySelector(iframeElemClass)[0];
        if (!document.fullscreenElement) {
            elem.requestFullscreen().catch((err) => {
                IframeFunctions.toggleFullScreen();
                // alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen();
        }
    }

    //     <div className={this.props.ShowNavigationButton ? "OverlayTopNavigationButton" : "OverlayTopNavigationButton WidthZero"} onClick={() => this.props.OnClickNavigate.call(this)}>
    //     <img className="OverlayTopNavigationButtonImg" src={Configs.ContentUrlWithTrailingSlash + "Ui/Icon-awesome-arrow-right.svg"}/>
    //     <div className="OverlayTopNavigationButtonText">{this.props.NavigationButtonText}</div>
    // </div>
    public render(): React.ReactElement {
        return (
            <div className="OverlayTopButtons">
                <div className="OverlayTopButton">
                    <img className="OverlayTopButtonImg" src={this.logoMain} />
                </div>
                {UIManager &&
                UIManager.UiOverlayRef &&
                UIManager.UiOverlayRef.current &&
                UIManager.UiOverlayRef.current.state.ShowReturnButton ? (
                    <>
                        <div className="btn-back">
                            {/* <Button className="btn-return primary-button" onClick={()=>{this.returnToHub()}}>
	<FontAwesomeIcon className="fa-icon" icon={faArrowLeft as IconProp}  />
	<div>Exit Training</div></Button> */}
                        </div>
                    </>
                ) : (
                    ""
                )}
                {/* {this.state.showToEntranceButton === true  ?
                    <>
                    <div className="btn-back">						
<Button className="btn-return primary-button" onClick={()=>{this.returnToEntrance()}}>
	<FontAwesomeIcon className="fa-icon" icon={faArrowLeft as IconProp}  />
	<div>RETURN TO ENTRANCE</div></Button>
                    </div>
                   
                    </>
                    :""} */}
                {/* {this.state.showToModuleButton === true ?
                    <>
                    <div className="btn-back">						
<Button className="btn-return primary-button" onClick={()=>{this.returnToModule()}}>
	<FontAwesomeIcon className="fa-icon" icon={faArrowLeft as IconProp}  />
	<div>Exit Activity</div></Button>
                    </div>
                   
                    </>
                    :""} */}

                <div className="nav-buttons-rt">
                    <div className="nav-items">
                        <Tooltip
                            id="langTooltip"
                            open={this.state.langTTOpen}
                            title={i18n.t("header.TTLang")}
                            arrow
                        >
                            <div
                                className="nav-item lang-wrapper"
                                ref={this.navLangRef}
                                onClick={this.handleLangClick}
                                onPointerEnter={this.handleEnterLang}
                                onPointerLeave={this.handleExitLang}
                            >
                                {/* <Badge className="lang-badge" badgeContent={this.state.currentLang.toUpperCase()} color="primary">
<SVGIcon iconName="svgLanguage"/>
</Badge>  */}

                                <Popover
                                    open={this.state.langOpen}
                                    anchorEl={this.setAnchor}
                                    // anchorPosition={{top:20,left:800}}
                                    onClose={this.handleLangClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: 130,
                                    }}
                                    TransitionComponent={Fade}
                                    // TransitionProps={{direction:"right"}}
                                >
                                    <Card variant="outlined">
                                        <Select
                                            id="simple-select"
                                            value={this.state.currentLang}
                                            onChange={this.handleLangChange}
                                            onClose={this.handleLangSelect}
                                        >
                                            <MenuItem value={"en"}>
                                                {i18n.t("header.Lang-English")}
                                            </MenuItem>
                                            <MenuItem value={"es"}>
                                                {i18n.t("header.Lang-Spanish")}
                                            </MenuItem>
                                        </Select>
                                    </Card>
                                </Popover>
                            </div>
                        </Tooltip>

                        <Tooltip title={i18n.t("header.TTAudio-a")} arrow>
                            <div
                                className="nav-item  audio-wrapper"
                                onClick={this.handleAudioClick}
                            >
                                {this.state.audioEnabled === true ? (
                                    <SVGIcon iconName="svgAudio" />
                                ) : (
                                    <SVGIcon iconName="svgMute" />
                                )}
                            </div>
                        </Tooltip>

                        <Tooltip title={i18n.t("header.TTFullscreen-a")} arrow>
                            <div
                                className="nav-item fullscreen-wrapper"
                                onClick={this.toggleFullscreen}
                            >
                                <SVGIcon
                                    iconName={this.getSvgFullScreenIcon()}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    }
}
