import IPanelProps from "../../UiComponents/TrainingComponents/UiImageTextOverlay.props";
import {ImgTextData} from '../../../Data/dataSetTypes'
export default class ImageTextOverlayState {
   public isVisible: boolean=true;
   public posX: number=0;
   public posY: number=0;
public currentPanel: ImgTextData;
public closeAction:any;
public title:string;
public percentComplete:number=0;
public stepId:string;
public panels: ImgTextData[];
public panelsId: string;
public panelIdx:number =0;
public standAlone:boolean = true;
}

// const p1:IPanelProps = {heading:"What is a Grade 1 Installation", imageFileName:"batt_install_tasks/correct_incorrect1.jpg", strHtml:"Grade 1 Insulation is installed according to the manufacturer instructions and/or industry standards. A Grade I installation requires that the insulation material uniformly fills each cavity side-to-side and top-to-bottom, without substantial gaps or voids around obstructions (such as blocking or bridging), and is split, installed, and/or fitted tightly around wiring and other services in the cavity. Follow this Grade 1 Installation Guide to learn more.",linkText:"Residential Insulation: Preparing for Your Installation", linkUrl:"https://www.knaufnorthamerica.com/en-ca/install"}
// const p2:IPanelProps = {heading:"Grade 1 Installation Details", imageFileName:"batt_install_tasks/correct_incorrect2.jpg", strHtml:"By reducing energy usage, fiberglass insulation allows your heating and cooling systems to operate with greater efficiency, which decreases energy use, may reduce your energy bills and limits the release of greenhouse gases into the atmosphere. Fiberglass insulation helps you — and all of us — do more while consuming fewer resources. ",linkText:"Advantages of EcoBatt Insulation", linkUrl:"https://www.knaufnorthamerica.com/en-ca/install"}


