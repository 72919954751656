export default class LocationRouterState {
    public ShowLogin: boolean = false;
    public ShowBabylonContainer: boolean = false;
    public LoadBabylonContainer: boolean = false;

    public BabylonLoaded: boolean = false;

    public Loading: boolean = false;
    public LoadingScreenOpacity: number;
}
