export default class UIHeaderState {
  public langOpen:boolean = false;
  public currentLang:string = "en";
  public langTTOpen:boolean = false;
  public audioEnabled:boolean = true;
  public fullScreen:boolean = false;
  public showReturnButton:boolean= false;
  public showToEntranceButton:boolean = false;
  public showToModuleButton:boolean = false;
}
