
export default class QuizState {
    public courseData:any[];
    public quizData:any[];
    public isVisible: boolean=true;
   public complete:boolean;
  public mode:string = "standard";
  public currQ:number = 0;
  public totalQ:number;
  public currQData:any;
  public slideIn:boolean = true;
  public currCorrect:boolean = false;
  public currIncorrect:boolean= false;
  public currComment:string ;
  public quizComplete:boolean = false;
  public score:number;
  
 }
 