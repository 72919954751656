export {};

declare global {

    /**
     * Adds some helpful methods to Math, these get added to the global Math object after this file is imported.
     */
    interface Math {
        clamp(x: number, min: number, max: number): number;
        lerp(a: number, b: number, u: number): number;
        smoothStep(a: number, b: number, u: number): number;
        smoothStepx2(a: number, b: number, u: number): number;
        smootherStep(a: number, b: number, u: number): number;
    }
}

Math.clamp = (x: number, min: number, max: number): number => {
    return Math.max(min, Math.min(x, max));
};


Math.lerp = (a: number, b: number, u: number): number => {
    u = Math.clamp(u, 0, 1);
    return a * (1 - u) + b * u;
};

Math.smoothStep = (a: number, b: number, u: number): number => {
    let x = Math.clamp((u - a) / (b - a), 0.0, 1.0);
    // Evaluate polynomial
    return x * x * (3 - 2 * x);
};

Math.smoothStepx2 = (a: number, b: number, u: number): number => {
    return Math.smoothStep(a, b, Math.smoothStep(a, b, u));
};

Math.smootherStep = (a: number, b: number, u: number): number => {
    let x = Math.clamp((u - a) / (b - a), 0.0, 1.0);
    // Evaluate polynomial
    return x * x * x * (x * (x * 6 - 15) + 10);
};