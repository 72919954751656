import UIManager from "./UIManager";
import AuthManager from "./AuthManager";

/**
 * Enum containing all URL paths
 */
export enum Locations {
    Home = "/",
    Courses="/courses",
    Login = '/Login/',
    Babylon = "/Babylon/"

}

/**
 * Location config object.
 * One of these should be added to LocationLookup for each URL path (see bottom of file)
 */
interface LocationConfiguration {
    path: Locations;

    /**
     * What the page title should be set to for a given path.
     */
    pageTitle: string;

    /**
     * This function is called when navigating to a specific path via URL.  This function should handle whatever UI setup is needed for this path assuming a fresh page load.
     */
    navigateTo: () => void;
}

let LocationLookup: { [index:string] : LocationConfiguration } = { };

/**
 * The location manager keeps track of and updates the current URL location, and handles loading the correct scene/ui/state when a user navigates to a specific URL.
 */
export default class LocationManager {

    public static get CurrentLocation(): Locations {
        return this._CurrentLocation;
    }

    private static _CurrentLocation: Locations = null;

    /**
     * Hard-loads a specific path, this is what's called when you navigate to an existing path via URL.
     *
     * @param location Location navigating to.
     * @param args Not yet implemented, but in theory can be used to pass in query parameters.
     */
    public static NavigateTo(location: Locations, args?: { [index: string]: string }): void {
        LocationLookup[location].navigateTo();
        LocationManager._CurrentLocation = location;
    }


    /**
     * Assumes another component already handled navigating to this path, and updates just the URL and page title accordingly.
     *
     * @param location Location navigated to.
     * @param args Not yet implemented, but in theory can be used to pass in query parameters.
     */
    public static NavigatedTo(location: Locations, args?: { [index: string]: string }): void {
        window.location.hash = "#" + LocationLookup[location].path;
        document.title = LocationLookup[location].pageTitle;
        LocationManager._CurrentLocation = location;
    }

    //TODO: Update to check if logged in
    public static LoadFromUrl(): void {
        let hash = window.location.hash;
        hash = hash.replace("#", "");
        if (hash.length === 0) {
setTimeout(()=>{

            UIManager.UiOverlayRef.current.setState({ShowIntro:true})
            UIManager.UiOverlayRef.current?.showIntro()
},0)
           
           // LocationManager.NavigateTo(Locations.Home);
            // if(AuthManager.IsAuthenticated) {
            //     LocationManager.NavigateTo(Locations.Home);
            // } else {
            //     LocationManager.NavigateTo(Locations.Login);
            // }
        }
        else {
            for (let key of Object.values(Locations)) {
                if (hash === key) {
                    LocationLookup[key].navigateTo();
                    return;
                }
            }

            if(AuthManager.IsAuthenticated) {
                LocationManager.NavigateTo(Locations.Home);
            } else {
                LocationManager.NavigateTo(Locations.Login);
            }
        }
    }
}

//TODO: Maybe move these out to their own file?

// #/
LocationLookup[Locations.Home.toString()] = {
    path: Locations.Home,
    pageTitle: "Home",
    navigateTo: (args?: { [index: string]: string }) => {
     //    UIManager.LocationRouterRef.current?.setState({ShowLogin: false, ShowBabylonContainer: false});
    //   UIManager.LocationRouterRef.current?.setState({ShowLogin: false, ShowBabylonContainer: true});
    //    UIManager.LocationRouterRef.current?.showLoadingScreen();
    //    UIManager.LocationRouterRef.current?.showIntroScreen();
   
         UIManager.UiOverlayRef.current.setState({ShowIntro:true,ShowTrainingHub: false,ShowTrainingWindow: false})
       // UIManager.UiOverlayRef.current?.showIntro()
    }
} as LocationConfiguration;

// #/Login/
LocationLookup[Locations.Login.toString()] = {
    path: Locations.Login,
    pageTitle: "Login",
    navigateTo: (args?: { [index: string]: string }) => {
        UIManager.LocationRouterRef.current?.setState({ShowLogin: true, ShowBabylonContainer: false});
    }
} as LocationConfiguration;

// #/Babylon/
LocationLookup[Locations.Babylon.toString()] = {
    path: Locations.Babylon,
    pageTitle: "Knauf Training Academy",
    navigateTo: (args?: { [index: string]: string }) => {
        UIManager.LocationRouterRef.current?.setState({ShowLogin: false, ShowBabylonContainer: true});
        UIManager.LocationRouterRef.current?.showLoadingScreen();
    }
} as LocationConfiguration;
