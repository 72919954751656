import React, {
    Component,
    createRef,
    LegacyRef,
    MouseEventHandler,
    RefObject,
} from "react";
import UIHotspotState from "./UiHotspot.state";
import HotspotProps from "./UiHotspot.props";
import Configs from "../../Config/Configs";
import "./UiHotspot.css";
import { Paper, Grow, Button, Popover, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import i18next, { t } from "i18next";
import SVGIcon from "../../icons/SVGIcon";
import i18n from "../../i18n";
//import { withTranslation,WithTranslation  } from 'react-i18next';

export default class UIHotSpotBase extends Component<
    HotspotProps,
    UIHotspotState
> {
    public hsRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
    private xPos: number;
    private yPos: number;
    private summary: string;
    private title: string;
    private popupImg: string;

    constructor(props: any) {
        super(props);
        this.state = new UIHotspotState();
        this.handleHSClick = this.handleHSClick.bind(this);
        this.closePopover = this.closePopover.bind(this);
        this.fillTemplateData = this.fillTemplateData.bind(this);
        //  this.getHSRef = this.getHSRef.bind(this);
    }
    componentDidMount(): void {
        // this.getHSRef()
        this.fillTemplateData();
    }

    componentDidUpdate(
        prevProps: Readonly<HotspotProps>,
        prevState: Readonly<UIHotspotState>,
        snapshot?: any
    ): void {
        this.fillTemplateData();
    }

    //     componentDidUpdate():void{
    //         console.log("update")
    // if(this.props.isSelected=== true){
    //     this.setState({selected:true})

    //    }

    //     }

    private fillTemplateData() {
        this.xPos = this.props.xPos;
        this.yPos = this.props.yPos;
        this.summary = this.props.summary;
        this.title = this.props.title.toUpperCase();
        this.popupImg = this.props.popupImg;
        this.hsRef.current.style.top = this.yPos + "%";
        this.hsRef.current.style.left = this.xPos + "%";
    }

    private handleHSClick(e: any): void {
        this.closePopover();
        if (e.currentTarget) {
            this.hsRef = e.currentTarget;
        } else {
            // this.hsRef= document.querySelector(".hotspot")
        }
        //if(this.props.isSelected=== true){
        this.setState({ selected: true });
        this.props.onClick();
        //}
    }

    private closePopover(): void {
        if (this.state.selected === true) {
            this.setState({ selected: false });
            this.props.onClick();
        }
    }

    // this.setState({selectedHotspot:this.state.hsData[hsIdx],hsSummaryOpen: !this.state.hsSummaryOpen})

    public render(): React.ReactElement {
        return (
            <>
                <div
                    id={this.props.id}
                    onClick={this.handleHSClick}
                    ref={this.hsRef as unknown as RefObject<HTMLDivElement>}
                    className={
                        this.state.selected ? "hotspot active" : "hotspot"
                    }
                >
                    <SVGIcon iconName="svgAdd" size="53px" />
                </div>
                <Popover
                    className="hs-popover-root"
                    open={this.state.selected}
                    //anchorEl={document.querySelectorAll(".hotspot")[this.props.index] as unknown as HTMLElement }
                    anchorEl={
                        document.querySelectorAll(
                            "#hs" + this.props.index
                        )[0] as unknown as HTMLElement
                    }
                    container={document.querySelector(".main-content")}
                    anchorOrigin={{
                        vertical: 25,
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                    //transformOrigin={{vertical:100,horizontal:500}}
                >
                    <div className="hs-wrapper">
                        <div className="hs-rt">
                            <div className="hs-summary">
                                <img
                                    className="hs-img"
                                    src={
                                        Configs.ContentUrlWithTrailingSlash +
                                        "Images/hotspots/" +
                                        this.popupImg
                                    }
                                />
                                <div className="hs-title">
                                    {i18n.t(this.title).toUpperCase()}
                                </div>

                                {this.summary}
                            </div>
                            <div className="btn-wrapper"></div>
                        </div>
                        <FontAwesomeIcon
                            className="fa-icon close"
                            onClick={this.closePopover}
                            icon={faClose as IconProp}
                        />
                    </div>
                </Popover>
            </>
        );
    }
}

//const UIHotSpot = withTranslation()(UIHotSpotBase) ;
//export default UIHotSpot;
