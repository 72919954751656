import React, {Component, createRef, RefObject} from "react";
import "../../BabylonContainer.css";
import "./UiQuiz.css";
import QuizProps from "./UiQuiz.props";
import QuizState from  "./UiQuiz.state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import {Button, Slide  } from "@material-ui/core";

import UIManager from "../../../Managers/UIManager";

import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

const minScore = 70; // min score require to receive cert of completion
let courseData:any=[
    {id:"battsInstall", index:0,quizTitle:"Eco-Batt Installation Quiz",
quizData:[
    {id:"0",topics:["ductwork", "wiring",],courseId:"battsInstall",completed:false, 
    q:"To achieve Grade 1, wall insulation must be enclosed with a rigid material on all six sides of the wall cavity - the front, back, sides, the top and bottom.",
	qType:"multi", answers:[
		{id:"1-1",text:"True",correct:true,color:"neutral" },
		{id:"1-2",text:"False",correct:false,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"Insulation must touch the stud on either side of the cavity, the exterior sheathing, the interior sheathing, the top plate, and the bottom plate.",
		commentCorrect:"", commentIncorrect:"" },
    {id:"1",topics:["ductwork", "wiring",],courseId:"battsInstall",completed:false, 
    q:"R-value is the most important factor in sound insulation.",
	qType:"multi", answers:[
		{id:"2-1",text:"True",correct:false,color:"neutral" },
		{id:"2-2",text:"False",correct:true,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"Thickness is.",
		commentCorrect:"", commentIncorrect:"" },
    {id:"2",topics:["ductwork", "wiring",],courseId:"battsInstall",completed:false, 
    q:"Insulating around an electrical wire isn't necessary so long as the insulation fills most of the space between the electrical wire and the exterior wall.",
	qType:"multi", answers:[
		{id:"3-1",text:"True",correct:false,color:"neutral" },
		{id:"3-2",text:"False",correct:true,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"Insulation must fill the entire cavity, around all obstructions",
		commentCorrect:"", commentIncorrect:"" },
    {id:"3",topics:["ductwork", "wiring",],courseId:"battsInstall",completed:false, 
    q:"Hearing protection is always necessary for a Batt installation.",
	qType:"multi", answers:[
		{id:"4-1",text:"True",correct:false,color:"neutral" },
		{id:"4-2",text:"False",correct:true,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"Only needed if job site requires it",
		commentCorrect:"", commentIncorrect:"" },
	]},
    {id:"looseFillAttic", index:1,quizTitle:"Loose Fill Attic Installation Quiz",
quizData:[
    {id:"0",topics:["ductwork", "attic",],courseId:"looseFillAttic",completed:false, 
    q:"Baffles help to direct airflow from soffit vents to prevent windwashing",
	qType:"multi", answers:[
		{id:"1-1",text:"True",correct:true,color:"neutral" },
		{id:"1-2",text:"False",correct:false,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"Baffles help keep insulation in place over the top plates. But it should also be far enough above the insulation to prevent it from touching the under side of the roof sheathing.",
		commentCorrect:"", commentIncorrect:"" },
    {id:"1",topics:["ductwork", "wiring",],courseId:"looseFillAttic",completed:false, 
    q:"Air sealing between the house and attic does little for the overall performance of the house",
	qType:"multi", answers:[
		{id:"2-1",text:"True",correct:false,color:"neutral" },
		{id:"2-2",text:"False",correct:true,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"It helps prevent conditioned air from leaving, unconditioned attic air from entering the house, can lower moisture risks like condensation and ice dams, and can prevent airborne contaminants from entering the house.",
		commentCorrect:"", commentIncorrect:"" },
    {id:"2",topics:["ductwork", "wiring",],courseId:"looseFillAttic",completed:false, 
    q:"When insulating an attic, it's best to start insulating next to the attic access and work your way out.",
	qType:"multi", answers:[
		{id:"3-1",text:"True",correct:false,color:"neutral" },
		{id:"3-2",text:"False",correct:true,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"Start at the furthest point and work your way back to the access.",
		commentCorrect:"", commentIncorrect:"" },
    {id:"3",topics:["ductwork", "wiring",],courseId:"looseFillAttic",completed:false, 
    q:"An attic ruler helps measure the square feet of an attic.",
	qType:"multi", answers:[
		{id:"4-1",text:"True",correct:false,color:"neutral" },
		{id:"4-2",text:"False",correct:true,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"It helps measure the thickness and installed R-value of the insulation.",
		commentCorrect:"", commentIncorrect:"" },
	]},
    {id:"netBlow", index:2,quizTitle:"Net & Blow Installation Quiz",
quizData:[
    {id:"0",topics:["ductwork", "attic",],courseId:"netBlow",completed:false, 
    q:"When hanging fabric, staples should be spaced 1” apart",
	qType:"multi", answers:[
		{id:"1-1",text:"True",correct:true,color:"neutral" },
		{id:"1-2",text:"False",correct:false,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"True - Spacing the staples 1” apart ensures that fiber will not “bridge” over the face of the stud when blown-in.",
		commentCorrect:"", commentIncorrect:"" },
    {id:"1",topics:["ductwork", "wiring",],courseId:"netBlow",completed:false, 
    q:"Performing a density check helps to ensure that your blowing machine is set-up correctly, and you are installing the correct R-value",
	qType:"multi", answers:[
		{id:"2-1",text:"True",correct:true,color:"neutral" },
		{id:"2-2",text:"False",correct:false,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"Density checks ensure that the proper R-value and coverage are achieved for the job. As the onsite manufacturer of the insulation system you, the installer, are responsible for making sure the correct product density is installed",
		commentCorrect:"", commentIncorrect:"" },
    {id:"2",topics:["ductwork", "wiring",],courseId:"netBlow",completed:false, 
    q:"Net and blow requires insulation that is different from what is installed in attics",
	qType:"multi", answers:[
		{id:"3-1",text:"True",correct:false,color:"neutral" },
		{id:"3-2",text:"False",correct:true,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"The same type of insulation can be used.",
		commentCorrect:"", commentIncorrect:"" },
    {id:"3",topics:["ductwork", "wiring",],courseId:"netBlow",completed:false, 
    q:"You should clean out the wall cavities of any debris before hanging fabric",
	qType:"multi", answers:[
		{id:"4-1",text:"True",correct:true,color:"neutral" },
		{id:"4-2",text:"False",correct:false,color:"neutral" },
		], complete:false,correct:null, resp:"", respVal:"", 
		comment:"Cleaning out debris and trash shows an extra step in professionalism. Once the cavities are insulated, those extra materials will never be removed from the wall.",
		commentCorrect:"", commentIncorrect:"" },
	]},
// quizData:[
//     {id:"0",topics:["plumbing","ductwork", "wiring",],courseId:"battsInstall",completed:false, 
//     q:"When wiring, plumbing or ductwork is present, the following should occur:",
//     qType:"multi", answers:[
//     {id:"0-1",text:"Split insulation in half and place behind and in front of objects",correct:true,color:"neutral" },
//     {id:"0-2",text:"Do not insulate these cavities",correct:false,color:"neutral" },
//     {id:"0-3",text:"Compress insulation into cavity",correct:false,color:"neutral" },
//     {id:"0-4",text:"Split insulation into thirds",correct:false,color:"neutral" },
//     ], complete:false,correct:null, resp:"", respVal:"", comment:"",commentCorrect:"", commentIncorrect:"" },
//     {id:"1",topics:["plumbing","ductwork", "wiring",],courseId:"battsInstall",completed:false, 
//     q:"A grade I install fills the entire cavity with insulation, and will fill around any/all obstructions. How many sides are there to a standard wall cavity?",qType:"multi", answers:[
//     {id:"1-1",text:"Split insulation in half and place behind and in front of objects",correct:true,color:"neutral" },
//     {id:"1-2",text:"Do not insulate these cavities",correct:false,color:"neutral" },
//     {id:"1-3",text:"Compress insulation into cavity",correct:false,color:"neutral" },
//     {id:"1-4",text:"Split insulation into thirds",correct:false,color:"neutral" },
//     ], complete:false,correct:null,resp:"", respVal:"", comment:"",commentCorrect:"", commentIncorrect:"" },
// ]},
    {id:"pipeInstall", index:3,quizTitle:"Pipe Installation Quiz",
      quizData:[
        {id:"1",topics:["plumbing","ductwork", "wiring",],courseId:"pipeInstall",completed:false, 
          q:'When "digging out", it is recommended that you do not use the same boning knife to cut into the material.',
          qType:"multi", answers:[
            {id:"1-1",text:"True",correct:true,color:"neutral" },
            {id:"1-2",text:"False",correct:false,color:"neutral" },
          ], complete:false,correct:null, resp:"", respVal:"", 
          comment:"Using a Retractable or Razor knife will prevent you from puncturing the vapor retarder.",commentCorrect:"", commentIncorrect:"" },
        {id:"2",topics:["plumbing","ductwork", "wiring",],courseId:"pipeInstall",completed:false, 
          q:"Maximum service temperature of Earthwool fiberglass pipe insulation is 600*F",
          qType:"multi", answers:[
            {id:"2-1",text:"True",correct:false,color:"neutral" },
            {id:"2-2",text:"False",correct:true,color:"neutral" },
          ], complete:false,correct:null, resp:"", respVal:"", 
          comment:"Maximum service temperature of Earthwool Pipe Insulation is 1000*F",commentCorrect:"", commentIncorrect:"" },
        {id:"3",topics:["plumbing","ductwork", "wiring",],courseId:"pipeInstall",completed:false, 
          q:"Earthwool fiberglass pipe insulation can be used on Copper, Iron, PVC, CPVC and stainless steel piping.",
          qType:"multi", answers:[
            {id:"3-1",text:"True",correct:true,color:"neutral" },
            {id:"3-2",text:"False",correct:false,color:"neutral" },
          ], complete:false,correct:null, resp:"", respVal:"", 
          comment:"Fiberglass pipe insulation can be used on all of the above.",commentCorrect:"", commentIncorrect:"" },
          
        {id:"4",topics:["plumbing","ductwork", "wiring",],courseId:"pipeInstall",completed:false, 
          q:"You are only required to insulate below ambient temperature systems.",
          qType:"multi", answers:[
            {id:"4-1",text:"True",correct:false,color:"neutral" },
            {id:"4-2",text:"False",correct:true,color:"neutral" },
          ], complete:false,correct:null, resp:"", respVal:"", 
          comment:"Generally speaking, both hot and cold piping systems require insulation for thermal, acoustical, or personnel protection purposes.",commentCorrect:"", commentIncorrect:"" },
    
        {id:"5",topics:["plumbing","ductwork", "wiring",],courseId:"pipeInstall",completed:false, 
          q:"Pipe supports should be in place prior to an insulation install.",
          qType:"multi", answers:[
            {id:"5-1",text:"True",correct:true,color:"neutral" },
            {id:"5-2",text:"False",correct:false,color:"neutral" },
          ], complete:false,correct:null, resp:"", respVal:"", 
          comment:"Pipe supports should be in place prior to beginning and install to ensure a continuous insulation system.",commentCorrect:"", commentIncorrect:"" },
   
        {id:"6",topics:["plumbing","ductwork", "wiring",],courseId:"pipeInstall",completed:false, 
          q:"When installing fiberglass pipe insulation, it is recommended that the self sealing lap be placed in a downward position to promote water shed practices",
          qType:"multi", answers:[
          {id:"6-1",text:"True",correct:true,color:"neutral" },
          {id:"6-2",text:"False",correct:false,color:"neutral" },
          ], complete:false,correct:null, resp:"", respVal:"", 
          comment:"Although laps can be positioned where needed, watersheding your longitudinal and circumferential laps is strongly recommended.",commentCorrect:"", commentIncorrect:"" },
        
        {id:"7",topics:["plumbing","ductwork", "wiring",],courseId:"pipeInstall",completed:false, 
          q:"It is ok to close all self sealing laps and taped butt joints by lightly running your hand over the tape",
          qType:"multi", answers:[
            {id:"7-1",text:"True",correct:false,color:"neutral" },
            {id:"7-2",text:"False",correct:true,color:"neutral" },
          ], complete:false,correct:null, resp:"", respVal:"", 
          comment:"Use of a squeegee is the only tool recommended to active the pressure sensitive Self sealing lap adhesive.",commentCorrect:"", commentIncorrect:"" },
        
    // {id:"8",topics:["plumbing","ductwork", "wiring",],courseId:"pipeInstall",completed:false, 
    // q:"It doesn't matter if pipe insulation is installed in the full specified thickness as long as the jacket can be sealed.",
    // qType:"multi", answers:[
    // {id:"8-1",text:"True",correct:false,color:"neutral" },
    // {id:"8-2",text:"False",correct:true,color:"neutral" },
    // ], complete:false,correct:null, resp:"", respVal:"", 
    // comment:"Insulation on above-ambient service pipe systems that is thin can cause discolored or burned jacket materials and cause poor system operation due to excessive heat loss. Insulation on below-ambient service systems that is thin can cause condensation to form on the outside surface of the insulation system, soaking jackets, damaging finishes and furnishings, and cause mold and/or mildew to form.",commentCorrect:"", commentIncorrect:"" },
   
    // {id:"9",topics:["plumbing","ductwork", "wiring",],courseId:"pipeInstall",completed:false, 
    // q:"All pipe insulation, regardless of material type, have the same insulating value per inch of thickness.",
    // qType:"multi", answers:[
    // {id:"9-1",text:"True",correct:false,color:"neutral" },
    // {id:"9-2",text:"False",correct:true,color:"neutral" },
    // ], complete:false,correct:null, resp:"", respVal:"", 
    // comment:"There are many materials used to make pipe insulation, and each one has a different insulating value per inch of thickness or thermal conductivity value. Some are very similar, some are very different in performance. Materials may not be able to be substituted in direct equal-thickness substitution.",commentCorrect:"", commentIncorrect:""},
    // ]
    ]},

    {id:"pipeAndTank", index:4, quizTitle:"Pipe and Tank Quiz",
      quizData:[
        {
          id:"1",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Pipe and Tank insulation can also be used on round and oval duct as long as it meets the minimum thermal requirements.",
          qType:"multi", answers:[
            {id:"1-1",text:"True",correct:true,color:"neutral" },
            {id:"1-2",text:"False",correct:false,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Pipe and tank insulation can be used for duct work on both commercial and industrial applications as long as it meets the minimum thermal requirements.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"2",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"When applying pipe and tank insulation, calculating the length of your pieces should be dealt with the same as duct wrap.",
          qType:"multi", answers:[
            {id:"2-1",text:"True",correct:true,color:"neutral" },
            {id:"2-2",text:"False",correct:false,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Follow the stretch out charts provided for the specific product being used to ensure a proper fit.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"3",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"It is recommended to cut pipe and tank on the floor as long as it is on an even surface.",
          qType:"multi", answers:[
            {id:"3-1",text:"True",correct:false,color:"neutral" },
            {id:"3-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Pipe and tank facings should always be protected to be free of dust and debris to prevent punctures.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"4",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Pipe and tank can only be used on extremely hot systems.",
          qType:"multi", answers:[
            {id:"4-1",text:"True",correct:false,color:"neutral" },
            {id:"4-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Pipe and tank can be used on both hot and cold as long as it meets the system specifications.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"5",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Pipe and tank should never be used to insulated flanges, valves, straighteners, etc.",
          qType:"multi", answers:[
            {id:"5-1",text:"True",correct:false,color:"neutral" },
            {id:"5-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Pipe and tank is extremely versatile and is recommended for use on the items listed below.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"6",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"When taping joints and seams, any pressure sensitive tape can be used.",
          qType:"multi", answers:[
            {id:"6-1",text:"True",correct:false,color:"neutral" },
            {id:"6-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Using the same pressure sensitive tape as the pipe and tank is always recommended.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"7",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"The use of bungie cords or cargo straps can help when only one person is available for installation.",
          qType:"multi", answers:[
            {id:"7-1",text:"True",correct:true,color:"neutral" },
            {id:"7-2",text:"False",correct:false,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Bungie cords and cargo straps hold materials in position during an install for a proper fit.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"8",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Rubbing down tape on joints and seams with the back of your knife is ok.",
          qType:"multi", answers:[
            {id:"8-1",text:"True",correct:false,color:"neutral" },
            {id:"8-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"No, although pressure sensitive tape may be activated with the back of a boning knife, a squeegee is strongly recommended.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"9",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"When installing pipe and tank insulation, there can only be a maximum number of seams on both vertical and horizontal.",
          qType:"multi", answers:[
            {id:"9-1",text:"True",correct:false,color:"neutral" },
            {id:"9-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"There is NO maximum number of joints and seams when installing pipe and tank as long as ever joint and seams is taped.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"10",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Pipe and tank insulation can be used on below ambient temperature system.",
          qType:"multi", answers:[
            {id:"10-1",text:"True",correct:true,color:"neutral" },
            {id:"10-2",text:"False",correct:false,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Pipe and tank insulation can be used on below ambient temperature systems as long as the material meets the specification requirements.",commentCorrect:"", commentIncorrect:"",
        },
      ]
    },

    {id:"ductWrap", index:5, quizTitle:"Duct Wrap Quiz",
      quizData:[
        {
          id:"1",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Duct wrap can be used for all duct systems on both commercial and industrial. ",
          qType:"multi", answers:[
            {id:"1-1",text:"True",correct:false,color:"neutral" },
            {id:"1-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Duct system service temperatures vary from commercial to industrial so it is extremely important to understand product specifications prior to install.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"2",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Cutting duct wrap on the floor is always ok as long as it is clean and level.",
          qType:"multi", answers:[
            {id:"2-1",text:"True",correct:false,color:"neutral"  },
            {id:"2-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"It is recommended to cut duct wrap on a table or against a wall to avoid punctures or tears on the vapor barrier.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"3",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"There can only be one longitudinal seam when installing duct wrap.",
          qType:"multi", answers:[
            {id:"3-1",text:"True",correct:false,color:"neutral" },
            {id:"3-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"There can be as many seams on both longitudinal and circumferential as necessary as long as they are sealed properly.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"4",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Staples and tape only need to be applied on the longitudinal seam when installing duct wrap.",
          qType:"multi", answers:[
            {id:"4-1",text:"True",correct:false,color:"neutral"},
            {id:"4-2",text:"False",correct:true,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"All seams need to be stapled and sealed unless otherwise specified or the area does not permit itself. Tape should be applied in those areas as a bare minimum.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"5",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Duct wrap should not be installed on ducts that are dusty.",
          qType:"multi", answers:[
            {id:"5-1",text:"True",correct:true,color:"neutral" },
            {id:"5-2",text:"False",correct:false,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"It is recommended to clean off any dust or debris to promote best install practices.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"6",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"Duct wrap can be used to temporarily insulate piping while hydro testing is in progress as long as it does not exceed 250 degrees Fahrenheit.",
          qType:"multi", answers:[
            {id:"6-1",text:"True",correct:true,color:"neutral"},
            {id:"6-2",text:"False",correct:false,color:"neutral" },
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Duct wrap is extremely useful in assisting with personal protection on jobsites.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"7",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"A vapor retarder tape must be used to seal all laps and seams.",
          qType:"multi", answers:[
            {id:"7-1",text:"True",correct:true,color:"neutral" },
            {id:"7-2",text:"False",correct:false,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"Not all pressure sensitive tapes are vapor retarders so it is imperative that the tapes match for performance and esthetics purposes.",commentCorrect:"", commentIncorrect:"",
        },
        {
          id:"8",topics:["ductwork"],courseId:"ductWrap",completed:false, 
          q:"The perimeter or circumference should always be measured prior to cutting insulation off the roll.",
          qType:"multi", answers:[
            {id:"8-1",text:"True",correct:true,color:"neutral"},
            {id:"8-2",text:"False",correct:false,color:"neutral"},
          ], 
          complete:false,correct:null, resp:"", respVal:"", 
          comment:"This is important to obtain in order to have pieces that are properly fitted.", commentCorrect:"", commentIncorrect:"",
        },
        // {
        //   id:"9",topics:["ductwork"],courseId:"ductWrap",completed:false, 
        //   q:"When installing duct wrap, laps can be fabricated on both longitudinal and circumferential as long as they are sealed tight.",
        //   qType:"multi", answers:[
        //     {id:"9-1",text:"True",correct:true,color:"neutral"},
        //     {id:"9-2",text:"False",correct:false,color:"neutral"},
        //   ], 
        //   complete:false,correct:null, resp:"", respVal:"", 
        //   comment:"Laps can be fabricated where necessary as long as they are sealed. Fabrications like this are common in construction.",commentCorrect:"", commentIncorrect:"",
        // },
        // {
        //   id:"10",topics:["ductwork"],courseId:"ductWrap",completed:false, 
        //   q:"Outward clinching staples should be used on all laps and joints that are fabricated.",
        //   qType:"multi", answers:[
        //     {id:"10-1",text:"True",correct:true,color:"neutral"},
        //     {id:"10-2",text:"False",correct:false,color:"neutral"},
        //   ], 
        //   complete:false,correct:null, resp:"", respVal:"", 
        //   comment:"While some areas would simply not lend itself to staples, pressure sensitive tape should be used.",commentCorrect:"", commentIncorrect:"",
        // },
     ]},
    ]


 class QuizBase extends Component<QuizProps, QuizState> {
   
  public QuizRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  private courseData:any;
  private qData:any;
private correctAnswers:number=0;

    constructor(props: any) {
        super(props);
       this.state = new QuizState();
       console.log(this.state)
      this.getNextQ = this.getNextQ.bind(this);
      // this.reviewQuiz= this.reviewQuiz.bind(this);
      this.retakeQuiz= this.retakeQuiz.bind(this);
      
    }

    componentDidMount(): void {

        console.log("quizcourse", this.props.courseId)
        console.log(courseData)
        
let cData = courseData.filter((item:any)=>{
    // console.log(item)
    // console.log('Props fopr quiz')
    // console.log(this.props.courseId);
    return item.index.toString() === this.props.courseId
})
// console.log('why  is true and false switched sometimes!?')
console.log(cData)

if(cData.length > 0){
//this.setState({courseData:cData})
this.courseData = cData[0];
this.qData = cData[0].quizData;

this.setState({quizData:cData[0].quizData,courseData:cData},()=>{ 
    console.log(this.state)
   // setTimeout(()=>{
        this.getNextQ()
      //  console.log(this.state)
   // },1000)
     } )


}
    }

  private getNextQ():any{
    console.log("getnext updatedstate",this.state)
    if(this.state.quizData && this.state.currQ < this.state.quizData.length ){
      let currQData = this.state.quizData[this.state.currQ]
      let question = currQData ;

      // let answers= this.shuffle(question.answers);
      let answers= question.answers;

      this.setState({currQData:currQData,currCorrect:false,currIncorrect:false,currComment:null})


      console.log("prenextqstate: ",this.state)
      console.log(this.state.currQ, this.state.quizData.length)
      if(this.state.currQ < this.state.quizData.length){
        let nextQ = this.state.currQ + 1
        this.setState({currQ:nextQ})
        console.log("nextq:", this.state.currQ, nextQ)
      }
    }else{
      let nextQ = this.state.currQ + 1
      this.setState({currQ:nextQ})
      console.log(this.correctAnswers)
      console.log(this.state.quizData.length)
      let total:number = this.state.quizData.length
      let score:number = this.correctAnswers/total *100
      this.setState({score:score})
          //console.log(score)
          console.log(this.state.score)
         // console.log(this.state.score.toFixed(2))
      }

      console.log("postnextqstate: ",this.state,this.state.quizData.length)
  }
 

  public evalAnswer(answer:any):void{

    let q = this.state.currQData;
    if(q.complete === false){
    let ans = q.answers.filter((item:any)=>{
        return item.id === answer.id;

    })
    console.log(answer)
    q.complete = true;
    if(answer.correct === true){
      this.setState({currCorrect:true,currIncorrect:false})
      //answer.color = "correct"
      this.correctAnswers = this.correctAnswers+1
      ans[0].color = "correct";
      q.correct = true;
    }    
    if(answer.correct === false){
        this.setState({currCorrect:false,currIncorrect:true})
        //answer.color = "incorrect"
        ans[0].color = "incorrect";
        q.correct = false
    }

    let newQData = this.state.quizData
    newQData[this.state.currQ -1]= q;

    console.log("newquizdata", newQData)
    this.setState({currQData:q, quizData:newQData, currComment:q.comment})  
}
  }

//   public shuffle(array:any[]):any[] {
//     let i = 0
//         , j = 0
//         , temp = null

//         for (i = array.length - 1; i > 0; i -= 1) {
//             j = Math.floor(Math.random() * (i + 1))
//             temp = array[i]
//             array[i] = array[j]
//             array[j] = temp
//         }
//         return array
// }
  
private retakeQuiz():void{
let qData = this.state.quizData.map((item)=>{
item.correct = null;
item.complete = false;
item.answers.map((ans:any)=>{
    ans.color="neutral"
    return ans
})
return item
})
console.log(this.correctAnswers);
this.correctAnswers = 0;
let currQData = qData[0]
this.setState({ complete:false,currQ : 1, currQData:currQData,mode:"standard", score:0, quizData:qData, currComment:"", currCorrect:null, currIncorrect:null});
//this.getNextQ();
    
}

// private reviewQuiz():void{

//     let currQData = this.state.quizData;
//     let reviewData= currQData.filter((item)=>{
//         return item.correct === false
//     })
// // enable review mode
//     // this.setState({quizData: reviewData, currQ : 0, mode:"review", });
// //this.getNextQ();
// }


 public  closeQuiz = ():void =>{
  let qData = this.state.quizData.map((item)=>{
    item.correct = null;
    item.complete = false;
    item.answers.map((ans:any)=>{
        ans.color="neutral"
        return ans
    })
    return item
    })
  this.correctAnswers = 0;
  let currQData = qData[0]
  this.setState({ complete:false,currQ : 1, currQData:currQData,mode:"standard", score:0, quizData:qData, currComment:"", currCorrect:null, currIncorrect:null});
    UIManager.UiOverlayRef.current.setState({ShowQuiz:false,HideOverlay:true})
 }
 
 private getStyleClass(q:any):string{
let styleName:string ="";
console.log("qdetails: ",q)
if(q.correct === true){
    styleName= "review-correct"
}else{

if(q.color === "neutral"){
    styleName = "review-neutral"
}else{
    styleName = "review-incorrect"
}


}

return styleName

 }


public render():React.ReactElement{
  console.log(this.state.score);

    let question;
    let answers;
	let quizTitle = UIManager.UiDataRef.current?.getTransData(this.props.courseName);
let disabled:boolean = false;
let btnHidden:boolean = false;
if(this.state.currQData){
     question = this.state.currQData ;
    console.log(question)
 answers= question.answers;

 if(question.complete === false ){
     disabled = true
}else{
    disabled = false
 }

 //create offset for items in review mode
 let offset:number = 0;
  // if(this.state.mode==="review"){offset = 1}
    if(this.state.currQ > this.state.quizData.length - offset ){
      btnHidden = true
    }else{
      btnHidden = false
  }
  console.log(btnHidden, this.state.currQ, this.state.quizData.length)
}


return <>
{this.state.quizData && this.state.quizData.length > 0 && this.state.currQData ?
<div className="quiz-wrapper">

    {/* quiz footer */}
    <div className="quiz-footer">
      {/* <div className="btn-close-quiz" onClick={this.closeQuiz}> <FontAwesomeIcon className="icon-close" icon={faClose as IconProp}></FontAwesomeIcon></div> */}

      <div className="progress" >
        { this.state.currQ < this.state.quizData.length + 1 && this.state.mode !== "review" ? 
          <>Question {this.state.currQ} of {this.state.quizData.length}</>
        : ""
        }
          {/* {this.state.mode === "standard" ?
            <QuizProgressDots total={this.state.quizData.length} currentIdx={this.state.currQ}  quizData={this.state.quizData} ></QuizProgressDots>
            :""
          } */}
      </div>
    </div>
  {/* END QUIZ FOOTER */}
<div className="quiz-content">
{ this.state.currQ < this.state.quizData.length + 1 && this.state.mode !== "review" ? 
<Slide>
  <>
{ this.state.currIncorrect === true ?
<div className="resp resp-incorrect">Oops! That's not the correct answer.</div>
:""}
{ this.state.currCorrect === true ?
<div className="resp resp-correct">Correct!</div>
:""}
{this.state.currComment && this.state.currComment !== "" ?
<div className="comment" >{this.state.currComment}</div>
:""
}
<div className="q-number">Question {this.state.currQ}:</div>
<div className="q-heading">{question.q}</div>
<div className={this.state.currQData.complete === true ? "ans-wrapper complete":"ans-wrapper incomplete"}>
    {this.state.mode === "standard" ? 
answers.map((item:any)=>{
    return <div className={" ans-item " + item.color} onClick={()=>{this.evalAnswer(item)}}  >{item.text}</div>
})
:""
}

</div>
</>

</Slide>
:"" }

{/* <div className="quiz-title">{this.courseData.quizTitle}</div> */}
{this.state.mode === "standard" ?
<>
{btnHidden=== true   ?
//standard mode completed quiz
<>
{this.state.score >= minScore ? 
<>
<div className="congrats">Congratulations, YOU'VE PASSED the quiz!</div>
<div className="score">
You've passed the {this.courseData.quizTitle} successfully with a score of: <span className="score-number">{Math.round(this.state.score)}%</span>
</div>
<div className="quiz-req">A certificate of completion will be available on your MyKnauf page.</div>
<div className="btn-next-wrapper quiz-btns">
<Button className="primary-button btn-exit" onClick={this.closeQuiz}>Exit the Quiz</Button>
{/* {Number(Math.round(this.state.score)) < 100 ?
  <Button className="primary-button btn-review" onClick={this.reviewQuiz}>Review Quiz</Button>
  :""
} */}
    {/* <Button className="primary-button btn-nextq">
    Start the Pipe &amp; Tank Course
    </Button> */}
    </div>

</>
:
<>

<div className="congrats">Sorry, you did not pass the {quizTitle} Training.</div>
<div>Please review the training material and re-take the quiz.</div>
<div className="score">
You've completed the {this.courseData.quizTitle}  with a score of: <span className="score-number">{Math.round(this.state.score)}%</span>
</div>
<div className="quiz-req">
You need a score of 70% or higher to receive a certificate of completion. 
</div>
<div className="failed-comment">Please review the training material and re-take the quiz.</div>
<div className="retake-course quiz-btns">
  <Button className="primary-button btn-retake" onClick={this.retakeQuiz}>Retake the Quiz</Button>
  <Button className="primary-button btn-exit" onClick={this.closeQuiz}>Exit the Quiz</Button>
</div>
</>
}
</>
:
// standard mode active quiz
<>



<Button className="primary-button btn-nextq" disabled={disabled} onClick={this.getNextQ} >NEXT <FontAwesomeIcon className="icon-complete" icon={faArrowRightLong as IconProp} /></Button>
</>
}
</>
:
// this code is driving me crazy 
// btnHidden === true &&  this.state.mode === "review" ?
// //review mode complete
// <>
// <div className="congrats">Congratulations, YOU'VE PASSED the quiz!</div>
// <div className="score">
// You've passed the {this.courseData.quizTitle} successfully with a score of: <span className="score-number">{Math.round(this.state.score)}%</span>
// </div>
// <div className="quiz-req">A certificate of completion will be available on your MyKnauf page.</div>
// <div className="btn-next-wrapper quiz-btns">
// {/* {Number(Math.round(this.state.score)) < 100 ?
//   <Button className="primary-button btn-review" onClick={this.reviewQuiz}>Review Quiz</Button>
//   :""
// } */}
// <Button className="primary-button btn-exit" onClick={this.closeQuiz}>Exit the Quiz</Button>


//     {/* <Button className="primary-button btn-nextq">
//     Start the Pipe &amp; Tank Course
//     </Button> */}
//     </div>

// </>
// :
// // review mode active
// <>

// <div className="q-heading">{question.q}</div>

// {this.state.currComment && this.state.currComment !== "" ?
// <div className="comment-review" >{this.state.currComment}</div>
// :""
{/* }

<div className={this.state.currQData.complete === true ? "ans-wrapper complete":"ans-wrapper incomplete"}>

{
answers.map((item:any)=>{
    return <div className={"ans-item " + this.getStyleClass(item)}   >{item.text}</div>
})

}
</div>
<Button className="primary-button btn-nextq" disabled={disabled} onClick={this.getNextQ} >NEXT <FontAwesomeIcon className="icon-complete" icon={faArrowRightLong as IconProp} /></Button>

</> */}

}




</div>
</div>
:""
}

</>
}
}

const Quiz = (QuizBase);

export default Quiz