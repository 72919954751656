
import { pathToFileURL } from "url";

export const assets: any = {
	config: {
		// Base URLs for assets
		localUrl:"localhost",
		localStorage:"assets/",
		baseDevUrl:"dev.knaufacademy.com",
		devStorage:"https://devknaufacademystorage.blob.core.windows.net/",
		baseStagingUrl:"staging.knaufacademy.com",
		stagingStorage:"https://stageknaufacademystorage.blob.core.windows.net/",
		baseProdUrl:"",
		prodStorage:"",
		baseModelsDir:"models/",
		baseImagesDir:"images/",
		baseMediaDir:"media/",
		baseMaterialsDir:"materials/",
		baseFontsDir:"fonts/",
		baseDocsDir:"documents/",
		currentEnv:null,
		currentUrl:null,
		currentBucket:null,
	},
	uiAssets: {
		"residentialHouseExterior": { id: "residentialHouseExterior", type: "image", file: "Res_Exterior_3_8bit.png" },
		"commercialExterior": { id: "commercialExterior", type: "image", file: "CI_Exterior2.png" },
		"pointerIcon": { id: "pointerIcon", type: "image", file: "PointerIcon.png" },
	},
	materialAssets: {
		looseFill: {
			"blowParticle": { id: "blowParticle", type: "material", file: "T_Blower_Insulation_D4.png" }
		},
		residential: {
			"skyboxImg": { id: "skyboxImg", type: "material", file: "immenstadter_horn.jpg" },
			"resEnvFile": { id: "resEnvFile", type: "material", file: "environment_powerplant.env"},
		}
	},
	courseAssets: {
		residentialBase: {
			"residentialHouse": { id: "residentialHouse", type: "model", file: "Knauf_Residential_20221202.glb", callBack:"handleResBaseLoaded" },
			//"residentialHouse": { id: "residentialHouse", type: "model", file: "Knauf_ResidentialHouse_MASTER_20221004.glb" },
			},
		commercialBase:{
			"commercialWareHouse": { id: "commercialWareHouse", type: "model", file: "Commercial_Env_v3.glb", callBack:"handleResBaseLoaded" },
		},
		battInstall:{
			"thermalImaging1":{id:"thermalImaging1", type:"image", file:"thermalvis.jpg"},
			"acousticAssemblies":{id:"acousticAssemblies", type:"file", file:"acoustical-assemblies-en-SA200.pdf"},
			"correctIncorrect1":{id:"correctIncorrect1", type:"image", file:"Batt_correctvincorrect3.jpg"},
			"correctIncorrect2":{id:"correctIncorrect2", type:"image", file:"Batt_correctvincorrect4.jpg"},
			"correctIncorrect3":{id:"correctIncorrect3", type:"image", file:"correct_incorrect1.jpg"},
			"correctIncorrect4":{id:"correctIncorrect4", type:"image", file:"correct_incorrect2.jpg"},
			"tooSmall":{id:"tooSmall", type:"image", file:"too-small.jpg"},
			"climateChart1":{id:"climateChart1", type:"image", file:"climate_chart1.jpg"},
			"zoneChart1":{id:"zoneChart1", type:"image", file:"zone_chart1.jpg"},
			"resBattOpening":{id:"resBattOpening", type:"model", file:"RES-ANIM-BattsPackageOpening.glb"},
			"resBattSplitting":{id:"resBattSplitting", type:"model", file:"RES-ANIM-BattsPanel_Split_Cut.glb", callBack:"handleBattSplittingLoaded"},
			"resKnife":{id:"resKnife", type:"model", file:"knife.glb", callBack:"handleKnifeLoaded"},
			"resTapeMeasure":{id:"resTapeMeasure", type:"model", file:"tapemeasure1a.glb",callBack:"handleTapeMeasureLoaded"},
			"resBattKnife":{id:"resBattKnife", type:"model", file:"BattsKnife3.glb",callBack:"handleBattKnifeLoaded"},
			"resBattsPkg":{id:"resBattsPkg", type:"model", file:"BattsPackage.glb",callBack:"handleR15PkgLoaded"},
		},
		looseFillAttic:{
		"resAtticLooseFill":{id:"resAtticLooseFill", type:"model", file:"RES-ANIM-AtticLooseFill_Accumulation_v2_1fps.glb"},
		"resAtticSingleCavity":{id:"resAtticSingleCavity", type:"model", file:"RES-ANIM-SingleCavityFill.glb", animationGroup:"RES-ANIM-SingleCavityFill"},
		//"resAtticHose":{id:"resAtticHose", type:"model", file:"RES-LooseFill_BlowerHose.glb", },
		"blowerHose":{id:"blowerHose", type:"model", file:"blowerHose.glb", callBack:"handleHoseLoaded"},
		"bafflesPanelImg2":{id:"bafflesPanelImg2", type:"image", file:"baffles2.jpg"},
		"blowerEcofill1":{id:"blowerEcofill1", type:"image", file:"BlowerMachine-EcoFill_01.png"},
		"blowerEcofill2":{id:"blowerEcofill2", type:"image", file:"BlowerMachine-EcoFill_02.png"},
		"rvalueRuler":{id:"rvalueRuler", type:"image", file:"atticRuler.jpg"},
		"densityTable":{id:"densityTable", type:"image", file:"DensityTable1.png"},
		"atticInstallGuide":{id:"atticInstallGuide", type:"file", file:"Knauf-Attic-Install-Guide.pdf"},
		
		},
		netBlow: {
			"resNetBlowCutHoles":{id:"resNetBlowCutHoles", type:"model", file:"RES-ANIM-CutHolesAndPrefill_v4.glb", animationGroup:"ANIM-CutHolesAndPrefill",callBack:"handleNetBlowCutHolesLoaded"},
			"resNetBlowStaples":{id:"resNetBlowStaples", type:"model", file:"RES-ANIM-Stapling.glb", animationGroup:"ANIM-Stapling"},
			"resNetBlowNetting":{id:"resNetBlowNetting", type:"model", file:"RES-ANIM-HangNetting.glb", animationGroup:"ANIM-HangNetting"},
			"resNetBlowFillCavity":{id:"resNetBlowFillCavity", type:"model", file:"RES-ANIM-NetAndBlow_CavityFill_v2.glb", animationGroup:"ANIM-NetAndBlow_CavityFill"},
			// "resNetBlowFillCavity":{id:"resNetBlowFillCavity", type:"model", file:"RES-ANIM-NetAndBlow_CavityFill.glb", animationGroup:"ANIM-NetAndBlow_CavityFill"},
			"densityTable":{id:"densityTable", type:"image", file:"densityTable1.jpg"},
			"prepBlower1":{id:"prepBlower1", type:"image", file:"prepBlower1.png"},
			"prepBlower2":{id:"prepBlower2", type:"image", file:"prepBlower2.png"},
			"wetWall":{id:"wetWall", type:"image", file:"Wetwall.jpg"},
			"densityCheck":{id:"densityCheck", type:"image", file:"density_check.jpg"},
			"densityTable2":{id:"densityTable", type:"image", file:"density_table.png"},
			"netBlowInstallGuide":{id:"netBlowInstallGuide", type:"file", file:"Net-Blow-Insulation-Training-Guide.pdf"},
			"boxCutter":{id:"boxCutter", type:"model", file:"BoxCutter.glb", callBack:"handleBoxCutterLoaded"},
			"blowerHose":{id:"blowerHose", type:"model", file:"blowerHose.glb", meshName:"blowerHoseM",  callBack:"handleHoseLoaded"},
		
		},
		pipeInsulation: {
			"bestP":{id:"bestP", type:"image", file:"cornerPipes.jpg"},
			"hotColdPipe":{id:"hotColdPipe", type:"image", file:"hot_cold_pipe.jpg"},
			"digOut":{id:"digOut", type:"image", file:"Dig_Out.png"},
			"pipeInsulationAnimation":{id:"pipeInsulationAnimation", type:"model", file:"PipeInsulation_Animation.glb", animationGroup:"C&I-ANIM-PipeInsulation_Install"},
			"pipeInsulationPackageOpening":{id:"pipeInsulationPackageOpening", type:"model", file:"PipeInsulation_PackageOpening_v4.glb", animationGroup:"ANIM-PipeInsulationPackageOpening"},
			"pipeInsulationTapeMeasure":{id:"pipeInsulationTapeMeasure", type:"model", file:"TapeMeasure.glb", callBack:"tapeMeasureLoaded"},	
		},
		pipeTank: {
			"tankFunFact":{id:"tankFunFact", type:"image", file:"tank_fun_fact.jpg"},
			"bungie":{id:"bungie", type:"image", file:"bungie.png"},
			"cuttingTip":{id:"cuttingTip", type:"image", file:"cutting_tip.png"},
			"pipeTankTapeMeasure":{id:"pipeTankTapeMeasure", type:"model", file:"TapeMeasure.glb", callBack:"tapeMeasureLoaded"},
			"pipe&TankPackageOpening":{id:"pipe&TankPackageOpening", type:"model", file:"Pipe&Tank_PackageOpening_v2.glb", animationGroup:"ANIM-Pipe&TankPackageOpening"},
			"pipeTankInstall":{id:"pipeTankInstall", type:"model", file:"Pipe_Tank_Install_v2.glb", animationGroup:"ANIM-Pipe&Tank_Install"},
		},
		ductWrap: {
			"kwikFlex":{id:"kwikFlex", type:"image", file:"Kwik_Flex_Installation.jpg"},
			"wrapforPP":{id:"wrapforPP", type:"image", file:"Temporary_Wrap_for_PP.jpg"},
			"ductWrapTapeMeasure":{id:"ductWrapTapeMeasure", type:"model", file:"TapeMeasure.glb", callBack:"tapeMeasureLoaded"},	
			"ductwrapApplicationAndStaple":{id:"ductwrapApplicationAndStaple", type:"model", file:"Ductwrap_ApplicationAndStaple_10.glb", animationGroup:"Anim-DuctwrapApplicationAndStaple"},
			"ductWrapPackageOpening":{id:"ductWrapPackageOpening", type:"model", file:"DuctWrap_PackageOpening_15.glb", animationGroup:"ANIM-DuctWrap_PackageOpening_01"},
			"ductWrapPackageOpening2":{id:"ductWrapPackageOpening2", type:"model", file:"DuctWrap_PackageOpening_2.glb", animationGroup:"ANIM-DuctWrap_PackageOpening_02"},
			"ductwrapTapeAndSqueegee":{id:"ductwrapTapeAndSqueegee", type:"model", file:"Ductwrap_TapeAndSqueegee.glb", animationGroup:"ANIM-Ductwrap_TapeAndSqueegee"},
		},
	},
	fns: {
		setCurrentUrl: (): void => {
			assets.config.currentUrl = location.protocol + '//' + location.host;
			let url = location.host;
			if (url.indexOf(assets.config.localUrl) > -1) {
				assets.config.currentBucket = assets.config.localStorage;
				assets.config.currentEnv = "local";
			}
			if (url.indexOf(assets.config.baseDevUrl) > -1) {
				assets.config.currentBucket = assets.config.devStorage
				assets.config.currentEnv = "dev";
			}
			if (url.indexOf(assets.config.baseStagingUrl) > -1) {
				assets.config.currentBucket = assets.config.stagingStorage
				assets.config.currentEnv = "staging";
			}
			assets.config.currentUrl = location.protocol + '//' + location.host;
		},
		getAssetPath: (id: string, cat: string, subCat?: string): string => {
			if (!assets.config.currentBucket) {
				assets.fns.setCurrentUrl()
			}
			let path: string;
			// console.log("getpath", id, cat, subCat)

			let obj;
			if (!subCat || subCat === null || subCat === "") {
				obj = assets[cat][id];
			} else {
				obj = assets[cat][subCat][id];
			}

			//console.log(obj)
			//let res = assets.config

			if (obj && obj.type === "image") {
				path = assets.config.currentBucket + assets.config.baseImagesDir + obj.file;
			}
			if (obj && obj.type === "model") {
				path = assets.config.currentBucket + assets.config.baseModelsDir + obj.file;
			}
			if (obj && obj.type === "material") {
				path = assets.config.currentBucket + assets.config.baseMaterialsDir + obj.file;
			}
			
			if (obj && obj.type === "file") {
				path = assets.config.currentBucket + assets.config.baseDocsDir + obj.file;
			}
			
			

			return path;
		},
		getSplitAssetPath: (id: string, cat: string, subCat?: string): string[] => {
			if (!assets.config.currentBucket) {
				assets.fns.setCurrentUrl()
			}
			let path: string[];
			// console.log("getsplitpath", id, cat, subCat)

			let obj;
			if (!subCat || subCat === null || subCat === "") {
				obj = assets[cat][id];
			} else {
				obj = assets[cat][subCat][id];
			}

			//console.log(obj)
			//let res = assets.config

			if (obj && obj.type === "image") {
				path = [assets.config.currentBucket + assets.config.baseImagesDir, obj.file];
			}
			if (obj && obj.type === "model") {
				path = [assets.config.currentBucket + assets.config.baseModelsDir, obj.file];
			}
			if (obj && obj.type === "material") {
				path = [assets.config.currentBucket + assets.config.baseMaterialsDir, obj.file];
			}

			return path;
		},
	}

}