import React, {Component} from "react";
import "./UiPreCheckList.css";
import Configs from "../../../Config/Configs";
import { TrainingAnswer } from "../../../TrainingSession.props";
import IUiTrainingAnswerProps from "./UiTrainingAnswer.props";

export default class UiPreCheckList extends Component<IUiTrainingAnswerProps> {

    constructor(props: any) {
        super(props);
    }

    private answerIndexsChecked: number[] = [];
    private answerKey = this.props.productIndex + "_" + this.props.preCheckIndex + "_";
    private iconFolder = Configs.ContentUrlWithTrailingSlash + "Images/Icons/Checklist/";
    private iconChecked = Configs.ContentUrlWithTrailingSlash + "Images/Icons/ChecklistSelectIcon.svg";

    protected onOptionClick(event: React.MouseEvent<HTMLButtonElement>): void {
        const option = event.target as HTMLButtonElement;
        const answerIndex = Number(option.getAttribute("data-answer-index"));

        if(!this.answerIndexsChecked.includes(answerIndex)) {
            this.answerIndexsChecked.push(answerIndex);
            option.classList.add("checked");
        }
           
        // Once all options have been checked 
        if(this.props.answers.length === this.answerIndexsChecked.length) {
            this.props.onAnswerClick(option, answerIndex, true);    
        }
    }

    public render(): React.ReactElement {
        return (
            <ul className="TrainingWindowPreCheckList">
                {this.props.answers.map((answer: TrainingAnswer, index: number) => {
                    return (
                        <li key={this.answerKey + "_" + index} className="PreCheckList">
                            <button onClick={(e) => this.onOptionClick(e)} data-answer-index={index}>
                                <img src={this.iconFolder + answer.icon} className="PreCheckListItemIcon"/>
                                <p className="PreCheckListItemTitle">{answer.title}</p>
                                <img src={this.iconChecked} className="PreCheckListIconChecked" />
                            </button>
                        </li>
                    );
                })}
            </ul>
        );
    }
}
