// HOTSPOTS
import {Tools, Vector3 } from "@babylonjs/core";
import { assets } from "../../assetMap";
import {ImgTextData, TaskListItem} from "../dataSetTypes"

let meshNames ={
	toolHub:"toolsetHub",
	tapeMeasureBlue:"tapeMeasureBlue",
}

let iconNames ={	
	tapeMeasure:"tapemeasure",	
	utilityKnife:"utilityknife"
}

const funFact1 : ImgTextData[] = [{
  heading: "Fun Fact",
  imageFileName:assets.fns.getAssetPath("tankFunFact", "courseAssets","pipeTank").toString(),
  strHtml: "Did you know that pipe and tank can be used to fabricate a variety of transitions such as valves, T's and 90 degree transitions. It can also be used for a variety of serviceable equipment; such as, pumps, various tanks, straighteners, diffusers, valves, flanges, round ducts etc.",
  arrLinks: [],
}]
const cuttingTips : ImgTextData[] = [{
  heading: "Cutting Tips",
  imageFileName:assets.fns.getAssetPath("cuttingTip", "courseAssets","pipeTank").toString(),
  strHtml: "<ul><li>It is always a good idea to avoid cutting pipe and tank with the vapor barrier facing in a downward position to avoid punctures.</li><li>Maintaining a sharp knife will always make it easier when cutting your materials because we all know that a dull knife is a dangerous one.</li><li>Using a T-Square will help ensure you have a straight cut when cutting for different transitions at different angles.</li></ul> ",
  arrLinks: [],
}]
const installationTips : ImgTextData[] = [{
  heading: "Installation Tips",
  imageFileName:assets.fns.getAssetPath("bungie", "courseAssets","pipeTank").toString(),
  strHtml: "<ul><li>Use bungee cords if you are installing by yourself.</li><li>Ensure that the pipe and tank insulation rolls out on a clean flat surface; such as a table or wall. This prevents debris from getting in the way of adhesive material applied to the ASJ.</li><li>Keeping a clean squeegee is the best way to leave a smooth finish when installing ASJ tape on joints and seams.</li></ul>",
  arrLinks: [],
}]

export let hotspotsPipeAndTank = [
  {
    id: "funFact",
    parentId: "fun-fact",
    task: "viewFunFact",
    priority: 0,
    inst: "",
    type: "imagetext",
    fnId: "",
    params: {
      panelData: funFact1,
    },
    active: false,
    complete: false,
  },
  {
    id: "cuttingTips",
    parentId: "cutting-tips",
    task: "viewCuttingTips",
    priority: 0,
    inst: "",
    type: "imagetext",
    fnId: "",
    params: {
      panelData: cuttingTips,
    },
    active: false,
    complete: false,
  },
  {
    id: "installationTips",
    parentId: "installation-tips",
    task: "viewInstallationTips",
    priority: 0,
    inst: "",
    type: "imagetext",
    fnId: "",
    params: {
      panelData: installationTips,
    },
    active: false,
    complete: false,
  }
]

// TOOLS
export let tankMeasureTools = {
	parentMeshName:meshNames.toolHub,
  position: new Vector3(0.535, 1.5, -14),
  rotation: new Vector3(0,0,0),
	onFloor:true,
	toolItems:[
			{name: meshNames.tapeMeasureBlue, hasHitbox:true,iconName:iconNames.tapeMeasure, isCorrect:false,position: new Vector3(0,.01,0), scaling: new Vector3(.75,.75,.75),rotation: new Vector3(Tools.ToRadians(0),Tools.ToRadians(0),Tools.ToRadians(0))}
	]
}


// INTERACTIVE
export let interactivePipeAndTank = [
{
  id: "step-1-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 1,
  inst: "",
  type: "popup",
  fnId: "",
  params: {
    text: "Welcome to the Pipe and Tank Installation Training.<br/><br/>Here you will learn all of the steps required for installing the Earthwool® Pipe and Tank Insulation and ensuring correct installation.",
    title: "PIPE AND TANK INTERACTIVE INSTALLATION",
    xPos: 150,
    yPos: 175,
    btnText: "Start Training",
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
},
{
  id: "step-2-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 2,
  inst: "",
  type: "checklist",
  fnId: "",
  params: {
    text: "Before installation, make sure you have completed the jobsite preparation.",
    title: "PREPARE THE JOB SITE",
    checklist: [
      'Have a boning or utility knife handy to cut materials. ', 
      'Have a table to cut on. It saves your back and protects your materials.',
      'Bring a T-Square to help ensure you have a straight cut when cutting at different angles.',
      'Fabricate product on clean, flat surfaces; such as a table or wall.',
      'If you are installing by yourself, bring along some bungee cords to help you hold the insulation together while you complete the steps.' 
    ],
    xPos: 150,
    yPos: 175,
    btnText: "Next",
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
},
{
  id: "step-3-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 3,
  inst: "",
  type: "popup",
  fnId: "",
  params: {
    text: "Identify the diameter of the vessel the piping material will be applied to.",
    title: "MEASURE DIAMETER",
    xPos: 150,
    yPos: 175,
    btnText: "Next",
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
},


{
  id: "step-4-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 4,
  inst: "",
  type: "simple-measure",
  fnId: "",
  params: {
    // autoAdvance: false,
    comment: "Select and drag the measuring tape to the right edge of the vessel to measure the diameter.",
    commentTitle: "MEASURE DIAMETER",
    retainPopup: true,
    tools: tankMeasureTools,
    measureAxis:"x",
    actualDrawAxis:"x",
    dragAxis:"x",
    measureTargetPoint: new Vector3(10.5, 10.10, -6),
    xPos: 150,
    yPos: 175,
    btnText: "Next",
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
},
{
  id: "step-5-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 5,
  inst: "",
  type: "popup",
  fnId: "",

  params: {
      xPos: 150,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      text: "You have measured <span id='measure-in-step'>measurementVar inches.</span> Click next to continue",
      title: "MEASURE VESSEL DIAMETER",
  },
  active: false,
  complete: false,
},
{
  id: "step-6-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 6,
  inst: "",
  type: "clicked-animation",
  fnId: "",

  params: {
      // autoAdvance: false,
      // Circumference
      comment: 'Total diameter after insulation multiplied by π (pi) will equal the necessary circumference.<br/><br /><span id="link-in-step">To ensure that you are using the correct thickness, refer to this <a target="_blank" href="https://res.cloudinary.com/knauf-insulation/image/upload/v1578611734/knauf%20insulation/pipe%20and%20tank/literature/knauf-pipe-and-tank-data-sheet.pdf">Minimum Pipe Thickness Chart.</a></span>',
      commentTitle: "IDENTIFY CORRECT \nPIPE COVERING THICKNESS",
      xPos: 150,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      autoAdvance: false,
      destroyOnInit: false,
      animation: "ANIM-Pipe&TankPackageOpening",
      animId: "ANIM-Pipe&TankPackageOpening",
      startAnim: 0,
      endAnim: 0.65,
      correctToolName: "ANIM-Pipe&TankPackageOpening",
      targetName: "ANIM-Pipe&TankPackageOpening",
      zoomOptions: {
        name: "zoomTankPackageStart",
        pos: new Vector3(-0.55, 1.5, -11.25),
        rotation: new Vector3(
          Tools.ToRadians(28),
          Tools.ToRadians(-190),
          Tools.ToRadians(0)
        ),
        fov: .8,
      },
  },
  active: false,
  complete: false,
},
{
  id: "step-7-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 7,
  inst: "",
  type: "clicked-animation",
  fnId: "",

  params: {
      // autoAdvance: false,
      comment: 'The Earthwool® Pipe and Tank Insulation packaging is designed to be ripped open. Select the hands to open the package. <span id="link-in-step">Note that the material should never be placed on the ground like batting is and if it does, it should be facing upwards to protect the vapor barrier.<br /><br />*Boning knives can also be used to open boxes, however be careful not to cut the materials inside.</span>',
      commentTitle: "Open Insulation packaging",
      xPos: 150,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      autoAdvance: false,
      destroyOnInit: false,
      animation: "ANIM-Pipe&TankPackageOpening",
      animId: "ANIM-Pipe&TankPackageOpening",
      startAnim: 1,
      endAnim: 5.2,
      correctToolName: "ANIM-Pipe&TankPackageOpening",
      targetName: "ANIM-Pipe&TankPackageOpening",
      zoomOptions: {
        name: "zoomTankPackage2",
        pos: new Vector3(-0.55, 1.5, -11),
        rotation: new Vector3(
          Tools.ToRadians(0),
          Tools.ToRadians(-190),
          Tools.ToRadians(0)
        ),
        fov: 1,
    },
  },
  active: false,
  complete: false,
},
{
  id: "step-8-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 8,
  inst: "",
  type: "popup",
  fnId: "",

  params: {
      xPos: 150,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      text: "Open the Earthwool® Pipe and Tank Insulation package and pull out the pipe and tank roll. <span id='link-in-step'>Rolls of pipe and tank come in lengths of 12 to 48 feet, and a width of 36 inches.<span>",
      title: "Open Insulation packaging",
      zoomOptions: {
        name: "zoomTankPackage2",
        pos: new Vector3(-0.55, 1.5, -11),
        rotation: new Vector3(
          Tools.ToRadians(0),
          Tools.ToRadians(-190),
          Tools.ToRadians(0)
        ),
        fov: 1,
    },
  },
  active: false,
  complete: false,
},
{
  id: "step-9-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 9,
  inst: "",
  type: "clicked-animation",
  fnId: "",

  params: {
      xPos: 150,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      comment: 'Refer to the Stretch-Out Chart to find the appropriate length to cut for the specific pipe size. Be sure to add an additional 2" (51 mm) to 4" (102 mm) for your staple flap.<br/><br/>Press on the pipe and tank piece to apply it on the vessel',
      commentTitle: "APPLY PIPE AND TANK ON VESSEL",
      autoAdvance: false,
      destroyOnInit: false,
      animation: "ANIM-Pipe&Tank_Install",
      animId: "ANIM-Pipe&Tank_Install",
      startAnim: 0.84,
      endAnim: 10.52,
      correctToolName: "ANIM-Pipe&Tank_Install",
      targetName: "Pipe_Tank_InsulationPiece_01",
  },
  active: false,
  complete: false,
},
{
  id: "step-10-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 10,
  inst: "",
  type: "clicked-animation",
  fnId: "",

  params: {
    xPos: 150,
    yPos: 175,
    btnText: "Next",
    navOptions: { displayPopupAsNavPanel: false },
    comment: "Select the appropriate tape strip to seal the first and second piece of pipe and tank around the circumferential seam.<span id='link-in-step'>ASJ tape (all-service jacketing tape) is a pressure sensitive tape commonly used for sealing cold and dual temperature duct and pipe systems where the insulation itself uses ASJ facing, typically duct board and pipe insulation.</span>",
    commentTitle: "Apply Tape",
    autoAdvance: false,
    destroyOnInit: false,
    animation: "ANIM-Pipe&Tank_Install",
    animId: "ANIM-Pipe&Tank_Install",
    startAnim: 11.206,
    endAnim: 14.178,
    correctToolName: "ANIM-Pipe&Tank_Install",
    targetName: "SM_Circum_Tape_01",
  },
  active: false,
  complete: false,
},
{
  id: "step-11-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 11,
  inst: "",
  type: "clicked-animation",
  fnId: "",

  params: {
      xPos: 150,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      comment: "Select the squeegee to rub down the circumferential seam.",
      commentTitle: "RUB DOWN CIRCUMFERENTIAL SEAM",
      autoAdvance: false,
      destroyOnInit: false,
      animation: "ANIM-Pipe&Tank_Install",
      animId: "ANIM-Pipe&Tank_Install",
      startAnim: 14.637,
      endAnim: 20.82,
      correctToolName: "ANIM-Pipe&Tank_Install",
      targetName: "PandT_Squeegee",
      
      
  },
  active: false,
  complete: false,
},
{
  id: "step-12-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 12,
  inst: "",
  type: "clicked-animation",
  fnId: "",

  params: {
    xPos: 150,
    yPos: 175,
    btnText: "Next",
    navOptions: { displayPopupAsNavPanel: false },
    comment: " Select the tape strip to apply the lap tape on the longitudinal seam.<br/><br />Rub down the seam with a squeegee.",
    commentTitle: "RUB DOWN LONGITUDINAL SEAM",
    autoAdvance: false,
    destroyOnInit: false,
    animation: "ANIM-Pipe&Tank_Install",
    animId: "ANIM-Pipe&Tank_Install",
    startAnim: 21.726,
    endAnim: 37.202,
    correctToolName: "ANIM-Pipe&Tank_Install",
    targetName: "SM_Tape_A",
    zoomOptions: {
      name: "zoomTankSide",
      pos: new Vector3(-1.6, 1.5, -10.5),
      rotation: new Vector3(
        Tools.ToRadians(0),
        Tools.ToRadians(-200),
        Tools.ToRadians(0)
      ),
      fov: 0.9,
    },
  
  },
  active: false,
  complete: false,
},
{
  id: "step-13-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 13,
  inst: "",
  type: "clicked-animation",
  fnId: "",

  params: {
      xPos: 150,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      comment: "Select the head gore pieces to assemble and install onto vessel.",
      commentTitle: "ASSEMBLE HEAD GORE PIECES",
      autoAdvance: false,
      destroyOnInit: false,
      animation: "ANIM-Pipe&Tank_Install",
      animId: "ANIM-Pipe&Tank_Install",
      startAnim: 42.919,
      endAnim: 48.18,
      correctToolName: "ANIM-Pipe&Tank_Install",
      targetName: "GorePiece_18_primitive0",
      zoomOptions: {
        name: "zoomTankTop",
        pos: new Vector3(-0.4, 2.5, -12.2),
        rotation: new Vector3(
          Tools.ToRadians(0),
          Tools.ToRadians(-190),
          Tools.ToRadians(0)
        ),
        fov: 0.9,
    },
  },
  active: false,
  complete: false,
},
{
  id: "step-14-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 14,
  inst: "",
  type: "clicked-animation",
  fnId: "",

  params: {
      xPos: 150,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      comment: "Select the tape strip to apply the lap tape to each pie-shaped seam.<br/><br/>Rub down seams with a squeegee.",
      commentTitle: "APPLY TAPE to seams",
      autoAdvance: false,
      destroyOnInit: false,
      animation: "ANIM-Pipe&Tank_Install",
      animId: "ANIM-Pipe&Tank_Install",
      startAnim: 48.24,
      endAnim: 56.001,//56.17,
      correctToolName: "ANIM-Pipe&Tank_Install",
      targetName: "GoreTape_01",
      zoomOptions: {
        name: "zoomTankTop",
        pos: new Vector3(-0.4, 2.5, -12.2),
        rotation: new Vector3(
          Tools.ToRadians(0),
          Tools.ToRadians(-190),
          Tools.ToRadians(0)
        ),
        fov: 0.9,
    },
  },
  active: false,
  complete: false,
},
{
  id: "step-15-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 15,
  inst: "",
  type: "clicked-animation",
  fnId: "",

  params: {
      xPos: 150,
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
      comment: "Select the tape strip to apply the lap tape to the gore seam.<br /><br />Rub down the seam with a squeegee.",
      commentTitle: "APPLY TAPE to gore seam",
      autoAdvance: false,
      destroyOnInit: false,
      animation: "ANIM-Pipe&Tank_Install",
      animId: "ANIM-Pipe&Tank_Install",
      startAnim: 61.673,
      endAnim: 76.233,
      correctToolName: "ANIM-Pipe&Tank_Install",
      targetName: "Pipe_Tank_InsulationPiece_02_primitive1",
      zoomOptions: {
        name: "zoomTankTop",
        pos: new Vector3(-0.4, 2.5, -12),
        rotation: new Vector3(
          Tools.ToRadians(0),
          Tools.ToRadians(-190),
          Tools.ToRadians(0)
        ),
        fov: 0.9,
    },
  },
  active: false,
  complete: false,
},
{
  id: "step-16-pipe-and-tank",
  parentId: "pipe-and-tank",
  task: "viewPipeAndTank",
  priority: 16,
  inst: "",
  type: "popup",
  fnId: "",

  params: {
      // autoAdvance: false,
      text: '<b>You have successfully completed the 3D training. Complete the other hotspots to unlock the quiz.</b>',
      title: "PIPE AND TANK INTERACTIVE INSTALLATION",
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "EXIT HOTSPOT",
      navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
}]

export let tasksPipeAndTank:TaskListItem[] = [
  {
    id: "fun-fact",
    courseId: 4,
    title: "Fun Fact",
    desc: '',
    active: false,
    complete: false,
    xPos: 2,
    yPos: 1.5,
    zPos: -11.4,
    hoverMesh: null,
    taskFn: "viewFunFact",
    cameraOptions: {
      xPos: -0.5,
      yPos: 1.5,
      zPos: -11.5,
      FOV: 0.9,
      targetMesh: null,
      rotationX: Tools.ToRadians(0),
      rotationY: Tools.ToRadians(-270),
      rotationZ: 0,
      rotationEnabled: false,
      navEnabled: false,
      zoomEnabled: false
    }
  },
  
  {
    id: "cutting-tips",
    courseId: 4,
    title: "Cutting Tips",
    desc: "",
    active: false,
    complete: false,
    xPos: 1.25,
    yPos: 1.5,
    zPos: -14,
    hoverMesh: null,
    taskFn: "viewCuttingTips",
    cameraOptions: {
      xPos: -0.5,
      yPos: 1.5,
      zPos: -11.5,
      FOV: 0.9,
      targetMesh: null,
      rotationX: Tools.ToRadians(0),
      rotationY: Tools.ToRadians(-140),
      rotationZ: 0,
      rotationEnabled: false,
      navEnabled: false,
      zoomEnabled: false
    }
  },
  
  {
    id: "installation-tips",
    courseId: 4,
    title: "Installation Tips",
    desc: "",
    active: false,
    complete: false,
    xPos: -0.8,
    yPos: 1.5,
    zPos: -14,
    hoverMesh: null,
    taskFn: "viewInstallationTips",
    cameraOptions: {
      xPos: -0.5,
      yPos: 1.5,
      zPos: -11.5,
      FOV: 0.9,
      targetMesh: null,
      rotationX: Tools.ToRadians(0),
      rotationY: Tools.ToRadians(-180),
      rotationZ: 0,
      rotationEnabled: false,
      navEnabled: false,
      zoomEnabled: false
    }
  },
  
  {
    id: "pipe-and-tank",
    courseId: 4,
    title: "Pipe and Tank \nInteractive Installation",
    desc: "",
    active: false,
    complete: false,
    xPos: 0.1,
    yPos: 1.5,
    zPos: -14,
    hoverMesh: null,
    taskFn: "viewPipeAndTank",
    navOptions: { displayPopupAsNavPanel: true },
    cameraOptions: {
      xPos: -0.5,
      yPos: 1.5,
      zPos: -11.5,
      FOV: 0.9,
      targetMesh: null,
      rotationX: Tools.ToRadians(0),
      rotationY: Tools.ToRadians(-190),
      rotationZ: 0,
      rotationEnabled: false,
      navEnabled: false,
      zoomEnabled: false
    }
  }
]

