import React, {Component, createRef, LegacyRef, MouseEventHandler, RefObject} from "react";

import LocationManager, {Locations} from "../../../../Managers/LocationManager";
import "./SafetyTools.css";
import SafetyToolsProps from "../SafetyTools.props";
import {Button,} from "@material-ui/core";
import SafetyToolsState from "./SafetyTools.state"
import SVGIcon from '../../../../icons/SVGIcon'

import Configs from "../../../../Config/Configs";
import {GetRoomIdx, GetUrlParameter} from "../../../utilities"
import CSS from 'csstype';
import UIManager from "../../../../Managers/UIManager";
import BabylonManager from "../../../../Managers/BabylonManager";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { PpeStatus } from "../../../../Data/dataSetTypes";
import { LoadingProgress } from "../../LoadingProgress/LoadingProgress";

class SafetyToolsPPEBase extends Component<SafetyToolsProps, SafetyToolsState> {    
    constructor(props: any) {
      super(props);
      this.state = new SafetyToolsState();
    }
   
    async componentDidMount(): Promise<void> {
      this.buildingCheck();
     

      // window.addEventListener("resize", ()=>{
      //     try{
      //       this.updateHotspots()
      //     }catch(err){
      //       console.log(err)
      //     }
      // })

      const mainContentObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          this.updateHotspots();
        });
      });

      const mainContentElm = document.querySelector('.ppe-char');
      mainContentObserver.observe(mainContentElm);

    }
// Switch to tools
// need to combind the building check into one(state update issue)
    private startTools = ():void => {
      const buildingIdx:number =  this.props.building;
      let bld:string;
      if (buildingIdx === 2){bld = "Residential"}
      if (buildingIdx === 3){bld = "Commercial"}

      const equipmentData = this.state.toolsEquipment.filter((item)=>{
        return item.building === bld || item.modelSubDir === bld
      })
      this.setState({ currentSet: 'tools', introView:false, hotSpotList: this.state.toolsHotspots, currentEquipment: equipmentData}, () => this.updateHotspots())
    }

// Correct items per building
    private  buildingCheck = async():Promise<void> =>{
      const buildingIdx:number =  this.props.building;
      let bld:string;
      if (buildingIdx === 2){bld = "Residential"}
      if (buildingIdx === 3){bld = "Commercial"}


	  UIManager.LocationRouterRef.current.setState({LoadBabylonContainer:true})
	  
		let ppeKey = "PPE-" + bld;
		let ppeStatus: PpeStatus = await UIManager.UiDataRef.current.getPPEStatus(ppeKey) as unknown as PpeStatus;
    
    if(!ppeStatus.taskList){
      this.updatePPEStatus("active")
    }else if(ppeStatus.taskList.Status === "complete"){
      //this.startTraining();
      this.setState({isCompleted:true});
    }
			
      const ppeData = this.state.currentEquipment.filter((item)=>{
        // return item.building === bld && item.section === "PPE" 
        return item.building === bld || item.modelSubDir === bld
      })
      this.setState({currentEquipment:ppeData})
    }

	private updatePPEStatus(status:string){

		const buildingIdx:number =  this.props.building;
		let bld:string;
		if (buildingIdx === 2){bld = "Residential"}
		if (buildingIdx === 3){bld = "Commercial"}
  
		let ppeKey = "PPE-" + bld;
		let ppeStatusData = JSON.stringify({"Status":status});
		 UIManager.UiDataRef.current.setUserTasks(ppeKey, ppeStatusData)
	  
	}
    private resetData = ():void =>{
      // this.clearHSData()
      this.buildingCheck();
    }

    private clearHSData():void{
      const hsList = this.state.hotSpotList;
      hsList.map((hs)=>{
        hs.popItem = null;
        hs.populated = false;
        hs.correct = null;
      })
      this.setState({hotSpotList:hsList})
    }

    private getPPEChar():ReactJSXElement[]{
      let imgUrl =  "Images/PPE/"
      if(this.state.currentSet === 'tools'){
        imgUrl =  "Images/Pegboard/"
      }
      if(this.state.currentEquipment &&  this.state.currentEquipment.length > 0){
        const charList:ReactJSXElement[] =  this.state.currentEquipment.map((item)=>{
          let visClass =" hidden"
          if(item.visible === true ){visClass = ""}
          return <div key={item.id} className={"ppe-img " + item.className + visClass}>
            <img src={Configs.ContentUrlWithTrailingSlash + imgUrl + item.imgFile}></img>
          </div>
        })
        return charList
      }else{
        return []
      }
    }

    private createHotSpots():ReactJSXElement[]{
      const joe =  document.querySelector('.char-body');
      const holder = document.getElementById('ppe-char');
      const hsList = this.state.hotSpotList.map((hs)=>{
        // currentSet: 'tools',
        let imgWidthOriginal = 1080;
        this.state.currentSet === 'tool' ? imgWidthOriginal = 1233 : imgWidthOriginal = 1080;
        const xOffset = (((holder.clientWidth /2) - (joe.clientWidth / 2)) + ((joe.clientWidth / imgWidthOriginal ) * hs.hotSpotPosX))

        const strStyle:CSS.Properties = {left: xOffset + "px" ,top: hs.hotSpotPosY + "%"}
        const sel = hs.populated === true ? " selected": "" ;
        let corr ="";
        let iconName = 'svgOpen'
        let cleanedClassName = hs.id.replace(/\s+/g, '-').toLowerCase();
        
        if(hs.popItem && hs.submitted){
          if(hs.popItem.correct === true){
              corr = " correct";
              // iconElem = faCheck as IconProp
              iconName = 'svgCorrect'
          }
          if(hs.popItem.correct === false){
              corr = " incorrect";
              // iconElem = faAngleDown as IconProp
              iconName = 'svgIncorrect'
          }
        }else{
          iconName = 'svgOpen'
        }
        return <div key={hs.id} className={"ppe-hs " + hs.id + " " + cleanedClassName + sel + corr} style={strStyle} onClick={()=>{this.setPPEIcons(hs.id)}} >
            <SVGIcon iconName={iconName} />
            </div>
      })
      if(this.state.currentSet === 'tools'){
        const hsCorrectList = this.state.hotSpotList;
        let hsCorrect = 0; 
        hsCorrectList.map((hs)=>{
          if(hs.submitted){
            hs.popItem.correct === true ? hsCorrect++ : ''
          }
        })
        let iconName = 'svgLocked';
        hsCorrect === hsCorrectList.length ? iconName = 'svgUnlocked' :  iconName = 'svgLocked'
         
        const xOffset = (((holder.clientWidth /2) - (joe.clientWidth / 2)) + ((joe.clientWidth / 1233 ) * 450))
        const strStyle:CSS.Properties = {left: xOffset + "px" ,top: "48%"}
        const specialitemIcon =  <div key={'special-hs'} className="ppe-hs special-Icon" style={strStyle} >
          {this.toolTip()}
          <SVGIcon iconName={iconName} />
        </div>
        hsList.push(specialitemIcon)
      }
      return hsList
    }

    private updateHotspots = ():void =>{
      const joe =  document.querySelector('.char-body');
      const holder = document.getElementById('ppe-char');
      const hsList = this.state.hotSpotList.map((hs)=>{
        
        const cleanedClassName = hs.id.replace(/\s+/g, '-').toLowerCase();
        const elmString = "." + cleanedClassName 
        const hpElem: HTMLElement = document.querySelector(elmString)
        let imgWidthOriginal = 1080;
        this.state.currentSet === 'tool' ? imgWidthOriginal = 1233 : imgWidthOriginal = 1080;
        if(hpElem){
          const xOffset = (((holder.clientWidth /2) - (joe.clientWidth / 2)) + ((joe.clientWidth / imgWidthOriginal) * hs.hotSpotPosX))
          hpElem.style.left = xOffset + "px"
        }
      })
      if(this.state.currentSet === 'tools'){
        const hpElem: HTMLElement = document.querySelector('.special-Icon')
        const xOffset = (((holder.clientWidth /2) - (joe.clientWidth / 2)) + ((joe.clientWidth / 1233 ) * 450))
        if(hpElem){
          hpElem.style.left = xOffset + "px"
        }
        
      }
    }

    private toolTip = ():ReactJSXElement =>{
      let tipElm: ReactJSXElement;
      const buildingIdx:number =  this.props.building;
      let bld:string;
      if (this.props.building === 2){
        tipElm = <div id='ppe-tooltip'>
          <h4>Blowing Machine Hose</h4>
          <span>Length: <b>150 feet</b></span>
          <span>Inside Diameter: <b>3 inches minimum</b></span>
          <p>Check hose for leaks, cracks and wear. Repair and replace every 6 months.</p>
        </div>
      } else {
        tipElm = <div id='ppe-tooltip' className="latter-tip">
          <div className="tip-content-left">
            <img src={Configs.ContentUrlWithTrailingSlash + 'Images/Pegboard/commercial-tip.png'} />
          </div>
          <div className="tip-content-right">
            <h4>Additional Items</h4>
            <p>Be sure to bring a Squeegee along with a high visibility vest and hearing protection based on job site requirements.</p>
            <span>
              <li>Hearing protection</li>
              <li>High visibility vest</li>
              <li>Squeegee</li>
            </span>
          </div>
        </div>
      }
      return(tipElm);
    }

    // HOTSPOT CLICKED
    private setPPEIcons(hsId:string):void{
      const ppeList = this.state.currentEquipment.filter((rec)=>{
        return rec.hotSpotId === hsId && rec.iconFile
      })
      this.setState({ppeIcons:ppeList, currentHotSpot:hsId, zoomedView: true}, () => this.zoomArea(hsId))
    }

    // zoom character
    private zoomArea = (hsId:string):void =>{
      const result = this.state.hotSpotList.find(({ id }) => id === hsId);
      const zoomElm: HTMLElement = document.getElementById("ppe-char");
      let zoomX = result.zoomX;
      const is_safari = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") == -1;
      if(is_safari){
        zoomX =  zoomX -20;
      }

      zoomElm.setAttribute("style", "top:" + result.zoomY + "%; left: " + zoomX + "%; transform: scale(2);") 
    }

    private zoomOutArea = ():void =>{
      const zoomElm = document.getElementById("ppe-char");
      zoomElm.setAttribute("style", "top: 0px; left: 0px; transform: scale(1);")
      // this.updateHotspots();
    }
    
    private itemCheck = ():void =>{
      const hsResult = this.state.hotSpotList.find(({ id }) => id === this.state.currentHotSpot);
      hsResult.submitted =  true;
      this.setState({hotSpotList: this.state.hotSpotList})
      if(hsResult.popItem.correct === true){
        const btns = document.getElementsByClassName("ppe-icon");
        for (const btn of btns) {
          btn.setAttribute("style","pointer-events: none;");
        }
        setTimeout(this.backOut, 1500);
      }
      
    }

    private getProperIcon = (hotSpotId:string, item:any):ReactJSXElement => {
      const hsResult = this.state.hotSpotList.find(({ id }) => id === hotSpotId);
      if (hsResult.submitted === true && item.active === true){
        if(hsResult.popItem.correct){
          return(
            <img className="thumb-correct-icon feedback-icon" src={Configs.ContentUrlWithTrailingSlash + "Images/Icons/correct.png"}/>
          )
        } else {
          return(
            <img className="thumb-incorrect-icon feedback-icon" src={Configs.ContentUrlWithTrailingSlash + "Images/Icons/incorrect_icon.png"}/>
          )
        }
      } else if(item.active === true){
        return(
          <img className="thumb-slected-icon feedback-icon" src={Configs.ContentUrlWithTrailingSlash + "Images/Icons/blue_icon.png"}/>
        )
      }

    }
// Next 2 functions need to be combined
    private getThumbColor = (hotSpotId:string, item:any):string => {
      const hsResult = this.state.hotSpotList.find(({ id }) => id === hotSpotId);
      if (hsResult.submitted === true && item.active === true){
        if(hsResult.popItem.correct){
          return("thumb-correct ppe-icon")
        } else {
          return("thumb-incorrect ppe-icon")
        }
      } else if(item.active === true){
        return("thumb-only-selected ppe-icon")
      }else{
        return("ppe-icon")
      }
    }

    private getThumbTxtResponce = (hotSpotId:string, item:any):ReactJSXElement => {
      const hsResult = this.state.hotSpotList.find(({ id }) => id === hotSpotId);
      if (hsResult.submitted === true && item.active === true){
        if(hsResult.popItem.correct){
          return(<span className="feedback-txt correct">That's correct!</span>)
        } else {
          return(<span className="feedback-txt incorrect">Wrong item, try again.</span>)
        }
      } else if(item.active === true){
        return(<span className="feedback-txt"></span>)
      }
    }


    private getPPEIcons():ReactJSXElement[]{
      const ppeList = this.state.ppeIcons;
      let imgUrl =  "Images/PPE/icons/"
      if(this.state.currentSet === 'tools'){
        imgUrl =  "Images/Pegboard/"
      }

      const icons = ppeList.map((item)=> {
        return <div key={item.id} className={this.getThumbColor(this.state.currentHotSpot, item)}
          onClick={()=>{this.selectIcon(item.id)}}>
            <span className="thumb-selected" >
              {this.getProperIcon(this.state.currentHotSpot, item)}
            </span>
            <img className="thumb-img" src={Configs.ContentUrlWithTrailingSlash + imgUrl + item.iconFile}/>
            <span>{item.label}</span>
            {this.getThumbTxtResponce(this.state.currentHotSpot, item)}
          </div>
      })
      return icons
    }
    
    // ITEM SELECTED
    private selectIcon = (selId:string):void => {
      const ppeList = this.state.currentEquipment;
      const selItem = ppeList.filter((item)=>{
        return item.id === selId
      })
      ppeList.map((item)=>{
          if( item.hotSpotId === selItem[0].hotSpotId){
            item.visible = false;
            item.active = false;
          }
        })
      selItem[0].visible = true;
      selItem[0].active = true;
      selItem[0].selected = true;

      const hsList = this.state.hotSpotList;
      let popItems = 0;

      hsList.map((item)=>{
        if(item.id === selItem[0].hotSpotId){
          item.populated = true;
          item.popItem = selItem[0];
          popItems++;
        }else{
          if(item.populated === true){popItems++}
        }
       return item
      })
      const hsResult = this.state.hotSpotList.find(({ id }) => id === this.state.currentHotSpot);
      hsResult.submitted =  false;

      this.setState({ppeEquipment:ppeList, hotSpotList:hsList, modelViewerOpen:false, selectedItem:selItem[0]})
    }

    private endHotspots = ():void =>{
      const hotspotElms = document.getElementsByClassName("ppe-hs") as HTMLCollectionOf<HTMLElement>;
      for (var i = 0; i < hotspotElms.length -1; i++) {
        hotspotElms[i].style.opacity = "0"
        setTimeout(hotspotElms[i].style.opacity = "0", 15000 + (i *10000));
     }
        // collection[0].style.backgroundColor = "red";
    }

    // SKIP BUTTON Prep
    private startTraining = ():void =>{
      this.resetData()
      BabylonManager.MainContext.startRenderLoop();

      let courseIdx =  parseInt(UIManager.UiOverlayRef.current.state.CourseId )
      let idx:number; //=  GetRoomIdx("LivingRoom")
      let startCta = 'START'
      //need to get commercial all named right 
      switch(courseIdx){
        case 0:
          idx = GetRoomIdx("LivingRoom")
        break
        case 1:
          idx = GetRoomIdx("Attic")
        break
        case 2:
          idx = GetRoomIdx("DiningRoom")
        break
        case 3:
          idx = GetRoomIdx("LivingRoom")
        break
        case 4:
          idx = GetRoomIdx("Kitchen")
        break
        case 5:
          idx = GetRoomIdx("Attic")
        break
        default:
          idx = GetRoomIdx("LivingRoom")  
      }
      // if(courseIdx === 0 || courseIdx === 3){
      //   idx = GetRoomIdx("LivingRoom")
      // }
      // if(courseIdx === 1 || courseIdx === 4){
      //   idx = GetRoomIdx("Attic")
      // }
      // if(courseIdx === 2 || courseIdx === 5){
      //   idx = GetRoomIdx("DiningRoom")
      // }
      //add spinner
      BabylonManager.MainContext.changeRoomState(idx)
      setTimeout(()=>{  
        UIManager.UiOverlayRef.current.setState({ShowSafetyTools:false,ShowTrainingWindow:false,HideOverlay:true,ShowReturnButton:true,ShowQuiz:false,})
        UIManager.LocationRouterRef.current.setState({ShowBabylonContainer:true}) // ,LoadBabylonContainer: true,
        LocationManager.NavigateTo(Locations.Babylon);
      },200)
    }

    // RIGHT CONTENT
    // Need to break this up 
    private rightContent = ():ReactJSXElement =>{
      // Removed the word below for better line break
      // let copyblurb = "Select an item of protective equipment below. Submit to equip."
      let copyblurb = "Select an item of protective equipment. Submit to equip."
      if(this.state.currentSet === 'tools'){
        copyblurb = "Select a tool below. Submit to equip."
      }
      let hasSelection = false;
      this.state.ppeEquipment.forEach( (item) => {
        if(item.hotSpotId === this.state.currentHotSpot && item.selected){
          hasSelection = true;
          return;
        }
      });
      const hsResult = this.state.hotSpotList.find(({ id }) => id === this.state.currentHotSpot);
      if(hsResult.submitted === true){
        if(hsResult.popItem.correct){
          
          return(
            <div>
              
              <div className="ppe-icon-wrapper">
                <div className="equip-title" >{this.state.currentHotSpot}</div>  
                <div className="inst equip-blurb">{copyblurb}</div>
                {this.getPPEIcons() }
              </div>
              {/* <Button onClick={this.backOut} id='submit-equipment-btn' className="primary-button btn eval long-btn submit-equipment-btn" disabled={!hasSelection}>NEXT EQUIPMENT</Button> */}
            </div>
          )
        }else{
          return(
            <div>
              
              {/* <div className="inst equip-blurb error">Oops. It looks like you have the incorrect PPE. Please replace the incorrect item with the correct equipment.</div> */}
              <div className="ppe-icon-wrapper"><div className="equip-title" >{this.state.currentHotSpot}</div><div className="inst equip-blurb error">{copyblurb}</div>  {this.getPPEIcons() }</div>
              <Button onClick={this.itemCheck} id='submit-equipment-btn' className="primary-button btn eval long-btn submit-equipment-btn" disabled >Try Again</Button>
            </div>
          )
        }
      }else{
        return(
          <div>
            
            <div className="ppe-icon-wrapper">
              <div className="equip-title" >{this.state.currentHotSpot}</div>  
              <div className="inst equip-blurb">{copyblurb}</div>
              {this.getPPEIcons() }
            </div>
            <Button onClick={this.itemCheck} id='submit-equipment-btn' className="primary-button btn eval long-btn submit-equipment-btn" disabled={!hasSelection}>Submit</Button>
          </div>
        )
      }
    }

    
    // LEFT CONTENT
    // Need to break this up 
    private leftContent = ():ReactJSXElement =>{
      const hsList = this.state.hotSpotList;
      // let startCta =  UIManager.UiOverlayRef.current.state.CourseTitle.toUpperCase();
      // THIS COULD BE A UTILITY OR JUST SAVED IN THE STATE or user the code above when working
      let startCta = 'START'
      switch(UIManager.UiOverlayRef.current.state.CourseId){
        case "0":
          startCta =  "START ECO-BATT INSULATION TRAINING";
        break
        case "1":
          startCta =  "START LOOSE FILL ATTIC TRAINING";
        break
        case "2":
          startCta =  "START NET & BLOW TRAINING";
        break
        case "3":
          startCta =  "START PIPE INSULATION TRAINING";
        break
        case "4":
          startCta =  "START PIPE & TANK TRAINING";
        break
        case "5":
          startCta =  "START DUCT WRAP TRAINING";
        break
        default:
          startCta =  "START TRAINING";   
      }

      let introText = "Click on a hotspot to equip the correct personal protective equipment in order to safely complete the residential insulation."
      let toolsIntroText = "Click on a hotspot to gather the correct tools needed to complete the residential insulation."
      if (Number(UIManager.UiOverlayRef.current.state.CourseId) > 2){
        introText = "Click on a hotspot to equip the correct personal protective equipment in order to safely complete the commercial insulation."
        toolsIntroText = "Click on a hotspot to gather the correct tools needed to complete the commercial insulation."
      }  
      
      let hsCorrect = 0;
      hsList.map((hs)=>{
        if(hs.submitted){
          hs.popItem.correct === true ? hsCorrect++ : ''
        }
      })
      
      if(hsCorrect === hsList.length && this.state.zoomedView === false && this.state.currentSet === 'ppe'){
        return(
          <>
            <div className="ppe-heading">Great Job!</div>
            <div className="ppe-intro-txt">
              You've completed the Personal Protective Equipment Training!
              <br /><br />
              Now let's get started with Tools Training.  
            </div>
            <Button className="primary-button btn eval start-btn" onClick={this.startTools}>START TOOLS TRAINING</Button>
            {this.state.introView === false ? this.itemCounter() : "" }
          </>
        )
      } else if((hsCorrect === hsList.length && this.state.zoomedView === false && this.state.currentSet === 'tools') || this.state.isCompleted === true){
        let bonusTool: HTMLElement = document.querySelector('.extra-tool')
        if(bonusTool){
          this.createHotSpots();
          setTimeout(()=>{  
            bonusTool.classList.add('fade-in');
            document.getElementById('ppe-tooltip').classList.add('fade-in');
            this.endHotspots();
          },250)
          
        }
		this.updatePPEStatus("complete")
        
        return(
          <>
            <div className="ppe-heading">Great Job!</div>
            <div className="ppe-intro-txt">
              You've completed the Tools and Equipment Training!  
            </div>
            {/* this is a bandaid for the loader in the rooms doesn't */}
            {UIManager.UiOverlayRef.current.state.ShowStartSceneBtn === false  ?
              <>
                <Button className="btn training"  disabled>Loading 3D Environment</Button>
                <LoadingProgress />
              </>
            :
            <Button className="primary-button btn eval start-btn" onClick={this.startTraining}>{startCta}</Button>
            }
            {/* <Button className="primary-button btn eval start-btn" onClick={this.startTraining}>{startCta}</Button> */}
            {this.state.introView === false ? this.itemCounter() : "" }
          </>
        )
      }

      if(this.state.introView === true && this.state.zoomedView === false){
        return(
            <div className="wide-left">
              <div className="ppe-heading">Personal Protective Equipment and Tools Training</div>
              {this.state.introView === true ? <Button className="primary-button btn eval start-btn" onClick={this.startPPE}>Start</Button> : ''}
            </div>
          )
      } else if(this.state.zoomedView === false  && this.state.currentSet === 'ppe'){
        return(
          <>
            <div className="ppe-heading">Personal Protective Equipment</div>
            <div className="ppe-intro-txt">
              {introText}
            </div>
            {this.state.introView === true ? <Button className="primary-button btn eval start-btn" onClick={this.startPPE}>Start</Button> : ''}
            {this.state.introView === false ? this.itemCounter() : "" }
          </>
        )
      } else if(this.state.zoomedView === false && this.state.currentSet === 'tools'){
        return(
          <>
            <div className="ppe-heading">Tools and Equipment</div>
            <div className="ppe-intro-txt">     
              {toolsIntroText}
            </div>
            {this.state.introView === false ? this.itemCounter() : "" }
          </>
        )
      }else{
        return(
          <div className="short-left">
            <Button className="primary-button btn short-btn" onClick={this.backOut}>
            <img src={Configs.ContentUrlWithTrailingSlash + "Images/Icons/white_arrow.svg"} />
              Back
            </Button>
          </div>
        );
      }
    }

  // item Counter Left
    private itemCounter = ():ReactJSXElement =>{
      const hsList = this.state.hotSpotList;
      let hsSubmitted = 0;
      hsList.map((hs)=>{
        hs.submitted ? hsSubmitted++ : ''
      })
      return(
        <>
          <div className="item-count-holder" onClick={this.toggleCompletionTable}>
            <div className="item-count-numbers">{hsSubmitted}/{this.state.hotSpotList.length}</div>
            <p>
              EQUIPMENT 
              <span>ADDED</span>
            </p>
          </div>
          {this.getCompletionTable()}
        </>
      )
    }

  // Completion Table
    private getCompletionTable = ():ReactJSXElement => {
      const listItems:any[] = [];
      this.state.hotSpotList.filter((item)=>{
        listItems.push(
          <div key={item.id} className="item-line" onClick={()=>{this.setPPEIcons(item.id)}}>
            {item.submitted ? this.getProperIcon(item.id, item.popItem) : <span className="completed-mark"></span>}
            {/* <span className="item-populated">{item.populated ? "1" : "0" }/1</span> */}
            <span className="item-name">{item.id}</span>
            <span className="item-link">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right-long" className="svg-inline--fa fa-arrow-right-long fa-w-16 icon-arrow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.6 278.6l-128 128c-12.51 12.51-32.76 12.49-45.25 0c-12.5-12.5-12.5-32.75 0-45.25L402.8 288H32C14.31 288 0 273.7 0 255.1S14.31 224 32 224h370.8l-73.38-73.38c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l128 128C515.1 245.9 515.1 266.1 502.6 278.6z"></path></svg>
              {/* <img src={Configs.ContentUrlWithTrailingSlash + "Images/Icons/black_arrow.png"} /> */}
            </span>
          </div>);
        return listItems;
      })
      let title = 'EQUIPMENT CHECKLIST'
      if(this.state.currentSet === 'tools'){ title = 'TOOLS CHECKLIST'}
      return(
        <div className={'completion-table ' + this.state.completionTable} >
          <h4>{title}</h4>
          {listItems}
        </div>
      )
    }
    private toggleCompletionTable = ():void => {
      if(this.state.completionTable === 'open'){
        this.setState({completionTable: 'closed'})
      }else{
        this.setState({completionTable: 'open'})
      }
      
    }
    // skip Button
    private skipBtn = ():ReactJSXElement => {
      return(
        <div className="start-training" id="skip-btn">
        {UIManager.UiOverlayRef.current.state.ShowStartSceneBtn === false  ?
          <Button className="btn training"  disabled>Loading 3D Environment</Button>
        :
          <Button className="primary-button btn training" id="skip-btn" onClick={this.startTraining}>Debug SKIP PPE and Tools</Button>
        }
        </div>
      )
    }

    private startPPE = ():void =>{
      this.setState({introView: false})
    }

    private backOut = ():void =>{
      this.setState({zoomedView : false}, () => this.zoomOutArea())
    }
      

    public render(): React.ReactElement {
      return (
        <>
        {/* {this.skipBtn()} */}
        <div className="safety-wrapper">
          <div className="ppe-intro">
            {this.leftContent()}
          </div>
          {/* LEFT CONTENT END  */}
          <div className="ppe-char" id='ppe-char'>
            {this.getPPEChar()}
            {this.state.introView === false && this.state.zoomedView === false ? this.createHotSpots() : ''}
          </div>
        {/* Right Content */}
          {this.state.currentHotSpot  && this.state.zoomedView ? 
            <div className="ppe-list">
              <div className="item-intro"></div>
              {this.rightContent()}
            </div>      
          : 
          ''
          }
        </div>
  </>
  )}
}


const SafetyTools = (SafetyToolsPPEBase) 
export default SafetyTools