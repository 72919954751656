import React, {Component} from "react";
import { assets } from "../../assetMap";
import UiTrainingWindow from "./TrainingComponents/UiTrainingWindow";
import UiTestMenu from "./UiTestMenu"
export default class UiFooter extends Component {

    constructor(props: any) {
        super(props);

        
    }
public trainingWindow: typeof UiTrainingWindow ;
componentDidUpdate(props: { trainingWindow: typeof UiTrainingWindow; }): void {
  this.trainingWindow  = props.trainingWindow
}

    public render(): React.ReactElement {
     //   console.log(this.trainingWindow)
            return (
                <div className="OverlayFooter Animated">
			
                    <h4>
                        <UiTestMenu></UiTestMenu>
                    </h4>
                </div>
            );
        }
}
