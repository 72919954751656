import React, {Component, createRef, Ref, RefObject} from "react";
import "./UiOverlay.css";
import Configs from "../Config/Configs";
import UiOverlayState from "./UiOverlay.state";
import LocationManager, {Locations} from "../Managers/LocationManager";
import TrainingManager from "../Managers/TrainingManager";
import UiProductMenu from "./UiComponents/UiProductMenu";

import UiHeader from "./UiComponents/UiHeader";
import UiFooter from "./UiComponents/UiFooter";
import UiTaskList from "./UiComponents/TrainingComponents/UiTaskList";
import UiTransition from "./UiComponents/UiTransition";
import UiTrainingWindow from "./UiComponents/TrainingComponents/UiTrainingWindow";
import UiPopupWindow from "./UiComponents/TrainingComponents/UiPopupWindow";
import UiToolStrip from "./UiComponents/TrainingComponents/UiToolStrip";
import UiQuiz from "./UiComponents/TrainingComponents/UiQuiz";
import UiVideoOverlay from "./UiComponents/TrainingComponents/UiVideoOverlay";
import UiPdfOverlay from "./UiComponents/TrainingComponents/UiPdfOverlay";
import UiImageTextOverlay from "./UiComponents/TrainingComponents/UiImageTextOverlay";
import IntroScreen from "./IntroScreen";
import UIManager from "../Managers/UIManager";
import TrainingHub from "./TrainingHub";
import SafetyTools from "./UiComponents/TrainingComponents/SafetyPPE/SafetyToolsppe";
import BabylonManager from "../Managers/BabylonManager";
import { TrainingLocations } from "../TrainingSession.props";
import UiNotepad from "./UiComponents/UiNotepad";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// import {getHsDataSet} from "../Data/datasets"
import { Button } from "@material-ui/core";
import { TagItem } from "@fluentui/react";
import IframeFunctions from "./UiComponents/iframe";
import { assets } from "../assetMap";
/**
 * A mostly non-functional placeholder overlay (stolen from the DigiKey Innovation Hub project)
 * Edit or remove as needed.
 */
export default class UiOverlay extends Component<unknown, UiOverlayState> {

    constructor(props: any) {
        super(props);
        this.state = new UiOverlayState();
    }
     
    public resetState(): void {

        const initialState = {
            ShowingTransition: false,
            TransitionExtraClass: "",
            ShowHeader: true,
            ShowFooter: true,
            ShowIntro: true,
            ShowProductMenu: false,
            ShowMap: false,
            ShowNavigationButton: false,
            NavigationButtonText: "",
            PopupData:{posX:400}
        };

        this.setState(initialState);

    }

   
//public  popup: UiPopupWindow = new UiPopupWindow(this.state.PopupData)
    public showIntro(): void {

        // -- Short delay to offset babylon loader fade
        setTimeout(() => {
            this.setState({IntroFadingIn: true})
        }, 0);
    }

    public showTransition(): void {
        this.setState({ShowingTransition: true, TransitionExtraClass: "Showing"});
    }

    public hideTransition(): void {
        this.setState({ShowingTransition: false, TransitionExtraClass: ""});
    }

    public showTutorial() {
        this.setState({TutorialOpacity: 1, ShowTutorial: true});
        // setTimeout(() => this.setState({TutorialOpacity: 1}), 50);
    }

    public hideTutorial() {
        this.setState({TutorialOpacity: 0});
        setTimeout(() => this.setState({ShowTutorial: false}), 400);
    }

	private getWelcomeMessage(): ReactJSXElement{
return <>
<div className={ this.state.HidingWelcomeMessage === true ? "welcome-mssg hide-welcome" : "welcome-mssg"} >
	<div className="welcome-close" onClick={()=>{this.setState({ShowWelcomeMessage:false})}}><FontAwesomeIcon icon={faTimesCircle as IconProp} /></div>
	<div className="welcome-txt">
		<div className="top-line">Welcome to</div>
		<div>{this.state.WelcomeMessageText}</div>
		<div className="bottom-line">...loading environment...</div>
		</div>
</div>
</>
	}

    public handleStartButton(): void {

        this.setState({IntroFadingOut: true, TutorialOpacity: 0, ShowTutorial: false, ShowTrainingWindow:false});
          setTimeout(() => {

            this.showTransition();
            this.state.OnClickStartButton();

            setTimeout(() => {
                this.setState({IntroFadingOut: false, TutorialOpacity: 1});
            }, 1100);

        }, 300);

    }


	private startCourse(): void{
		IframeFunctions.toggleFullScreen();
		this.setState({showCourseLandingPanel:false})

	}

// private getLandingPanel(){
//     let hsData = getHsDataSet() //UIManager.UiDataRef.current.hsData;
//     let courseIdx = parseInt(sessionStorage.courseIdx)
//     let fData = hsData.filter((item)=>{
//     return item.id === courseIdx
//   })

// if(fData.length >0){
//   let cData = fData[0];
//   let bkgImg: string;
// if(cData.building === "residential"){
// 	bkgImg = "Res_Exterior_3_8bit.png"
// }else{
// 	bkgImg = "CI_Exterior2.png"
// }
// 		if(this.state.showCourseLandingPanel == true){ 
// 			return		   
//     <div className="course-landing-panel">
// 		<div className="landing-panel-wrapper">
// 		<div className="panel-left">
// 		<div className="popup-img">
// 		<img src={Configs.ContentUrlWithTrailingSlash + "Images/hotspots/" + cData.popupImg} className="popup" />
// 		</div>
// 			<div className="">
// 			<img src={Configs.ContentUrlWithTrailingSlash + "Images/" + bkgImg} className="training-hub-model" />
// 			</div>
			
// 		</div>
// 		<div className="panel-right">
// 		<div className="heading">{cData.title}</div>
// 		<div className="desc">{cData.summary}</div>
// 		<div className="user-status">{cData.tasksComplete} Tasks Completed of {cData.totalSections}</div>
// 		<Button className="primary-button start" onClick={()=>{this.startCourse()}} >START</Button>
// 		</div>
// 		</div>
// 					   </div>
// 		}else{
// 			return <div></div>

// 		}
// 		}else{
// 			return <div></div>
// 		}
// 		}

    public render(): React.ReactElement {
       // console.log("showProducts", this.state.ShowProductMenu)
             return (
                <>
				<div className="too-small">
					<div>
						<img src={assets.fns.getAssetPath("tooSmall","courseAssets","battInstall")} />
   
					</div>
					<div className="title">
						Browser window is too small.
					</div>
					<div className="content">
						Scale up your browser to continue the training
					</div>
				</div>
                 {this.state.ShowHeader && !this.state.ShowIntro &&
                            <UiHeader ref={UIManager.UiHeaderRef}
                               
                            />
                        }
                         {this.state.ShowPopupWindow === true ?  <UiPopupWindow ref={UIManager.UiPopupRef} />
						 : <div className='hidden'><UiPopupWindow ref={UIManager.UiPopupRef} /></div>}
                   
                    <div className={this.state.HideOverlay ? "OverlayRoot DisplayNone" : "OverlayRoot"} style={this.state.DimOverlay ? {backgroundColor: "#00000077"} : {}}>
                        <UiTransition
                            ShowingTransition={this.state.ShowingTransition}
                            ExtraClass={this.state.TransitionExtraClass}
                        />
                       
                        
                      
                     
                        

                        {this.state.ShowProductMenu && <UiProductMenu />}
                      {this.state.ShowTrainingWindow && <UiTrainingWindow />}
                        
                        {this.state.ShowFooter && <UiFooter />}
                        {this.state.ShowTutorial &&
                            <div className="TutorialBackground" style={{opacity: this.state.TutorialOpacity}} onClick={() => this.hideTutorial()}>
                                <div className="TutorialDialog" onClick={(e) => {e.preventDefault()}}>
                                    <div className="TutorialDialogHeader">
                                        Welcome!
                                    </div>
                                    <div className="TutorialDialogBody">
                                        Welcome to the Knauf Academy Immersive Training experience!  Watch as our industry leading insulation installation techniques come to life as you successfully answer questions in realtime.
                                        <br/>
                                        <br/>
                                        Please choose one of our products to begin.
                                    </div>
                                    <div className="TutorialDialogButton" onClick={() => this.hideTutorial()}>
                                        Let's go!
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

					{this.state.ShowIntro &&
						<>
                            <IntroScreen onClickBeginTraining={() => this.handleStartButton()} fadingOut={this.state.IntroFadingOut} fadingIn={this.state.IntroFadingIn}/>
							<TrainingHub />
							</>
                        }
					{this.state.ShowTrainingHub &&
						<>
                	<TrainingHub />
							</>
                        }
						  {this.state.ShowSafetyTools &&
						<>
						
						    {/* {this.getLandingPanel() } */}
                            <SafetyTools building={parseInt(sessionStorage.building)}/>
							</>
                        }
                        
					{this.state.ShowVideoOverlay && <UiVideoOverlay ref={UIManager.UiVideoOverlayRef} />}
                        {this.state.ShowPdfOverlay && <UiPdfOverlay ref={UIManager.UiPdfOverlayRef} />}
                        {this.state.ShowImageTextOverlay && <UiImageTextOverlay ref={UIManager.UiImageTextOverlayRef} />}
                       
                   {this.state.ShowWelcomeMessage && this.getWelcomeMessage() }
                    {this.state.ShowToolStrip && <UiToolStrip  ref={UIManager.UiToolStripRef} />}
                    {this.state.ShowNotepad && <UiNotepad  ref={UIManager.UiNotepadRef} />}
                    {this.state.ShowTaskList && this.state.ShowSafetyTools === false && <UiTaskList ref={UIManager.TaskListRef} />}
                    {this.state.ShowQuiz && this.state.ShowSafetyTools === false && <UiQuiz courseId={this.state.CourseId} courseName={this.state.CourseTitle}/>}
                </>
            );
        }
}
