import { Tools, Vector3 } from "@babylonjs/core";
import { assets } from "../../assetMap";
import { ImgTextData, TaskListItem } from "../dataSetTypes";

const looseFillIntroPanel: ImgTextData[] = [
    {
        heading: "Welcome",
        strHtml: "Learn the correct...",
        arrLinks: [
            {
                linkText: "Air Sealing and Insulating Ceilings",
                linkUrl: "https://www.knaufnorthamerica.com/en-us/r-value",
            },
        ],
    },
];
const densityPanel: ImgTextData[] = [
    {
        heading: "Open Attic Coverage",
        imageFileName: assets.fns.getAssetPath(
            "densityTable",
            "courseAssets",
            "looseFillAttic"
        ),
        strHtml:
            "Before you begin, it's important to confirm that you have the correct number of packages to complete the job. Refer to the table found in the product data sheet (open attic coverage table). <br><br>For this particular installation, You will need to install approximately 1200 sq ft at an R30. Based on the table, you would need no less than 19 packages to complete the job. ",
        arrLinks: [],
    },
];

const atticBlowerPanel: ImgTextData[] = [
    {
        heading: "Prepping Blowing Machine",
        imgAutoPlay: true,
        imageSet: [
            {
                imageFileName: assets.fns.getAssetPath(
                    "blowerEcofill1",
                    "courseAssets",
                    "looseFillAttic"
                ),
            },
            {
                imageFileName: assets.fns.getAssetPath(
                    "blowerEcofill2",
                    "courseAssets",
                    "looseFillAttic"
                ),
            },
        ],
        strHtml:
            "Blown in insulation performs best when the fibers expand and are fully separated by air while flowing through the hose. Its package is compressed for shipping and storage, which means it has to run through a machine and hose in order to open the fibers.<br/<br/>Check all machine settings for the proper feed gate and airflow settings. Make sure to attach the correct length and diameter of hose per the settings and recommendations found on the insulation bag label or product data sheet.<br/<br/>Click the link below to view detailed instructions on setting up your machine.",
        arrLinks: [
            {
                linkText: "Attic Insulation Installation Guide",
                linkUrl: assets.fns.getAssetPath(
                    "atticInstallGuide",
                    "courseAssets",
                    "looseFillAttic"
                ),
            },
        ],
    },
];

const bafflesPanel: ImgTextData[] = [
    {
        heading: "Baffles",
        imageFileName: assets.fns
            .getAssetPath("bafflesPanelImg2", "courseAssets", "looseFillAttic")
            .toString(),
        strHtml:
            'Baffles and vent chutes direct air from the soffit vents up and over the insulation for a vented roof assembly, and prevent high wind events from dislodging the insulation away from the top plate (i.e. "windwashing")',
        arrLinks: [
            {
                linkText: "Air Sealing and Insulating Ceilings",
                linkUrl: "https://www.knaufnorthamerica.com/en-us/r-value",
            },
        ],
    },
];
const rValuePanel: ImgTextData[] = [
    {
        heading: "Attic R Value Rulers",
        imageFileName: assets.fns
            .getAssetPath("rvalueRuler", "courseAssets", "looseFillAttic")
            .toString(),
        strHtml:
            "An attic ruler is a simple depth marker for loosefill insulation to ensure proper coverage and the correct R-value is installed. They should be installed no less than one for every 300 square feet of attic space, with one near the attic access for easy inspection. Local requirements may differ, so be sure to consult your local building code or code official.",
        arrLinks: [
            {
                linkText: "Blown-In Insulation's Measures and Checks",
                linkUrl:
                    "https://www.knaufnorthamerica.com/en-ca/products/blown-in-insulation",
            },
        ],
    },
];

export let hotspotsLooseFill = [
    {
        id: "img-text-rvaluerulers",
        parentId: "loose-fill-rulers",
        task: "rvaluerulers",
        priority: 0,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: { autoAdvance: true, panelData: rValuePanel },
        active: false,
        complete: false,
    },
    {
        id: "airsealing-video",
        parentId: "loose-fill-air-sealing",
        task: "AirSealingVideo",
        priority: 0,
        inst: "",
        type: "video",
        fnId: "",
        params: {
            autoAdvance: true,
            src: "external",
            videoHeading: "Air Sealing.",
            url: "https://www.youtube.com/watch?v=0BdWnVAdxEM",
            videoText: "",
        },
        active: false,
        complete: false,
    },
    {
        id: "img-text-baffles",
        parentId: "loose-fill-baffles",
        task: "looseFillBaffles",
        priority: 0,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: { autoAdvance: true, panelData: bafflesPanel },
        active: false,
        complete: false,
    },
    {
        id: "how-to-install-video",
        parentId: "loose-fill-how-to-install",
        task: "howToInstall",
        priority: 0,
        inst: "",
        type: "video",
        fnId: "",
        params: {
            autoAdvance: true,
            src: "external",
            videoHeading: "How To Install",
            url: "https://www.youtube.com/watch?v=ktveal9QUJQ",
            videoText: "",
        },
        active: false,
        complete: false,
    },
];

export let interactiveLooseFill = [
    {
        id: "intro-loosefill",
        parentId: "loose-fill-hands-on",
        task: "onInstallLooseFill",
        priority: 0,
        inst: "",
        type: "popup",
        fnId: "",
        params: {
            autoAdvance: true,
            text: "Welcome to the Loose Fill Attic Insulation Training. <br><br> Here you will learn all of the steps required for installing fiberglass loose fill and ensure proper R-Value Loose fill is installed.",
            title: "Loose Fill Attic Interactive Installation",
            retainPopup: true,
            xPos: 150,
            yPos: 175,
            btnText: "Start Training",
        },
        active: false,
        complete: false,
    },
    // {id:"img-text-baffles",parentId:"loose-fill-hands-on",task:"onInstallLooseFill",priority:0,inst:"" ,type:"imagetext",fnId:"",
    // params:{autoAdvance:true,panelData:bafflesPanel }
    // ,active:false,complete:false,},

    {
        id: "chklist-attic",
        parentId: "loose-fill-hands-on",
        task: "onInstallLooseFill",
        priority: 2,
        inst: "",
        type: "checklist",
        fnId: "",
        params: {
            autoAdvance: true,
            text: "Before installation, make sure you have completed the jobsite preparation.",
            checklist: [
                "All areas to be insulated are built to allow code minimum insulation requirements, (e.g.use of raised-heal truss to allow the full thickness of minimum required insulation, plus minimum required vent space).",
                "All attic facing walls are backed with rigid material, and sealed for air infiltration (e.g.knee walls to attic, wall to porch roof, skylight shaft, etc.)",
                "Top and bottom plates, and/or appropriate blocking (e.g.blocking in rafters above knee wall top plates), are installed in all walls, including knee walls.",
                "Floor cavities are blocked between conditioned and unconditioned areas (e.g. bonus room to attic, second floor to garage,rim/band joists, etc.)",
                "Dropped soffits directly below attic space are backed with rigid material and air sealed.",
                "All shafts/chases between different elevations in ceilings and/or floors are capped with rigid material and air sealed.",
            ],
            navOptions: { displayPopupAsNavPanel: true },
            title: "Prepare the Job Site",
            xPos: 150,
            yPos: 175,
            btnText: "NEXT",
        },
        active: false,
        complete: false,
    },

    {
        id: "prep-blower",
        parentId: "loose-fill-hands-on",
        task: "onInstallLooseFill",
        priority: 3,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: {
            panelData: atticBlowerPanel,
            navOptions: { displayPopupAsNavPanel: true },
        },
        active: false,
        complete: false,
    },

    {
        id: "intro-loosefill",
        parentId: "loose-fill-hands-on",
        task: "onInstallLooseFill",
        priority: 4,
        inst: "",
        type: "popup",
        fnId: "",
        params: {
            autoAdvance: true,
            text: 'In this interactive installation, you will be blowing insulation up to a minimum fill line of 10 3⁄8 inches to achieve an R-30 value.',
            title: "ENSURING PROPER R-VALUE FILL LINE",
            retainPopup: true,
            xPos: 150,
            yPos: 175,
            btnText: "NEXT",
        },
        active: false,
        complete: false,
    },
    // {id:"img-text-baffles",parentId:"loose-fill-hands-on",task:"onInstallLooseFill",priority:0,inst:"" ,type:"imagetext",fnId:"",
    // params:{autoAdvance:true,panelData:looseFillIntroPanel }
    // ,active:false,complete:false,},

    {
        id: "interaction-loosefill",
        parentId: "loose-fill-hands-on",
        task: "onInstallLooseFill",
        priority: 5,
        inst: "",
        type: "interaction",
        fnId: "setupLooseFillCavity",
        params: {
            comment:
                "Select and move the hose down parallel to the ground and begin to blow in insulation. <br><br> Important to remember:",
            pullQuote:
                "For demonstration purposes only, we are finishing in the center.  &nbsp;When physically installing, start from the farthest point from entry and work your way out of the attic.",
            commentTitle: "Begin Blowing Insulation",
            xPos: 150,
            yPos: 175,
            btnText: "NEXT",
            speedRatio: 1,
            animation: "RES-ANIM-SingleCavityFill",
            autoAdvance: false,
            navOptions: { displayPopupAsNavPanel: true },
        },
        active: false,
        complete: false,
    },

    {
        id: "triggeranim-loosefill",
        parentId: "loose-fill-hands-on",
        task: "onInstallLooseFill",
        priority: 6,
        inst: "",
        type: "interaction",
        fnId: "setupFillAttic",
        params: {
            autoAdvance: false,
            retainPopup: true,
            destroyOnInit: true,
            animation: "ANIM-AtticLooseFill_Accumulation",
            correctToolName: "",
            targetName: "blowerHoseM",
            xPos: 150,
            yPos: 175,
            btnText: "Continue...",
            navOptions: { displayPopupAsNavPanel: true },
            comment:
                "Continue blowing insulation in all directions until you have achieved the desired R-Value height. <br><br>Important to remember:<br>",
            commentTitle: "Continue Blowing Insulation",
            pullQuote:
                "Ensure the insulation is leveled so the correct R-Value is achieved evenly.",
        },
        active: false,
        complete: false,
    },

    {
        id: "outro-loosefill",
        parentId: "loose-fill-hands-on",
        task: "onInstallLooseFill",
        priority: 7,
        inst: "",
        type: "popup",
        fnId: "",
        params: {
            text:
                "Congrats! You have successfully completed the Loose Fill Attic Interactive Insulation Training.<br><br>You can review the training steps in the <a href='" +
                assets.fns.getAssetPath(
                    "atticInstallGuide",
                    "courseAssets",
                    "looseFillAttic"
                ) +
                "' target='_blank'>official installation guide</a> for additional information.",
            navOptions: { displayPopupAsNavPanel: false },
            showCompleteBtn: true,
            autoAdvance: true,
            pullQuote: "",
            title: "LOOSE FILL ATTIC INTERACTIVE INSTALLATION",
            xPos: 150,
            yPos: 175,
            btnText: "EXIT HOTSPOT",
        },
        active: false,
        complete: false,
    },
];

export let tasksLooseFill: TaskListItem[] = [
    {
        id: "loose-fill-hands-on",
        courseId: 1,
        title: "Loose Fill Attic Installation",
        desc: "",
        active: false,
        complete: false,
        xPos: -2.7,
        yPos: 6,
        zPos: -4.01,
        hoverMesh: null,
        taskFn: "onInstallLooseFill",
        navOptions: { displayPopupAsNavPanel: true },
        //cameraOptions:{xPos:1.8,yPos:1.7,zPos:-7.61,FOV:1, targetMesh:null,
        cameraOptions: {
            xPos: -2.7,
            yPos: 6.26,
            zPos: -5.343,
            FOV: 1.048,
            targetMesh: null,
            //rotationX:Tools.ToRadians(10),rotationY:Tools.ToRadians(175), rotationZ:0,
            rotationX: Tools.ToRadians(10),
            rotationY: Tools.ToRadians(0),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },

    {
        id: "loose-fill-rulers",
        courseId: 1,
        title: "Attic R Value Rulers",
        desc: "",
        active: false,
        complete: false,
        xPos: -1.332,
        yPos: 6,
        zPos: -4.113,
        hoverMesh: null,
        taskFn: "rvaluerulers",
        //cameraOptions:{xPos:1.8,yPos:1.7,zPos:-7.61,FOV:1, targetMesh:null,
        cameraOptions: {
            xPos: -2.7,
            yPos: 6.26,
            zPos: -5.343,
            FOV: 1.048,
            targetMesh: null,
            //rotationX:Tools.ToRadians(10),rotationY:Tools.ToRadians(175), rotationZ:0,
            rotationX: Tools.ToRadians(10),
            rotationY: Tools.ToRadians(0),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
    {
        id: "loose-fill-baffles",
        courseId: 1,
        title: "Baffles",
        desc: "",
        active: false,
        complete: false,
        xPos: -3.5,
        yPos: 6.35,
        zPos: -3.991,
        hoverMesh: null,
        taskFn: "looseFillBaffles",
        cameraOptions: {
            xPos: -2.7,
            yPos: 6.26,
            zPos: -5.343,
            FOV: 1.048,
            targetMesh: null,
            rotationX: Tools.ToRadians(10),
            rotationY: Tools.ToRadians(0),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
    {
        id: "loose-fill-air-sealing",
        courseId: 1,
        title: "Air Sealing",
        desc: "",
        active: false,
        complete: false,
        xPos: -4,
        yPos: 6.1,
        zPos: -3.991,
        hoverMesh: null,
        taskFn: "AirSealingVideo",
        cameraOptions: {
            xPos: -2.7,
            yPos: 6.26,
            zPos: -5.343,
            FOV: 1.048,
            targetMesh: null,
            rotationX: Tools.ToRadians(10),
            rotationY: Tools.ToRadians(0),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
    {
        id: "loose-fill-how-to-install",
        courseId: 1,
        title: "How To Install",
        desc: "",
        active: false,
        complete: false,
        xPos: -2,
        yPos: 6,
        zPos: -4.01,
        hoverMesh: null,
        taskFn: "howToInstall",
        cameraOptions: {
            xPos: -2.7,
            yPos: 6.26,
            zPos: -5.343,
            FOV: 1.048,
            targetMesh: null,
            rotationX: Tools.ToRadians(10),
            rotationY: Tools.ToRadians(0),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
];
