//Circular dependencies are fun, so this is in its own file now
export enum ResidentialHouseStates {
    NavigatingToAttic,
    Attic, //1
    NavigatingToKitchen,
    Kitchen,
    NavigatingToLivingRoom,
    LivingRoom, //5
    NavigatingToOutside,
    Outside,
    NavigatingToDiningRoom,
    DiningRoom //9
}

export default ResidentialHouseStates;
