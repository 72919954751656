
/**
 * Enum containing a list of all possible training rooms.
 */
 import ResidentialHouseStates from "./Babylon/Main/Rooms/ResidentialHouse/ResidentialHouseStates";
 import CommercialWarehouseStates from "./Babylon/Main/Rooms/Commercial/CommercialWarehouseStates";


 
export type AllBuildingStates = ResidentialHouseStates | CommercialWarehouseStates;

export enum TrainingLocations {
    None,
    Residential,
    Commercial
}

export interface TrainingLocation {
    title: string;
    description: string;
    products: TrainingProduct[];
    progress?: number;
    path?: string;
}

export interface TrainingProduct {
    title: string;
    description: string;
    preChecks: TrainingPreCheck[];
    questions: TrainingQuestion[];
    progress?: number;
    path?: string;
    thumbnail?: string;
    state?: ResidentialHouseStates; // | OtherHouseStates
}

export interface TrainingPreCheck {
    title: string;
    description?: string;
    question?: string;
    questionTrans?: string;
    type: TrainingPreCheckTypes;
    animation?: string;
    answers: TrainingAnswer[];
    path?: string;
}

export enum TrainingPreCheckTypes {
    situation,
    list
}

export interface TrainingQuestion {
    title: string;
    description: string;
    type: TrainingQuestionTypes;
    animation?: string;
    answers: TrainingAnswer[];
    path?: string;
}

export enum TrainingQuestionTypes {
    trueOrFalse,
    multipleChoice
}

export interface TrainingAnswer {
    title: string;
    value?: string | number | boolean;
    icon?: string;
    response?: string;
}

export interface TrainingStatus {
    location: TrainingLocation;
    product: TrainingProduct;
    preCheck: TrainingPreCheck;
    question: TrainingQuestion;
    productIndex: number;
    preCheckIndex: number;
    questionIndex: number;
}
