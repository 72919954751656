
type TaskListItem={
   id:string,
   title:string,
   desc:string,
   active:boolean,
   complete:boolean,
   xPos:number,
   yPos:number,
   zPos:number,
   hoverMesh?:string,
   taskFn:string,
   courseId:number,
   cameraOptions:{xPos:number, yPos:number,zPos:number,FOV:number,targetX?:number,targetY?:number,targetZ?:number,targetMesh?:string,rotationX:number,rotationY:number,rotationZ:number, rotationEnabled:boolean,navEnabled:boolean,zoomEnabled:boolean}
}
export default class UiDataState {
   public toolsData:any[];
   public quizData:any[];
   public tasksData:TaskListItem[];
   public stepsData:any;
   public courseId: number;
   public courseTitle: string;
   public resourcesLoaded: string[];
    }