import 'regenerator-runtime/runtime';
import 'core-js';
import './Extensions';
import  './i18n'
import React from 'react';
import ReactDOM from 'react-dom';
import './Index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
