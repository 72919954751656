import React, {Component} from "react";
import LocationManager from "./Managers/LocationManager";
import LocationRouter from "./LocationRouter";
import UIManager from "./Managers/UIManager";
import "./ProjectRoot.css";
import UiOverlay from "./ReactComponents/UiOverlay";

//TODO: Rename this component to the actual Project name
/**
 * The root of the project, which containers the LocationRouter component and sets up that ref.
 */
export default class ProjectRoot extends Component {

    public componentDidMount(): void {
        //This can't be any lower in the hierarchy because react mounts children BEFORE parents.  Fun fact.
        LocationManager.LoadFromUrl();
    }

    public render(): React.ReactElement {
        return (
            <>
            <LocationRouter ref={UIManager.LocationRouterRef}/>
            <UiOverlay ref={UIManager.UiOverlayRef}/>
            </>
        );
    }
}
