import React, {Component, createRef, LegacyRef, RefObject} from "react";

import LocationManager, {Locations} from "../../../Managers/LocationManager";
import "../../BabylonContainer.css";

import "./UiTaskList.css";
import TaskListState from  "./UiTaskList.state";
import IBabylonContainerProps from "../../BabylonContainer.props";

import {Engine, SceneLoader, Scene,ISceneLoaderPlugin, CubeTexture,AbstractMesh, ArcRotateCamera, Mesh , FreeCamera, Vector3, HemisphericLight, MeshBuilder, Color4} from "@babylonjs/core"; 
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import Paper from "@material-ui/core/Paper";
import { AppBar, Grow, Menu, MenuItem, Tab, Tabs,Accordion, AccordionDetails,AccordionSummary, Button, Popover  } from "@material-ui/core";
import i18next, { t } from 'i18next'

import { withTranslation,WithTranslation  } from 'react-i18next';
import { FramingBehavior, TransformNode } from "babylonjs";
import UIManager from "../../../Managers/UIManager";
import BabylonManager from "../../../Managers/BabylonManager";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faUnlock } from '@fortawesome/free-solid-svg-icons'
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons'
import { faAnglesUp } from '@fortawesome/free-solid-svg-icons'
import { faExpand } from '@fortawesome/free-solid-svg-icons'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { GetFillerText } from "../../utilities";
import i18n from "../../../i18n";
  
export default class TaskList extends Component<unknown, TaskListState> {
   
   // public TaskListRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  
   
    constructor(props: any) {
        super(props);
       this.state = new TaskListState();
      
    }

    componentDidMount(): void {
        console.log(UIManager.UiDataRef.current.state.tasksData) //UIManager.UiOverlayRef.current.state.TaskData)
        console.log(this.state)
        this.setState({taskData: UIManager.UiDataRef.current.state.tasksData})
    }




public startQuiz():void{
    console.log("coursecomplete",UIManager.UiOverlayRef.current.state.CourseComplete === true )
    
if(this.state.readyForQuiz === true || UIManager.UiOverlayRef.current.state.CourseComplete === true){
    UIManager.UiOverlayRef.current.setState({ShowQuiz:true})
   
}

}

    private startTask(id:string):void{
BabylonManager.MainContext.startTask(id)
    }

	private toggleExpand(){
console.log("expand toggle")
this.setState({expanded:!this.state.expanded})
	}

public render():React.ReactElement{
   // this.chkForComplete();

   let totalCompleted:number = 0;
console.log("tasklist", this.state.taskData)
if(this.state.taskData && this.state.taskData.length >0){
let tasksComplete = this.state.taskData.filter((item,idx)=>{
return item.complete === true
})

 totalCompleted = tasksComplete.length
//this.setState({totalCompleted: tasksComplete.length})
}
// turn condition back on when testing is finished
//let readyForQuiz = true ;
let readyForQuiz = UIManager.UiOverlayRef.current.state.CourseComplete;
  return(
<>
{this.state.taskData ? 
<div className="task-list-wrapper" >
<Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
<List className="task-list" 
 subheader={
    <ListSubheader className="list-header" component="div" id="nested-list-subheader">
  {i18n.t(UIManager.UiOverlayRef.current.state.CourseTitle).toUpperCase()} MODULE
  <div></div>
    </ListSubheader>
  }
>
    {this.state.taskData.map((item,idx)=>{
     return   <ListItemButton component="div" onClick={()=>{this.startTask(item.id)}} >
 <div className="task-icon-wrapper">
{item.complete ? 
    <FontAwesomeIcon className="icon-complete" icon={faCircleCheck as IconProp} />
     :
     ""
     
}
{item.active && item.complete === false ? 
    <FontAwesomeIcon className="icon-active" icon={faCircleHalfStroke as IconProp} />
     :
     ""
     
}
{item.active=== false && item.complete === false ? 
    <FontAwesomeIcon  className="icon-incomplete" icon={faCircle as IconProp} />
     :""
}
     </div>
  <ListItemText primary={item.title} />
  <FontAwesomeIcon className="icon-arrow" icon={faArrowRightLong as IconProp} />
   
</ListItemButton>
    })}
 <ListItemButton className="quiz-link" component="div" onClick={()=>{this.startQuiz()}} >
 <div className="task-icon-wrapper">
{readyForQuiz ? 
	<>
	 <FontAwesomeIcon className="icon-unlocked" icon={faUnlock as IconProp} />
     
	 <div className="task-content">
	   <ListItemText className="quiz-task-title" primary={this.state.quizTitle} />  
	   </div>
	  
	 </> 
     :<>
	
     <FontAwesomeIcon className="icon-locked" icon={faLock as IconProp} />  
	 <div className="task-content">
	   <ListItemText className="quiz-task-title" primary={this.state.quizTitle} />  
	   <div className="tiny-text">Complete all hotspots first to unlock the quiz.</div>
	   </div>
	  
	 </> 
}


     </div>

 
</ListItemButton>
</List>
</Collapse>


<Button className="primary-button btn-tasks" onClick={()=>{this.toggleExpand()}}> <FontAwesomeIcon className="icon-expand" icon={this.state.expanded === true ? faAnglesDown as IconProp : faAnglesUp as IconProp} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {totalCompleted}/{this.state.taskData.length} 
   </Button>

</div>
:""
}
</>

    )
}

}


//const TaskList = withTranslation()(TaskListBase) 
//export default TaskList

