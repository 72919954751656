import {LinkData} from '../../../Data/dataSetTypes'

export default class VideoOverlayState {
   public isVisible: boolean=true;
   public posX: number=0;
   public posY: number=0;
public videoUrl:string;
public closeAction:any;
public title:string;
public percentComplete:number=0;
public stepId:string;
public videoText:string;
public videoHeading:string;
public links?: LinkData[];
}
