import React, {Component} from "react";
import "./UiProductMenu.css";
import TrainingManager from "../../Managers/TrainingManager";
import { TrainingLocation, TrainingProduct } from "../../TrainingSession.props";
import IUiProductMenuProps from "./UiProductMenu.props";
import UiProductMenuItem from "./UiProductMenuItem";


export default class UiProductMenu extends Component<IUiProductMenuProps, unknown> {

    constructor(props: any) {
        super(props);
    }

    private location:TrainingLocation = TrainingManager.Session.status.location;

    public render(): React.ReactElement {
            return (
                <div className="ProductMenuContainer">
                    <div className="ProductMenu">
                        <ul>
                            {this.location.products.map((product: TrainingProduct, i: number) => {
                                return (<UiProductMenuItem key={i + "_" + product.path} index={i} product={product} />);
                            })}
                        </ul>
                    </div>
                </div>
            );
        }
}
