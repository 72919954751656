import TrainingManager from "../../../Managers/TrainingManager";
import { TrainingStatus } from "../../../TrainingSession.props";
import { TrainingWindowSections } from "./UiTrainingWindow";

export default class PopupWindowState {
   public isVisible: boolean=false;
   public txtPopupIsVisible:boolean= false
   public posX: number=0;
   public posY: number=0;
   public title:string = "";
   public popupText:string = "";
   public pullQuote:string = "";
   public btnText:string = "OK";
   public btnDisabled:boolean = false;
   public actionId:any;
   public stepId:string;
   public fnComplete:any;
public actionList:{label:string,action:any}[];
public showReplayBtn:boolean = false;
public showContinueBtn:boolean = false;
public hideContinueBtn:boolean = false;
public showConfirmBtn:boolean = false;
public showAltBtn:boolean = false;
public altBtnText:string ="";
public altFn:any;
public altFnId:string;
public altBtnDisabled:boolean = false;
public neverReadText: boolean = false;
public textHasBeenRead: boolean = false;
public retainPopup:boolean= false;
public displayAsNavPanel: boolean= false;
public backBtnDisabled: boolean = true;
public nextBtnDisabled: boolean = true;
public totalSteps: number;
public currentStep: number;
public showCheckList: boolean = false;
public arrCheckList: string[];
public showQuizBtn: boolean = false;
}
