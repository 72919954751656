import {createRef, RefObject} from "react";
import UiOverlay from "../ReactComponents/UiOverlay";
import LocationRouter from "../LocationRouter";

import UiVideoOverlay from "../ReactComponents/UiComponents/TrainingComponents/UiVideoOverlay";
import UiPdfOverlay from "../ReactComponents/UiComponents/TrainingComponents/UiPdfOverlay";
import UiImageTextOverlay from "../ReactComponents/UiComponents/TrainingComponents/UiImageTextOverlay";

import LoadingScreen from "../ReactComponents/LoadingScreen";
import RefTest from "../ReactComponents/reftest";
import UiPopupWindow from "../ReactComponents/UiComponents/TrainingComponents/UiPopupWindow";
import UiToolStrip from "../ReactComponents/UiComponents/TrainingComponents/UiToolStrip";
import UiNotepad from "../ReactComponents/UiComponents/UiNotepad";
import UITextToSpeech from "../ReactComponents/UiComponents/UiTextToSpeech";
import TaskList from "../ReactComponents/UiComponents/TrainingComponents/UiTaskList";
import IframeFunctions from "../ReactComponents/UiComponents/iframe"
import UIData from "../Data/UIData"
import UIHeader from "../ReactComponents/UiComponents/UiHeader"
/**
 * This static class holds React component refs to all UI elements that need to be accessed from within Babylon's context.
 * This is the main way to update the UI from within the game loop.  Just call setState on the needed component refs.
 */
export default class UIManager {
  //Static component refs to go here.
  public static UiOverlayRef: RefObject<UiOverlay> = createRef<UiOverlay>();
  
  public static LoadingScreenRef: RefObject<LoadingScreen> = createRef<LoadingScreen>();
  public static TestRef: RefObject<RefTest> = createRef<RefTest>();
  public static LocationRouterRef: RefObject<LocationRouter> = createRef<LocationRouter>();

  public static UiPopupRef: RefObject<UiPopupWindow> = createRef<UiPopupWindow>();
  public static UiToolStripRef: RefObject<UiToolStrip> = createRef<UiToolStrip>();
  public static UiNotepadRef: RefObject<UiNotepad> = createRef<UiNotepad>();
  public static TaskListRef: RefObject<TaskList> = createRef<TaskList>();
  public static UiVideoOverlayRef: RefObject<UiVideoOverlay> = createRef<UiVideoOverlay>();
  public static UiPdfOverlayRef: RefObject<UiPdfOverlay> = createRef<UiPdfOverlay>();
  public static UiDataRef: RefObject<UIData> = createRef<UIData>();
  public static UiTTLRef: RefObject<UITextToSpeech> = createRef<UITextToSpeech>();
  public static UiHeaderRef: RefObject<UIHeader> = createRef<UIHeader>();
  public static UiImageTextOverlayRef: RefObject<UiImageTextOverlay> = createRef<UiImageTextOverlay>();
  public  IframeFunctions:IframeFunctions;
}
