const transResourcesEN ={
  "en": {
    "translation": {
      "intro":{
		"Welcome": "Welcome to the Knauf Training Academy",
      "Welcome-a": "Welcome to the",
      "Welcome-b": "Knauf Training Academy",
      "Subtitle":"Select from the residential or commercial environment to start training or visit the Knauf Product Showcase to see a detailed view of our products.",
    "StartButton":"Get Started" ,
	"StartWithoutAudio": "Continue Without Audio",
    "PopupTitle": "For the Best Experience",
    "PopupLang": "Select Language",
    "PopupAudio": "Turn Sound On",
    "PopupFullscreen": "View Fullscreen",
    "Footer-a":"We use cookies to enhance your experience and analyze our site usage. Please see our ",
    "FooterLink":"Privacy Policy",
    "Footer-b": " for more information."
    },
    "header":{
      "TTLang":"Select Language",
      "TTAudio-a":"Mute Audio",
      "TTAudio-b":"Enable Audio",
      "TTFullscreen-a":"Full Screen",
      "TTFullscreen-b":"Exit Full Screen",
"Lang-English":"English",
"Lang-Spanish":"Spanish"
    },
    "courses":{
      "ResidentialLabel": "Residential Training",
      "CommercialLabel": "Commercial & Industrial Training",
      "ProductsLabel": "Product Showcase",
      "CoursesLabel":"Training Courses",
      "MTitle-Batts":"Batt Installation",
      "MTitle-Attic":"Loose Fill Attic",
      "MTitle-NetBlow":"Net & Blow",
      "MTitle-PipeIns":"Pipe Insulation",
      "MTitle-DuctWrap":"Duct Wrap",
      "MTitle-DuctLiner":"Duct Liner",
      "MTitle-PipeAndTank":"Pipe & Tank",
      "Of": "of",
      "HotspotsComplete":"Hotspots Complete",
      "BtnStart-a":"Start Course",
      "BtnStart-b":"Continue Course",
      "BtnStart-c":"Review Course",
    },
    "buildings":{
"incompleteTitle":"Continue?",
"incompleteText":"Your last task was not completed. Would you like to continue where you left off?",
"continueBtn":"Continue",
"altBtn":"Cancel",
"taskCompleteTitle":"Task Complete",
"taskCompleteText1":"Congratulations! You've completed",
"taskClose":"Close",
    },
    "taskListData":{
"title1":"Eco-Batt Hands-on Installation",
"desc1":"description",
"title2":"Eco-Batt Installation Video",
"desc2":"description",
"title3":"Pipe Insulation Application",
"desc3":"description",
"title4":"Pipe Insulation Video",
"desc4":"description",
"quizTaskLabel":"Training Quiz",

    },
    "taskStepsData":{
      "pipe-insul-task":{
        "intro3":{
      "btn1":"Watch Now",
      "text":"Discover the steps for the correct approach to commercial pipe insulation.",
      "title":"Pipe Insulation Demo"
        },
        "pipeInsAnimation1":{
          "comment":"Lower the hangar and add load bearing insulation insert.",
          "commentTitle":"Lower Brace Insulation"
            }
      }
    },
      "NavButtonText.Default":"Return To Product Overview",
      "NavButtonText.Attic":"Go To Attic",
      "NavButtonText.LivingRoom":"Go To Living Room",
      "Quiz-Ecobatt":{
"title":"EcoBatt® Insulation",
"description":"Quality engineered thermal and acoustical fiberglass batt insulation with innovative ECOSE® technology is ideal for floors, walls, ceilings and attics.",
"Q1":{
  "title":"Pre-Install Storage",
  "question": "EcoBatt insulation packaging should be opened a minimum 24 hours before installation.",
"answers":{
"true":{"title":"False","response":"Correct!  Open the package only when ready to install. The insulation will recover and expand to the labeled thickness once the package is opened." },
"false":{"title":"True","response":"Oops!  Wrong answer.  Try again." },
}}

      },
    }
  },
   
  }

  export default transResourcesEN