import React, {Component, createRef, RefObject} from "react";
import "./UiToolStrip.css";
import "./UiPopupWindow.css";
import Configs from "../../../Config/Configs";
import ToolStripState from "./UiToolStrip.state";
import BabylonManager from "../../../Managers/BabylonManager";
import TrainingManager from "../../../Managers/TrainingManager";
import UIManager from "../../../Managers/UIManager";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
import { GetFillerText } from "../../utilities";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Grow, Slide } from "@mui/material";
import { StandardMaterial } from "babylonjs";


export default class UiToolStrip extends Component<unknown, ToolStripState> {

    public toolStripRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
    public toolboxRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();


private expandTools:boolean = false;
    constructor(props: any) {
        super(props);
        this.state = new ToolStripState();

      //  this.onOptionButtonClick = this.onOptionButtonClick.bind(this);
      this.onHandleMouseOut = this.onHandleMouseOut.bind(this);
      this.onHandleMouseOver = this.onHandleMouseOver.bind(this);
      this.onHandleDropTool = this.onHandleDropTool.bind(this);
      this.onHandlePickupTool = this.onHandlePickupTool.bind(this);
      this.onHandleToolHover = this.onHandleToolHover.bind(this);
    }

    componentDidLoad(): void {
       this.initToolStrip()
    }

    componentDidUpdate(): void {
        if(this.state.isDraggingTool === false){
            this.removeDragIcon()
             // this.onHandlePickupTool()
          }
    }
  
 public initToolStrip():void{

    let toolStrip = document.getElementsByClassName("ToolStripContainer")[0]
    toolStrip.addEventListener("mouseover",()=>{

        this.setState({isOverToolStrip:true})
    })
    toolStrip.addEventListener("mouseout",()=>{

        this.setState({isOverToolStrip:false})
    })

 }

 private expandToolStrip():void{
    this.setState({expandTools:true}) 
  
 }

 private hideToolStrip():void{
    this.setState({expandTools:false}) 
  
 }


 private onHandleMouseOver():void{
    this.setState({isOverToolStrip:true}) 
  
 }
 private onHandleMouseOut():void{
    this.setState({isOverToolStrip:false})
   
 }

 private onHandleToolHover():void{
    // console.log("toolhover")
     if(this.state.dragToolId){
         this.expandToolStrip()
     }
 }
 private onHandleDropTool():void{
//console.log("drop tool")
    let toolId = this.state.dragToolId;
    if(toolId && this.state.isDraggingTool === true){
let arrTools = this.state.toolIds;
arrTools.push(toolId)
this.setState({toolIds:arrTools, dragToolId:null, isDraggingTool:false});
this.removeDragIcon()
    }
 }
 
 public onHandleMove(x: number,y: number){
	let self = this;
	let body = document.getElementsByTagName("body")[0];
let toolIcon: HTMLImageElement = document.querySelector(".drag-tool-icon");
if(self.state.dragToolId){
	let toolId = self.state.dragToolId
	//self.followCursor(e,toolIcon)
	body.style.cursor = "grabbing"
	let offsetL:number = 0;
	let offsetT:number = 0;
 if(toolId === "tapemeasure"){
	offsetL = 40;
	offsetT = 60
 }else{
 //offsetL= 90;
 //offsetT = 25
 }
 
 if(toolId === "knife"){
	// offsetL = 109;
	 //offsetT = 48;
	 offsetL = 80;
	 offsetT = 10;
  }
 
 if(toolId === "utilityknife"){
	 offsetL = 110;
	 offsetT = 40;
  }
 
 if(toolId === "halfknife"){
	
	 offsetL = 3;
	 offsetT = 40;
	
  }
	toolIcon.style.left = (x - offsetL) + "px";
	toolIcon.style.top = (y - offsetT ) + "px";
}

 }
 public onHandlePickupTool(e:any,toolId:string):void{

    if(this.state.dragToolId === null ){
    let body = document.getElementsByTagName("body")[0];
let toolIcon = document.createElement("img")
toolIcon.style.opacity = "0";
toolIcon.src = Configs.ContentUrlWithTrailingSlash + "Images/tools/" + toolId + ".png"
toolIcon.className = "drag-tool-icon"
let mPosX = e.clientX;
let mPosY = e.clientY



toolIcon.style.left = mPosX + "px";
toolIcon.style.top = mPosY + "px";
body.style.cursor = "grabbing" //'url(Configs.ContentUrlWithTrailingSlash + "Images/tools/knife.png")'
body.appendChild(toolIcon);
//console.log("mouseevent", e)

this.setState({dragToolId:toolId, isDraggingTool:true})
toolIcon.style.opacity = "1";
toolIcon.classList.remove("hidden")
if(toolId === "tapemeasure"){
    toolIcon.style.width = "4vw"
}
if(toolId === "knife"){
    toolIcon.style.width = "7vw"
}

if(toolId === "utilityknife"){
    toolIcon.style.width = "6vw"
}
if(toolId === "halfknife"){
    toolIcon.style.width = "5vw";
    toolIcon.style.transform = "rotate(-25deg)"
}
let src: HTMLElement = document.querySelector("." + toolId);
if(src){
src.style.opacity = ".35";
}
let self = this;
let moveListener:any;
document.addEventListener("mousemove",function moveListener(e?:any){
 //   console.log("mousemove", e)
   if(self.state.dragToolId){
   //self.followCursor(e,toolIcon)
   body.style.cursor = "grabbing"
   let offsetL:number = 0;
   let offsetT:number = 0;
if(toolId === "tapemeasure"){
   offsetL = 40;
   offsetT = 60
}else{
//offsetL= 90;
//offsetT = 25
}

if(toolId === "knife"){
   // offsetL = 109;
    //offsetT = 48;
    offsetL = 80;
    offsetT = 10;
 }

if(toolId === "utilityknife"){
    offsetL = 110;
    offsetT = 40;
 }

if(toolId === "halfknife"){
   
    offsetL = 3;
    offsetT = 40;
   
 }
   toolIcon.style.left = (e.clientX - offsetL) + "px";
   toolIcon.style.top = (e.clientY - offsetT ) + "px";
   }else{
    toolIcon.removeEventListener("mousemove", moveListener);
    body.style.cursor = "default"
   }

},false)
    }

	
 }

public hideIcon(): void{
	let icon = document.querySelector(".drag-tool-icon");
    if(icon){
icon.classList.add("hidden")
	}
}
 
 public removeDragIcon():void{
    let icon = document.querySelector(".drag-tool-icon");
    if(icon){
        
let src: HTMLElement = document.querySelector("." + this.state.dragToolId);
if(src){
src.style.opacity = "1";
}
    icon.remove();
    this.setState({dragToolId:null, isDraggingTool:false})
    }
 }
    protected clearAnimations(): void {
        BabylonManager.MainContext.clearAllAnimations();
    }
public closeToolStrip():void{

}

    public render(): React.ReactElement {
let aList = this.state.actionList;

//console.log(this.state)
if(this.toolStripRef.current){
let posX = this.state.posX + "px";
let posY = this.state.posY + "px";

//this.toolStripRef.current.setAttribute('style',`left:${posX};top:${posY}`)
}
        return (
            <>
          
            <> {this.state.isVisible ?  
            
            <div className="ToolStripContainer"  onMouseOver={(e)=>{this.onHandleToolHover()}} onMouseOut={this.onHandleMouseOut} onMouseUp={this.onHandleDropTool}>
                 {this.state.expandTools === true ? 
                     <div className="btn-hide" onClick={()=>this.hideToolStrip()}><FontAwesomeIcon icon={faArrowLeft as IconProp} /></div>
               :
                <div className="btn-expand" onClick={()=>this.expandToolStrip()}><FontAwesomeIcon icon={faArrowRight as IconProp} /></div>
              
                }
                <div className="ToolStrip" ref={this.toolStripRef}>
                   
                     <>
                    <Stack direction="row" spacing={2} >
                   
     <div>        
  <FontAwesomeIcon icon={faScrewdriverWrench as IconProp} className="tools-icon"   />
 </div> 
 <div className="outer-tools"  ref={this.toolboxRef}>
  <Slide in={this.state.expandTools} direction="right" container={this.toolboxRef.current}> 
  <div className="inner-tools">
  
                   
                    {this.state.toolIds.map((item,idx)=>{
                        return <div className='tool-strip-icon' key={"key_" + idx}  onMouseUp={(e)=>{this.onHandlePickupTool(e,item)}}>
                        <img className={'tool ' + item}  src={Configs.ContentUrlWithTrailingSlash + "Images/tools/" + item + ".png"} />
                        </div>

                    })}
                 
                    </div>
                 </Slide> 
                 </div>
                   </Stack>
                   
                    </>
                   
                </div>
            </div>
             :
             <div>
                     </div>
               }
            </>
            <>
            {this.state.txtPopupIsVisible ?
            <div className="TxtPopupContainer">
                {GetFillerText()}
                </div>
            : <div></div>
    }
            </>
            </>
        );
    }
}
