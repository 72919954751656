import BabylonManager from "../../Managers/BabylonManager";
import UIManager from "../../Managers/UIManager";
import { GetRoomIdx } from "../utilities";

//const parentUrl = "https://iframe.knaufacademy.com"
const parentUrl = "https://elated-knuth-c53e2c.netlify.app"
const mode = "test"

type PathData={
  level:number,
  pathType:string,
  navType:string,
  activities:[] , 
}

 type IframeFunctions={
  init:any,
  parseData:any,
  savePath:any,
  handleComplete:any,
  handleSectionComplete:any,
  clearPathData:any,
  setDefaultPath:any,
  hasLoaded:any,
  toggleFullScreen:any,
  test:any,
 }
//const data = JSON.stringify({action:"goto",options:{navType:"room", location:"LivingRoom"}});
// let pathData = {level:1, pathType:"strict", navType:"none",
// activities:[
//   {building:"Residential",room:"Attic", id:"anim_1", actionType:"animationGroup", complete:false},
//   {building:"Residential",room:"Attic", id:"video_1", actionType:"video", complete:false},
//   {building:"Residential",room:"Attic", id:"quiz_1", actionType:"quiz", complete:false, sectionIdx:0},
//   {building:"Residential",room:"LivingRoom", id:"quiz_2", actionType:"quiz", complete:false, sectionIdx:0},
// ]}
let pathData = {level:1, pathType:"strict", navType:"none",
activities:[
    {building:"Commercial",room:"LivingRoom", id:"interaction1", actionType:"interaction", complete:false, sectionIdx:0},
]}
//localStorage.pathData=""
//localStorage.pathData = JSON.stringify(pathData)
const IframeFunctions:IframeFunctions={
init:():void=>{
    let self:any = IframeFunctions;
//self.parseData(data)
    window.addEventListener("message", (event) => {
     //   console.log("iframe event",event)
        if (event.origin === parentUrl || mode === "test"){
            try{


                if(event.data ){
                  self.parseData(event.data)
                }
                    
        
        
        // if(dataObj.action=== "resize"){
        
        // }
        
        // if(dataObj.action=== "init"){
        
        // }
                    
            }catch(err){
              //  console.log(err)
            }  
        }else{

          return;
           
        }
      
      }, false);
},
hasLoaded:(status:boolean):void=>{
parent.postMessage("contentLoaded", "*")

},
toggleFullScreen():void{
	parent.postMessage("toggleFullScreen", "*")
},
parseData:(evData: string):void=>{

   // console.log(evData)
    let data =evData;
    let dataObj = JSON.parse(data);

   // console.log(dataObj)



    if(dataObj.action=== "autoStart"){
      let options = dataObj.options
let room = options.room;
let actionId = options.actionId;
let roomIdx = GetRoomIdx(room)
if(roomIdx > -1){
console.log("go to room:" , roomIdx)
console.log(BabylonManager.MainContext)
console.log(pathData)
if(BabylonManager.MainContext && !options.pathData){
BabylonManager.MainContext.changeRoomState(roomIdx,options.hotSpotId, options.actionId)
}else{
    console.log("could not find room")
}
}
    }


if(dataObj.action=== "goto"){
    console.log(evData)
let options = dataObj.options
let navType= options.navType;
if(navType === "room"){
let strRoom = options.location
console.log(options)
let roomIdx = GetRoomIdx(strRoom)
if(roomIdx > -1){
console.log("go to room:" , roomIdx)
if(BabylonManager.MainContext && !pathData){
BabylonManager.MainContext.changeRoomState(roomIdx)
}else{
    console.log("could not find room")
}
}
}

if(navType === "course"){
	let courseId: number = 0 // GetCourseId(options.location)
	if(options.location){
	courseId =parseInt( options.location)
	}
	if(courseId >-1 && courseId < 3){
		sessionStorage.building = 2
	}
	if(courseId >=3 && courseId < 6){
		sessionStorage.building = 3
	}

	let strCourse = courseId.toString()
UIManager.UiOverlayRef.current.setState({ShowTrainingHub:false,ShowSafetyTools:true, CourseId:strCourse, ShowReturnButton:true,})

}


}

},
test:():void=>{
	// const hsData=UIManager.UiDataRef.current.hsData
	// console.log(hsData)
	sessionStorage.building = 2 //3;
	let courseId = '0' // GetCourseId(options.location)
	
UIManager.UiOverlayRef.current.setState({ShowIntro:false, ShowTrainingHub:false,ShowSafetyTools:true, CourseId:courseId, ShowReturnButton:true,})

},
 savePath:(data:PathData,checkNextAction?:boolean):void=>{
  localStorage.pathData = JSON.stringify(data);
 // const Residential = buildings[Buildings.Residential] as ResidentialHouse;
 //Residential.savePathData(checkNextAction)
},
setDefaultPath:():void=>{
  localStorage.pathData = JSON.stringify(pathData);

},

 handleComplete:(actionId:string):void=>{
 // const Residential = this.buildings[Buildings.Residential] as ResidentialHouse;
 //Residential.handleActionComplete(actionId)
},

 handleSectionComplete:(actionId:string, sectionIdx:number):void=>{
 // const Residential = this.buildings[Buildings.Residential] as ResidentialHouse;
// Residential.handleSectionComplete(actionId, sectionIdx)
},
clearPathData:():void=>{
  console.log(localStorage.pathData)
localStorage.pathData = JSON.stringify(pathData)

console.log(localStorage.pathData)
alert("Progress Data has been cleared");
window.location.reload()
}
}

export default IframeFunctions
