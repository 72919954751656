import { Tools, Vector3 } from "@babylonjs/core";
import { assets } from "../../assetMap";
import { ImgTextData, TaskListItem } from "../dataSetTypes";

let meshNames = {
    toolHub: "toolsetHub",
    utilityKnife: "knife_Tools_0",
    battKnife: "battKnife",
    tapeMeasure: "roulette_Tools_0",
    tapeMeasureBlue: "tapeMeasureBlue",
    r15Pkg: "r15box",
    r15Wrapper: "insulation_wrapping.002",
    //r15Pkg:"battPkgr15",
    cuttingBatt: "batt2",
};
let iconNames = {
    battKnife: "knife",
    tapeMeasure: "tapemeasure",
    utilityKnife: "utilityknife",
};

export let toolSetEcobattInstall = {
    parentMeshName: meshNames.toolHub,
    position: new Vector3(0.54, 0.034, -10.3),
    rotation: new Vector3(0, Tools.ToRadians(332), 0),
    onFloor: true,
    toolItems: [
        {
            name: meshNames.battKnife,
            hasHitbox: false,
            iconName: iconNames.battKnife,
            isCorrect: true,
            position: new Vector3(0.5, 0.01, 0.1),
            scaling: new Vector3(1.3, 1.3, 1.3),
        },
        {
            name: meshNames.r15Pkg,
            meshName: meshNames.r15Wrapper,
            hasHitbox: false,
            isNode: true,
            isCorrect: false,
            position: new Vector3(0.068, 0.01, -0.418),
            rotation: new Vector3(0, Tools.ToRadians(320), 0),
        },
        {
            name: meshNames.tapeMeasure,
            hasHitbox: false,
            iconName: iconNames.tapeMeasure,
            isCorrect: false,
            position: new Vector3(0.6, 0.025, -0.1),
            scaling: new Vector3(1.3, 1.3, 1.3),
            rotation: new Vector3(0, Tools.ToRadians(45), 0),
        },
        {
            name: meshNames.utilityKnife,
            hasHitbox: false,
            iconName: iconNames.utilityKnife,
            isCorrect: false,
            position: new Vector3(0.649, 0.01, -0.318),
            scaling: new Vector3(1.3, 1.3, 1.3),
            rotation: new Vector3(Tools.ToRadians(271), 0, 0),
        },
    ],
};

export let measureParams = [
    { name: meshNames.battKnife, hasHitbox: false, isCorrect: false },
    { name: meshNames.r15Pkg, hasHitbox: false, isCorrect: true },
    {
        name: meshNames.tapeMeasure,
        hasHitbox: true,
        iconName: iconNames.tapeMeasure,
        isCorrect: false,
    },
];

const correctIncorrect1: ImgTextData[] = [
    {
        heading: "What is a Grade 1 Installation?",
        //imageSet:[
        //{imageFileName:assets.fns.getAssetPath("correctIncorrect1", "courseAssets","battInstall")},
        imageFileName: assets.fns.getAssetPath(
            "correctIncorrect2",
            "courseAssets",
            "battInstall"
        ),
        strHtml:
            "Grade 1 Insulation is installed according to the manufacturer instructions and/or industry standards. A Grade I installation requires that the insulation material uniformly fills each cavity side-to-side and top-to-bottom, without substantial gaps or voids around obstructions (such as blocking or bridging), and is split, installed, and/or fitted tightly around wiring and other services in the cavity. Follow this Grade 1 Installation Guide to learn more.",
        //strHtml:"Wall insulation should be touching all sides of the wall cavity and fill it completely. This includes completely filling around wires and pipes, cutting batts to fit around outlet boxes and other services, and cutting batts to fit around blocking and non-standard cavities",
        arrLinks: [
            {
                linkText: "Grade 1 Insulation Guide",
                linkUrl:
                    "https://drive.google.com/file/d/13rO-9f0ls8GEWOIvMPAX2dJeY6L5H6bR/view",
            },
            {
                linkText: "Insulation Preparation",
                linkUrl:
                    "https://insulationinstitute.org/im-a-building-or-facility-professional/residential/installation-guidance-2/installation-preparation-2/",
            },
        ],
    },
    {
        heading: "Benefits of a Grade 1 Installation",
        imageFileName: assets.fns.getAssetPath(
            "correctIncorrect3",
            "courseAssets",
            "battInstall"
        ),
        strHtml:
            "By reducing energy usage, fiberglass insulation allows your heating and cooling systems to operate with greater efficiency, which decreases energy use, may reduce your energy bills and limits the release of greenhouse gases into the atmosphere. Fiberglass insulation helps you — and all of us — do more while consuming fewer resources.",
        //linkText:"Residential Insulation: Preparing for Your Installation", linkUrl:"https://www.knaufnorthamerica.com/en-ca/install",
        arrLinks: [
            {
                linkText: "How To Install Knauf Insulation",
                linkUrl: "https://www.knaufnorthamerica.com/en-ca/install",
            },
        ],
    },
];

const rvalue1: ImgTextData[] = [
    {
        heading: "What is R-Value",
        imageFileName: "Images/batt_install_tasks/correct_incorrect2.jpg",
        imageSet: [
            {
                imageFileName: assets.fns.getAssetPath(
                    "climateChart1",
                    "courseAssets",
                    "battInstall"
                ),
            },
            {
                imageFileName: assets.fns.getAssetPath(
                    "zoneChart1",
                    "courseAssets",
                    "battInstall"
                ),
            },
        ],
        strHtml:
            "Buildings located in different areas of the country may require different levels of insulation. For quick reference, check the recommended R-values from the Department of Energy. Local requirements may differ from the DOE map, so be sure to consult your local building code or code official.",
        linkText: "Residential Insulation: Preparing for Your Installation",
        linkUrl: "https://www.knaufnorthamerica.com/en-ca/install",
        arrLinks: [
            {
                linkText: "Insulation R-Value",
                linkUrl: "https://www.knaufnorthamerica.com/en-us/r-value",
            },
        ],
    },
];

const thermal1: ImgTextData[] = [
    {
        heading: "Thermal Imaging",
        imageFileName: assets.fns.getAssetPath(
            "thermalImaging1",
            "courseAssets",
            "battInstall"
        ),
        strHtml:
            "Even though insulation is rarely seen by the occupant, it's proper, or improper, installation will affect them for the life of the home. This section provides a visual of what good vs bad installation can actually look like once the home is finished.",
        arrLinks: [],
    },
];

export let hotspotsBattInstall = [
    {
        id: "batts-install-video",
        parentId: "batts-how-to-install",
        task: "viewBattsHowToVideo",
        priority: 0,
        inst: "",
        type: "video",
        fnId: "",
        params: {
            autoAdvance: true,
            src: "external",
            videoHeading: "How To Install",
            url: "https://www.youtube.com/watch?v=O5-pgcNUY1g",
            videoText: "",
        },
        active: false,
        complete: false,
    },

    {
        id: "batt-tips-video",
        parentId: "batts-tips-tricks",
        task: "viewBattsTipsVideo",
        priority: 0,
        inst: "",
        type: "video",
        fnId: "",
        params: {
            autoAdvance: true,
            src: "external",
            videoHeading: "Tips & Tricks",
            url: "https://www.youtube.com/watch?v=s10k52dtKE8",
            videoText: "",
        },
        active: false,
        complete: false,
    },

    {
        id: "sound-absorb-video",
        parentId: "sound-absorb",
        task: "viewSoundVideo",
        priority: 0,
        inst: "",
        type: "video",
        fnId: "",
        params: {
            autoAdvance: true,
            src: "external",
            videoHeading: "Insulation for Noise Reduction",
            url: "https://www.youtube.com/watch?v=BI_y6bRBk5Q",
            videoText:
                "Designing structures to control noise effectively - both outdoor to indoor, and room to room – can make homes and offices much more pleasant spaces to live and work. ",
            links: [
                //{linkText:"Insulating Your Home Office for Sound Control",},
                {
                    linkText:
                        "Acoustical Performance of Wall Cavity Insulation",
                    linkUrl:
                        "http://www.usg.com/content/dam/USG_Marketing_Communications/united_states/product_promotional_materials/finished_assets/acoustical-assemblies-en-SA200.pdf",
                },
            ],
        },
        active: false,
        complete: false,
    },

    {
        id: "img-text-rvalue",
        parentId: "rvalueguide",
        task: "viewrvalue",
        priority: 0,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: { autoAdvance: true, panelData: rvalue1 },
        active: false,
        complete: false,
    },

    {
        id: "img-text1",
        parentId: "correct-incorrect",
        task: "viewCorrectIncorrect",
        priority: 0,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: { autoAdvance: true, panelData: correctIncorrect1 },
        active: false,
        complete: false,
    },

    {
        id: "video2Install",
        parentId: "thermal-vis",
        task: "viewThermalVideo",
        priority: 0,
        inst: "",
        type: "imagetext",
        fnId: "",
        params: { panelData: thermal1 },
        active: false,
        complete: false,
    },
];

export let interactiveBattInstall = [
    {
        id: "intro-install",
        parentId: "ecobatt-hands-on",
        task: "onEcobattInstall2",
        priority: 0,
        inst: "",
        type: "popup",
        fnId: "",
        params: {
            text: "Welcome to EcoBatt® Insulation Training.<br><br> Here you will learn all of the steps required for ensuring correct batting insulation through this interactive training.",
            title: "ECOBATT® INTERACTIVE INSTALLATION",
            xPos: 150,
            yPos: 175,
            btnText: "START TRAINING",
        },
        active: false,
        complete: false,
    },

    {
        id: "chklist-install",
        parentId: "ecobatt-hands-on",
        task: "onEcobattInstall2",
        priority: 1,
        inst: "",
        type: "checklist",
        fnId: "",
        params: {
            text: "Check off all the items needed to prepare the job.",
            checklist: [
                "Sweeping",
                "Air Sealing",
                "Site Prep",
                "Chases Capped Off",
                "Knee Walls Have Rigid Backing",
                "Extra Blocking",
                "Air Barrier Requirements",
            ],
            title: "Prepare the Job Site",
            xPos: 150,
            yPos: 175,
            btnText: "START",
        },
        active: false,
        complete: false,
    },

    {
        id: "int-install-measure2",
        parentId: "ecobatt-hands-on",
        task: "onEcobattInstall2",
        priority: 2,
        inst: "",
        type: "triggered-measure",
        fnId: "setupMeasure",
        params: {
            autoAdvance: true,
            xPos: 150,
            yPos: 175,
            btnText: "Continue...",
            scaling: new Vector3(1, 0.05, 1),
            target0PosX: 2.65,
            target0PosY: 2.69,
            target0PosZ: -11.894,
            target1PosX: 2.65,
            target1PosY: 1.402,
            target1PosZ: -11.894,
            focalPointOptions: {
                name: "focalPt",
                pos: new Vector3(2.661, 1.401, -11.82),
                radius: 1,
                arc: 1,
                scale: new Vector3(0.25, 0.08, 1),
            },
            zoomOptions: {
                id: "zoomMeasure1",
                target: new Vector3(3.0, 1.25, -11.89),
                rotation: new Vector3(
                    Tools.ToRadians(-11.31),
                    Tools.ToRadians(170.4),
                    Tools.ToRadians(0)
                ),
                fov: 0.6,
                eventTrigger: "correct",
            },
            targetRotAxis: "x",
            measureAxis: "y",
            actualDrawAxis: "y",
            correctToolName: meshNames.tapeMeasure,
            toolParams: measureParams,
            mSurfacePosX: 1.8,
            mSurfacePosY: 1.4,
            mSurfacePosZ: -11.87,
            mSurfaceRotX: 0,
            mSurfaceRotY: 90,
            mSurfaceRotZ: 0,
            comment:
                "Pick a tool and measure the highlighted area from bottom to top. Drag the tape measure over the bottom edge of the highlighted area to begin measuring and then release it when you are finished.",
            retainPopup: true,
            commentTitle: "Measure the Installation Area",
            interactionComplete: false,
        },
        active: false,
        complete: false,
    },

    {
        id: "int-open-pkg",
        parentId: "ecobatt-hands-on",
        task: "onEcobattInstall2",
        priority: 4,
        inst: "",
        type: "triggered-animation",
        fnId: "",
        params: {
            autoAdvance: true,
            destroyOnInit: false,
            animation: "r15PkgAnimation",
            animId: "r15PkgAnimation",
            postAnimationId: "setupCreateBatt",
            animateToolParams: {
                toolId: meshNames.utilityKnife,
                cloneInToolset: true,
                startX: -0.176,
                startY: 0.474,
                startZ: 0.132,
                endX: 0.627,
                endY: 0.474,
                endZ: -0.883,
                toolRotX: 0,
                toolRotY: 57.7,
                toolRotZ: 374,
            },
            startAnim: 4.31,
            endAnim: 8.98,
            correctToolName: meshNames.utilityKnife,
            targetName: meshNames.r15Pkg,
            xPos: 150,
            yPos: 175,
            btnText: "Continue...",
            retainPopup: true,
            comment:
                "Pick a tool and drop it on the insulation package to open it.",
            commentTitle: "Open the EcoBatt package",
        },
        active: false,
        complete: false,
    },

    {
        id: "int-install-cut2",
        parentId: "ecobatt-hands-on",
        task: "onEcobattInstall2",
        priority: 6,
        inst: "",
        type: "cut-mesh",
        fnId: "",
        params: {
            xPos: 150,
            yPos: 175,
            btnText: "Start Cut",
            correctToolName: meshNames.battKnife,
            targetName: meshNames.cuttingBatt,
            forceTarget: true,
            comment:
                "Pick a tool and cut the batt to size. Use the horizontal cutting line you measured to make the batt the correct height.",
            commentTitle: "Cut the Batt",
            interactionComplete: false,
        },
        active: false,
        complete: false,
    },

    {
        id: "int-install-placebatt",
        parentId: "ecobatt-hands-on",
        task: "onEcobattInstall2",
        priority: 7,
        inst: "",
        type: "interaction",
        fnId: "setupPlaceBatt",
        params: {
            xPos: 150,
            yPos: 175,
            btnText: "",
            comment:
                "Click on the batt to push it into the cavity.<br/><br/>Ensuring contact on all 6 sides (top, bottom, side to side, front, and back) is the key success factor when installing Batt.",
            commentTitle: "Place the Batt",
            interactionComplete: false,
        },
        active: false,
        complete: false,
    },

    {
        id: "int-install-splitbatt",
        parentId: "ecobatt-hands-on",
        task: "onEcobattInstall2",
        priority: 8,
        inst: "",
        type: "animation",
        fnId: "",
        params: {
            xPos: 150,
            yPos: 175,
            btnText: "",
            autoplay: true,
            autoAdvance: false,
            animationList: [
                {
                    animType: "standard",
                    animationName: "splittingAnimation",
                    enabledNode: "TN_ANIM-BattsPanel_Split&Cut",
                    startFrame: 0,
                    endFrame: 28,
                    speed: 1,
                },
            ],
            comment:
                "When wiring or piping is present in the cavity, split the batt so that the wire or pipe is covered on both sides by the batt. Assure insulation is behind the outlet box.",
            commentTitle: "Split Around Wiring",
            interactionComplete: false,
        },
        active: false,
        complete: false,
    },

    {
        id: "ecobatt-hands-on-end",
        parentId: "ecobatt-hands-on",
        task: "onEcobattInstall2",
        priority: 9,
        inst: "",
        type: "popup",
        fnId: "",
        params: {
            autoAdvance: false,
            text: "<b>Congrats! You have successfully completed the<br />EcoBatt® Interactive Installation Training.</b><br /><br />You can review the training steps in the <a href='https://insulationinstitute.org/wp-content/uploads/2016/03/NAIMA-Tech-Tips-and-Critical-Details-FINAL.pdf' target='_blank'>official installation guide</a> for additional information.",
            title: "CONGRATULATIONS!",
            xPos: 150,
            yPos: 175,
            btnText: "Exit Hotspot",
            navOptions: { displayPopupAsNavPanel: false },
        },
        active: false,
        complete: false,
    },
];

export let tasksBattInstall: TaskListItem[] = [
    {
        id: "ecobatt-hands-on",
        courseId: 0,
        title: "EcoBatt Hands-On Installation",
        desc: "",
        active: false,
        complete: false,
        xPos: 1.9,
        yPos: 1.25,
        zPos: -10.4,
        hoverMesh: null,
        tools: toolSetEcobattInstall,
        destroyOnInit: false,
        taskFn: "onEcobattInstall2",
        highlightOptions: {
            name: "cutHilite",
            size: new Vector3(0.36, 1.26, 0.01),
            position: new Vector3(2.661, 2.042, -11.89),
            rotation: new Vector3(0, 0, Tools.ToRadians(180)),
            mat: "hiliteMat",
        },
        navOptions: { displayPopupAsNavPanel: true },
        //cameraOptions:{xPos:1.8,yPos:1.7,zPos:-7.61,FOV:1, targetMesh:null,
        cameraOptions: {
            xPos: 2.339,
            yPos: 1.552,
            zPos: -7.61,
            FOV: 1,
            targetMesh: null,
            //rotationX:Tools.ToRadians(10),rotationY:Tools.ToRadians(175), rotationZ:0,
            rotationX: Tools.ToRadians(9.553),
            rotationY: Tools.ToRadians(178.2),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
    {
        id: "thermal-vis",
        courseId: 0,
        title: "Thermal Visualizer",
        desc: "",
        active: false,
        complete: false,
        xPos: -0.33,
        yPos: 1.5,
        zPos: -10,
        hoverMesh: null,
        taskFn: "viewThermalVideo",
        cameraOptions: {
            xPos: 0.8,
            yPos: 1.16,
            zPos: -7.61,
            FOV: 1,
            targetMesh: null,
            rotationX: Tools.ToRadians(10),
            rotationY: Tools.ToRadians(175),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },

    {
        id: "correct-incorrect",
        courseId: 0,
        title: "Correct vs Incorrect Installation",
        desc: "",
        active: false,
        complete: false,
        xPos: 0.55,
        yPos: 1.5,
        zPos: -5.68,
        taskFn: "viewCorrectIncorrect",
        cameraOptions: {
            xPos: 0.8,
            yPos: 1.16,
            zPos: -7.61,
            FOV: 1,
            rotationX: Tools.ToRadians(6.03),
            rotationY: Tools.ToRadians(3.99),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
    {
        id: "rvalueguide",
        courseId: 0,
        title: "DOE Recommended R-Values",
        desc: "",
        active: false,
        complete: false,
        xPos: -0.15,
        yPos: 1.5,
        zPos: -6.96,
        taskFn: "viewrvalue",
        cameraOptions: {
            xPos: 0.8,
            yPos: 1.16,
            zPos: -7.61,
            FOV: 1,
            rotationX: Tools.ToRadians(-0.08),
            rotationY: Tools.ToRadians(278),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
    {
        id: "sound-absorb",
        courseId: 0,
        title: "Sound Absorption",
        desc: "",
        active: false,
        complete: false,
        xPos: 2.45,
        yPos: 1.5,
        zPos: -7.68,
        hoverMesh: null,
        taskFn: "viewSoundVideo",
        cameraOptions: {
            xPos: 0.8,
            yPos: 1.16,
            zPos: -7.6,
            FOV: 1,
            targetMesh: null,
            rotationX: Tools.ToRadians(-2.27),
            rotationY: Tools.ToRadians(90),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
    {
        id: "batts-how-to-install",
        courseId: 0,
        title: "How To Install",
        desc: "",
        active: false,
        complete: false,
        xPos: 2.501,
        yPos: 1.564,
        zPos: -5.978,
        hoverMesh: null,
        taskFn: "viewBattsHowToVideo",
        cameraOptions: {
            xPos: 1.75,
            yPos: 1.16,
            zPos: -7.0,
            FOV: 1,
            targetMesh: null,
            rotationX: Tools.ToRadians(-2.27),
            rotationY: Tools.ToRadians(90),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
    {
        id: "batts-tips-tricks",
        courseId: 0,
        title: "Tips & Tricks",
        desc: "",
        active: false,
        complete: false,
        xPos: -0.843,
        yPos: 1.395,
        zPos: -8.184,
        hoverMesh: null,
        taskFn: "viewBattsTipsVideo",
        cameraOptions: {
            xPos: 0.8,
            yPos: 1.16,
            zPos: -7.61,
            FOV: 1,
            targetMesh: null,
            rotationX: Tools.ToRadians(-0.08),
            rotationY: Tools.ToRadians(278),
            rotationZ: 0,
            rotationEnabled: false,
            navEnabled: false,
            zoomEnabled: false,
        },
    },
];
