
export default class TaskListState {
    public taskData:any[];
    public isVisible: boolean=true;
    public expanded: boolean=true;
   public complete:boolean;
   public quizTitle:string = "Training Quiz";
   public readyForQuiz:boolean = false;
   public totalCompleted:number = 0;
 }
 