import {Tools, Vector3 } from "@babylonjs/core";
import { assets } from "../../assetMap";
import {ImgTextData, TaskListItem} from "../dataSetTypes"

let meshNames ={
	toolHub:"toolsetHub",
	tapeMeasureBlue:"tapeMeasureBlue",
}

let iconNames ={	
	tapeMeasure:"tapemeasure",	
	utilityKnife:"utilityknife"
}

const bestPractices1 : ImgTextData[] = [{
  heading: "Best Practices",
  imageFileName:assets.fns.getAssetPath("bestP", "courseAssets","pipeInsulation").toString(),
  strHtml: 'The cheapest energy is the energy you do not use. Implementing a comprehensive mechanical or industrial insulation installation could lead to significant energy savings in the short run and a reduction in maintenance costs in the long run.',
  arrLinks: [],
}]

const bestPractices2 : ImgTextData[] = [{
  heading: "Hot / Cold Piping",
  imageFileName:assets.fns.getAssetPath("hotColdPipe", "courseAssets","pipeInsulation").toString(),
  strHtml: 'Did you know that the majority of hot and cold piping systems are required to be insulated on both commercial and industrial facilites in the United States? ',
  arrLinks: [],
}]

const specialCases1: ImgTextData[] = [{
  heading: "Special Cases",
  imageFileName:assets.fns.getAssetPath("digOut", "courseAssets","pipeInsulation").toString(),
  strHtml: "Tip when digging out: The best knife to use is a <b>razor knife</b> to dig out insulation to avoid puncturing exterior jacketing.",
  arrLinks: [],
}]

export let pipeMeasureTools = {
	parentMeshName:meshNames.toolHub,
	position: new Vector3(8, 5.003, -2.6),
  rotation: new Vector3(0,Tools.ToRadians(-90),0),
	onFloor:true,
	toolItems:[
		{name: meshNames.tapeMeasureBlue, hasHitbox:true,iconName:iconNames.tapeMeasure, isCorrect:false,position: new Vector3(0,.01,0), scaling: new Vector3(.5,.5,.5),rotation: new Vector3(Tools.ToRadians(0),Tools.ToRadians(0),Tools.ToRadians(0))}
	]
}
export let pipeMeasureTools2 = {
	parentMeshName:meshNames.toolHub,
	position: new Vector3(8, 5.003, -2.482),
  rotation: new Vector3(0,0,Tools.ToRadians(180)),
	onFloor:true,
	toolItems:[
		{name: meshNames.tapeMeasureBlue, hasHitbox:true,iconName:iconNames.tapeMeasure, isCorrect:false,position: new Vector3(0,.01,0), scaling: new Vector3(.5,.5,.5),rotation: new Vector3(Tools.ToRadians(0),Tools.ToRadians(0),Tools.ToRadians(0))}
	]
}

export let hotspotsPipeInsulation = [
  {
    id: "specialCases",
    parentId: "special-cases",
    task: "viewPipeSpecialCases",
    priority: 0,
    inst: "",
    type: "imagetext",
    fnId: "",
    params: {
      panelData: specialCases1
    },
    active: false,
    complete: false,
  },
  {
    id: "bestPractices",
    parentId: "best-practices",
    task: "viewBestPractices",
    priority: 0,
    inst: "",
    type: "imagetext",
    fnId: "",
    params: {
      panelData: bestPractices1
    },
    active: false,
    complete: false,
  },
  {
    id: "hotCold",
    parentId: "hot-cold",
    task: "viewHotCold",
    priority: 0,
    inst: "",
    type: "imagetext",
    fnId: "",
    params: {
      panelData: bestPractices2
    },
    active: false,
    complete: false,
  }
]

export let interactivePipeInsulation = [
  {
    id: "step-1-install-pipe",
    parentId: "pipe-insulation",
    task: "viewPipeInsulationNew",
    priority: 1,
    inst: "",
    type: "popup",
    fnId: "",
    params: {
      // autoAdvance: false,
      text: "Welcome to Pipe Insulation Training.<br /><br />Here you will learn all of the steps required for installing the Earthwool® 1000° Pipe Insulation and ensuring correct pipe covering.<br /><br />You will see some of the most common pipe installation methods used when insulating copper, stainless and or black iron piping and equipment. Keep in mind that geographical areas play a role, but generally speaking, what you see here is always a good practice.<br /><br />As always, feel free to contact any of the Knauf Academy Team members for any questions or advice on systematic best practices for both commercial and industrial product applications.",
      title: "PIPE INSULATION INTERACTIVE INSTALLATION",
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Start Training",
      navOptions: { displayPopupAsNavPanel: false },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-2-install-pipe",
    parentId: "pipe-insulation",
    task: "viewPipeInsulationNew",
    priority: 2,
    inst: "",
    type: "checklist",
    fnId: "",
    params: {
      text: "Before installation, make sure you have completed the jobsite preparation.",
      checklist: ['Use of a long sleeved shirt is strongly recommended'],
      title: "PIPE INSULATION INTERACTIVE INSTALLATION",
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      navOptions: { displayPopupAsNavPanel: false },
    },
    
    active: false,
    complete: false,
},
    {
    id: "step-3-install-pipe",
    parentId: "pipe-insulation",
    task: "viewPipeInsulationNew",
    priority: 3,
    inst: "",
    type: "simple-measure",
    fnId: "",

    params: {
        tools: pipeMeasureTools,
        measureAxis:"y",
        actualDrawAxis:"y",
        dragAxis:"y",
        measureTargetPoint: new Vector3(10.5, 10.10, -6),
        xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
        yPos: 175,
        btnText: "Next",
        navOptions: { displayPopupAsNavPanel: false },
        comment: "Select and drag the measuring tape to the bottom edge of the pipe to measure the outer diameter.",
        commentTitle: "MEASURE PIPE OUTER DIAMETER",
        retainPopup: true,
        zoomOptions: {
          name: "zoomPipeMeasure",
          pos: new Vector3(6, 5.1, -2.6),
          fov: 0.3,
        },


    },
    active: false,
    complete: false,
  },
  {
    id: "step-measure1-install-pipe",
    parentId: "pipe-insulation",
    task: "viewPipeInsulationNew",
    priority: 3.5,
    inst: "",
    type: "popup",
    fnId: "",

    params: {
        xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
        yPos: 175,
        btnText: "Next",
        navOptions: { displayPopupAsNavPanel: false },
        text: "You have measured <span id='measure-in-step'>measurementVar inches.</span> Click next to continue",
        title: "MEASURE PIPE OUTER DIAMETER",
        zoomOptions: {
          name: "zoomPipeMeasure",
          pos: new Vector3(6, 5.1, -2.6),
          fov: 0.3,
        },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-4-install-pipe",
    parentId: "pipe-insulation",
    task: "viewPipeInsulationNew",
    priority: 4,
    inst: "",
    type: "popup",
    fnId: "",
    params: {
      autoAdvance: false,
      text: "Insulation thickness is dependent on your needed application. For this installation, you will be installing on 2\" copper piping.<br /><br />Follow your local code requirements for the proper thickness to install.",
      title: "IDENTIFY CORRECT PIPE COVERING THICKNESS",
      xPos: 50,
      yPos: 175,
      btnText: "Next",
      // navOptions: { displayPopupAsNavPanel: true },
      zoomOptions: {
        name: "zoomPipeBox",
        target: new Vector3(6.5, 1.4, -0.5),
        pos: new Vector3(6.6, 2, -1.75),
        rotation: new Vector3(
          Tools.ToRadians(25),
          Tools.ToRadians(-360),
          Tools.ToRadians(0)
        ),
        fov: 1,
      },
    },
    active: false,
    complete: false,
  },
  {
      id: "step-5-install-pipe",
      parentId: "pipe-insulation",
      task: "viewPipeInsulationNew",
      priority: 5,
      inst: "",
      type: "clicked-animation",
      fnId: "",
      params: {
          autoAdvance: false,
          destroyOnInit: false,
          // 
          animation: "ANIM-PipeInsulationPackageOpening",
          animId: "ANIM-PipeInsulationPackageOpening",
          // postAnimationId: "step-6-install-pipe",
          
          zoomOptions: {
            name: "zoomPipeBox2",
            target: new Vector3(6.5, 1.5, -0.5),
            pos: new Vector3(6.6, 2, -2.4),
            rotation: new Vector3(
              Tools.ToRadians(13),
              Tools.ToRadians(-360),
              Tools.ToRadians(0)
            ),
            fov: 0.8,
          },
          startAnim: 0,
          endAnim: 7,
          correctToolName: "PipeCovering_UtilityKnife",
          targetName: "PipeCovering_UtilityKnife",
          // navOptions: { displayPopupAsNavPanel: false, hideContinueBtn: false },
          comment: "The Earthwool® 1000° Pipe Insulation packaging is designed to be ripped open. Select the hands to open the package.",
          commentTitle: "Open Insulation packaging",
          retainPopup: true,
      },
      active: false,
      complete: false,
  },
  {
      id: "step-6-install-pipe",
      parentId: "pipe-insulation",
      task: "viewPipeInsulationNew",
      priority: 6,
      inst: "",
      type: "clicked-animation",
      fnId: "",
      params: {
          autoAdvance: false,
          destroyOnInit: false,
          animation: "C&I-ANIM-PipeInsulation_Install",
          animId: "C&I-ANIM-PipeInsulation_Install",
          xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
          yPos: 175,
          btnText: "Next",
          startAnim: 0,
          endAnim: 3.55,
          animationName: "C&I-ANIM-PipeInsulation_Install",
          targetName: "ClevisHanger_Main",
          commentTitle: "Install load-bearing Insert",
          comment: "Press on the clevis hanger to lower it, allowing you to insert the load-bearing piece. <br /></br> Installing the insulation insert will give the proper piping support.",
          retainPopup: true,
      },
      active: false,
      complete: false,
  },
  {
    id: "step-7-install-pipe",
    parentId: "pipe-insulation",
    task: "viewPipeInsulationNew",
    priority: 7,
    inst: "",
    type: "simple-measure",
    fnId: "",

    params: {
        tools: pipeMeasureTools2,
        measureAxis:"z",
        actualDrawAxis:"z",
        dragAxis:"z",
        // drawAxis:"z",
        measureTargetPoint: new Vector3(10.5, 10.10, -6),
        xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
        yPos: 175,
        btnText: "Next",
        navOptions: { displayPopupAsNavPanel: false },
        comment: "Select and drag the measuring tape to the right edge of the pipe to determine the length of piping needed.",
        commentTitle: "Measure Length Needed",
        retainPopup: true,
        zoomOptions: {
          name: "zoomPipeMeasure2",
          pos: new Vector3(6, 5.1, -2.6),
          fov: 0.3,
        },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-measure2-install-pipe",
    parentId: "pipe-insulation",
    task: "viewPipeInsulationNew",
    priority: 7.5,
    inst: "",
    type: "popup",
    fnId: "",

    params: {
        xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
        yPos: 175,
        btnText: "Next",
        navOptions: { displayPopupAsNavPanel: false },
        text: "You have measured <span id='measure-in-step'>measurementVar inches.</span> Click next to continue",
        title: "Measure Length Needed",
        zoomOptions: {
          name: "zoomPipeMeasure2",
          pos: new Vector3(6, 5.1, -2.6),
          fov: 0.3,
        },
    },
    active: false,
    complete: false,
  },
  {
    id: "step-8-install-pipe",
    parentId: "pipe-insulation",
    task: "viewPipeInsulationNew",
    priority: 8,
    inst: "",
    type: "clicked-animation",
    fnId: "",
    params: {
      autoAdvance: false,
      destroyOnInit: false,
      animation: "C&I-ANIM-PipeInsulation_Install",
      animId: "C&I-ANIM-PipeInsulation_Install",
      xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
      yPos: 175,
      btnText: "Next",
      startAnim: 4.9,
      endAnim: 11.1,
      animationName: "C&I-ANIM-PipeInsulation_Install",
      targetName: "InsulationKnife",
      commentTitle: "Cut Pipe Covering to size",
      comment: "Press on the insulation knife to cut along the dashed line.<br /></br>The size of the cut is based on the measurements taken earlier.",
      retainPopup: true,
      navOptions: { displayPopupAsNavPanel: false },
      zoomOptions: {
        name: "zoomPipeMeasureEnd",
        pos: new Vector3(6, 5.25, -2.6),
        fov: 1,
      },
    },
    active: false,
    complete: false,
},
{
  id: "step-9-install-pipe",
  parentId: "pipe-insulation",
  task: "viewPipeInsulationNew",
  priority: 9,
  inst: "",
  type: "clicked-animation",
  fnId: "",
  params: {
    autoAdvance: false,
    destroyOnInit: false,
    animation: "C&I-ANIM-PipeInsulation_Install",
    animId: "C&I-ANIM-PipeInsulation_Install",
    xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
    yPos: 175,
    btnText: "Next",
    startAnim: 11.1,
    endAnim: 20,
    animationName: "C&I-ANIM-PipeInsulation_Install",
    targetName: "Tape_Flap_A_Cut",
    commentTitle: "Apply Covering to Pipe",
    comment: "Press on the pipe covering to fit it around the pipe and fold down seam laps.",
    retainPopup: true,
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
},
  {
  id: "step-10-install-pipe",
  parentId: "pipe-insulation",
  task: "viewPipeInsulationNew",
  priority: 10,
  inst: "",
  type: "clicked-animation",
  fnId: "",
  params: {
    autoAdvance: false,
    destroyOnInit: false,
    animation: "C&I-ANIM-PipeInsulation_Install",
    animId: "C&I-ANIM-PipeInsulation_Install",
    xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
    yPos: 175,
    btnText: "Next",
    startAnim: 20.5,
    endAnim: 25.57,
    animationName: "C&I-ANIM-PipeInsulation_Install",
    targetName: "Squeegee",
    commentTitle: "Rub Down Seam Laps",
    comment: "Press on the squeegee to rub down the longitudinal seams.",
    retainPopup: true,
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
  },
  {
  id: "step-11-install-pipe",
  parentId: "pipe-insulation",
  task: "viewPipeInsulationNew",
  priority: 11,
  inst: "",
  type: "clicked-animation",
  fnId: "",
  params: {
    autoAdvance: false,
    destroyOnInit: false,
    animation: "C&I-ANIM-PipeInsulation_Install",
    animId: "C&I-ANIM-PipeInsulation_Install",
    xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
    yPos: 175,
    btnText: "Next",
    startAnim: 25.8,
    endAnim: 29,
    animationName: "C&I-ANIM-PipeInsulation_Install",
    targetName: "SM_Tape_Wrap_A",
    commentTitle: "Seal Circumferential Seams",
    comment: "Press on the strip of tape to apply ASJ+ tape.",
    retainPopup: true,
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
  },
  {
  id: "step-12-install-pipe",
  parentId: "pipe-insulation",
  task: "viewPipeInsulationNew",
  priority: 12,
  inst: "",
  type: "clicked-animation",
  fnId: "",
  params: {
    autoAdvance: false,
    destroyOnInit: false,
    animation: "C&I-ANIM-PipeInsulation_Install",
    animId: "C&I-ANIM-PipeInsulation_Install",
    xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
    yPos: 175,
    btnText: "Next",
    startAnim: 30,
    endAnim: 41.2,
    animationName: "C&I-ANIM-PipeInsulation_Install",
    targetName: "Squeegee",
    commentTitle: "Rub Down Seams again",
    comment: "Press on the squeegee to rub down circumferential seams.",
    retainPopup: true,
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
  },
  {
  id: "step-13-install-pipe",
  parentId: "pipe-insulation",
  task: "viewPipeInsulationNew",
  priority: 13,
  inst: "",
  type: "clicked-animation",
  fnId: "",
  params: {
    autoAdvance: false,
    destroyOnInit: false,
    animation: "C&I-ANIM-PipeInsulation_Install",
    animId: "C&I-ANIM-PipeInsulation_Install",
    xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
    yPos: 175,
    btnText: "Next",
    startAnim: 43,
    endAnim: 47,
    animationName: "C&I-ANIM-PipeInsulation_Install",
    targetName: "MetalShield",
    commentTitle: "Install galvanized shield",
    comment: "Press on the galvanized shield to insert it beneath the clevis hanger.",
    retainPopup: true,
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
  },
  {
  id: "step-14-install-pipe",
  parentId: "pipe-insulation",
  task: "viewPipeInsulationNew",
  priority: 14,
  inst: "",
  type: "popup",
  fnId: "",
  params: {
    autoAdvance: false,
    text: "<b>Congrats! You have successfully completed the Pipe Insulation Training.</b>",
    title: "PIPE INSULATION INTERACTIVE INSTALLATION",
    xPos: (document.documentElement.clientWidth / 2) + (document.documentElement.clientWidth * .05),
    yPos: 175,
    btnText: "Exit Hotspot",
    navOptions: { displayPopupAsNavPanel: false },
  },
  active: false,
  complete: false,
  },
] 

export let  tasksPipeInsulation:TaskListItem[] = [
  {
    id: "pipe-insulation",
    courseId: 3,
    title: "Pipe Insulation",
    desc: "",
    active: false,
    complete: false,
    xPos: 8,
    yPos: 5.04,
    zPos: -2.75,
    hoverMesh: null,
    taskFn: "viewPipeInsulationNew",
    navOptions: { displayPopupAsNavPanel: true },
    cameraOptions: {
        xPos: 6,
        yPos: 5.25,
        zPos: -2.6,
        FOV: 1,
        targetMesh: null,
        rotationX: Tools.ToRadians(0),
        rotationY: Tools.ToRadians(-270),
        rotationZ: 0,
        rotationEnabled: false,
        navEnabled: false,
        zoomEnabled: false,
    },
  },
  {
    id: "special-cases",
    courseId: 3,
    title: "Special Cases",
    desc: "",
    active: false,
    complete: false,
    xPos: 7.4,
    yPos: 5.2,
    zPos: -3.63,
    hoverMesh: null,
    taskFn: "viewPipeSpecialCases",
    cameraOptions: {
      xPos: 6,
      yPos: 5.25,
      zPos: -2.6,
      FOV: 0.9,
      targetMesh: null,
      rotationX: Tools.ToRadians(0),
      rotationY: Tools.ToRadians(-260),
      rotationZ: 0,
      rotationEnabled: false,
      navEnabled: false,
      zoomEnabled: false
    }
  },
  {
    id: "best-practices",
    courseId: 3,
    title: "Best Practices",
    desc: "",
    active: false,
    complete: false,
    xPos: 4.4,
    yPos: 5.55,
    zPos: -4,
    hoverMesh: null,
    taskFn: "viewBestPractices",
    cameraOptions: {
      xPos: 6,
      yPos: 5.25,
      zPos: -2.6,
      FOV: 0.9,
      targetMesh: null,
      rotationX: Tools.ToRadians(0), //9.96
      rotationY: Tools.ToRadians(-138),
      rotationZ: 0,
      rotationEnabled: false,
      navEnabled: false,
      zoomEnabled: false
    }
    },
    {
      id: "hot-cold",
      courseId: 3,
      title: "Hot / Cold Piping",
      desc: "",
      active: false,
      complete: false,
      xPos: 8.400,
      yPos: 5.2,
      zPos: -0.200,
      hoverMesh: null,
      taskFn: "viewHotCold",
      cameraOptions: {
        xPos: 6,
        yPos: 5.25,
        zPos: -2.6,
        FOV: 0.9,
        targetMesh: null,
        rotationX: Tools.ToRadians(0), //9.96
        rotationY: Tools.ToRadians(-312),
        rotationZ: 0,
        rotationEnabled: false,
        navEnabled: false,
        zoomEnabled: false
      }  
    },
]