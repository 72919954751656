import React, {Component} from "react";
import UIManager from "./../../Managers/UIManager";
import BabylonManager from "./../../Managers/BabylonManager";
import TrainingManager from "../../Managers/TrainingManager";
import UiTrainingWindow,{TrainingWindowSections} from "./TrainingComponents/UiTrainingWindow";
import ResidentialHouseStates from "../../Babylon/Main/Rooms/ResidentialHouse/ResidentialHouseStates";
import { Scene } from "@babylonjs/core";
export default class UiTestMenu extends Component {

    constructor(props: any) {
        super(props);
    }
   
    onRemoveProducts():void{
console.log("remove Products")
        UIManager.UiOverlayRef.current?.setState({ShowProductMenu:false})
    }

    onGoToLocation(location:ResidentialHouseStates):void {

       // if(location === ResidentialHouseStates.Attic){ //"kitchen"){
            BabylonManager.MainContext.changeRoomState(location) ;  //.change(location)
        //}
       // BabylonManager.MainContext.changeAnimation("",true)

       if(location === ResidentialHouseStates.Attic){
           TrainingManager.Session.goToProduct(1);

          setTimeout(()=>{
               //TrainingManager.Session.  
               // TrainingManager.Session.goToQuestion(3);
               // TrainingManager.Session.goToPreCheck(2);
              // TrainingManager.Session.status.
              
              // let uitWin =  UiTrainingWindow(this)
                // uitWin.setState({CurrentSection:TrainingWindowSections.Questions, CompletedSection:true})
            //    console.log(uitWin.state)
             //uitWin.handleRequestedQuestion();
               
             //   BabylonManager.MainContext.changeAnimation("StartWrap",false)
           },3500)
          
       }

    }

    public render(): React.ReactElement {
            return (
                <div className="TestMenu" >
                   <ul>
                       <li onClick={()=>{this.onRemoveProducts()}}>Remove Tutorial</li>
                       <li onClick={()=>{this.onGoToLocation(ResidentialHouseStates.Kitchen)}}>Go To Kitchen</li>
                       <li onClick={()=>{this.onGoToLocation(ResidentialHouseStates.Attic)}}>Go To Attic</li>
                       <li onClick={()=>{this.onGoToLocation(ResidentialHouseStates.LivingRoom)}}>Go To Living Room</li>
                   </ul>
                </div>
            );
        }
}
