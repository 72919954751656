import { Tools, Vector3 } from "@babylonjs/core";
import {TaskStep, TaskListItem} from "./dataSetTypes"

// Residential Data
import {hotspotsBattInstall, interactiveBattInstall , tasksBattInstall } from "./BattInstall/BattInstallData"
import {hotspotsLooseFill, interactiveLooseFill, tasksLooseFill} from "./LooseFill/LooseFillData"
import {hotspotsNetAndBlow , interactiveNetAndBlow , tasksNetAndBlow } from "./NetAndBlow/NetAndBlowData"

// Commercial Data
import {hotspotsPipeAndTank, interactivePipeAndTank, tasksPipeAndTank} from "./PipeAndTank/PipeAndTankData"
import {hotspotsDuctWrap, interactiveDuctWrap, tasksDuctWrap} from "./DuctWrap/DuctWrapData"
import {hotspotsPipeInsulation, interactivePipeInsulation, tasksPipeInsulation} from "./PipeInsulation/PipeInsulationData"

// camaera starting positions
export let defaultCameraData: any={
	0:{position: new Vector3(.8,1.16,-7.61), rotation:new Vector3(Tools.ToRadians(10),Tools.ToRadians(0),Tools.ToRadians(0)),fov:1.3},
	1:{position: new Vector3(-2.7,6.26,-5.343), rotation:new Vector3(Tools.ToRadians(10),Tools.ToRadians(0),Tools.ToRadians(0)),fov:1.048},
	2:{position: new Vector3(-6.87,1.36,-8.461), rotation:new Vector3(Tools.ToRadians(5.5),Tools.ToRadians(255),Tools.ToRadians(0)),fov:1.219},
	3:{position: new Vector3(6, 5.25, -2.6), rotation:new Vector3(Tools.ToRadians(0),Tools.ToRadians(-270),Tools.ToRadians(0)),fov:.9},
	4:{position: new Vector3(-0.5, 1.5, -11.5), rotation:new Vector3(Tools.ToRadians(0),Tools.ToRadians(-90),Tools.ToRadians(0)),fov:.9},
	5:{position: new Vector3(-1.7, 5.25, 5), rotation:new Vector3(Tools.ToRadians(0),Tools.ToRadians(-270),Tools.ToRadians(0)),fov:.9},
}

// TaskSteps include hotspots and each item in the the interactive sections
export let getTaskStepsDataSet = (): TaskStep[]=>{
  let taskStepsDataset:TaskStep[] = [
    ...hotspotsBattInstall,
    ...interactiveBattInstall,
    ...hotspotsLooseFill,
    ...interactiveLooseFill,
    ...hotspotsNetAndBlow,
    ...interactiveNetAndBlow,
    ...hotspotsPipeInsulation,
    ...interactivePipeInsulation,
    ...hotspotsPipeAndTank,
    ...interactivePipeAndTank,
    ...hotspotsDuctWrap,
    ...interactiveDuctWrap
  ];
	taskStepsDataset.sort((a:TaskStep, b:TaskStep) => a.priority - b.priority);
  return taskStepsDataset
}

// The Tasks for each course
export let getTaskListDataSet = (): TaskListItem[]=>{
  let taskListDataset:TaskListItem[]=[
    ...tasksBattInstall,
    ...tasksLooseFill,
    ...tasksNetAndBlow,
    ...tasksPipeInsulation,
    ...tasksPipeAndTank,
    ...tasksDuctWrap
  ]
  console.log(tasksDuctWrap)
  console.log('NEW TASK LIST')
  console.log(taskListDataset)
return taskListDataset
}